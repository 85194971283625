import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { Shifts } from './shifts.model';
import { Rates } from '../rates.model';
import { BookingService } from '../booking.service';
import { CalendarShiftComponent } from '../calendar-shift/calendar-shift.component';
import { DatePipe } from '@angular/common';
import { CalendarEvent } from 'calendar-utils';

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  providers: [CalendarShiftComponent, {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  styleUrls: ['./add-shift.component.css']
})
export class AddShiftComponent implements OnInit {

  constructor(
  private formBuilder: FormBuilder,
  private bookingService: BookingService,
  public datepipe: DatePipe,
  private bookingDetailsComponent: BookingDetailsComponent
  ) { }

  customForm: FormGroup;
  submitted = false;
  loading = false;
  id: number;
  pLength: number;
  editMode = false;
  error: string = null;
  hours: any;
  currentDate = new Date();
  currentDateObj: any;
  shifts: Shifts[] = [];
  rates: Rates[] = [];
  pEndShiftDate: string;

  @ViewChild('DoughnutChartComponent' , {static: false}) chart: any;

  @Input() events: CalendarEvent[];

  @Input() childHours: any;

  ngOnInit() {
    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });

    this.id = this.bookingDetailsComponent.id;
    this.initForm();
    this.pLength = this.customForm.controls.pLength.value;
    this.customForm.controls.pLength.enable();
  }

  onCancel() {
    this.bookingDetailsComponent.modal.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  private initForm() {
    let pShiftDate = '';
    let pStartTime = '';
    let pEndTime = '';
    let pLength = '';
    let pShiftType = '';
    let pUniformRequirements = '';
    let pContactName = '';
    let pContactNumber = '';
    let pNoStaffRequired = '';

    if (this.editMode) {
      pShiftDate = pShiftDate;
      pStartTime = pStartTime;
      pEndTime = pEndTime;
      pLength = pLength;
      pShiftType = pShiftType;
      pNoStaffRequired = pNoStaffRequired;
      pContactName = pContactName;
      pContactNumber = pContactNumber;
      pUniformRequirements = pUniformRequirements;
    }
    this.customForm = this.formBuilder.group({
      pBookingID: [this.id],
      pShiftDate: [pShiftDate, Validators.required],
      pStartTime: [pStartTime, Validators.required],
      pEndTime: [pEndTime, Validators.required],
      pLength: [this.pLength],
      pShiftType: [pShiftType, Validators.required],
      pNoStaffRequired: [pNoStaffRequired, [Validators.required, Validators.max(500), Validators.min(0)]],
      pContactName: [pContactName, Validators.required],
      pContactNumber: [pContactNumber],
      pUniformRequirements: [pUniformRequirements]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid && !this.pEndShiftDate) {
      this.error = 'Please complete all the required fields.';
      return;
    } else {
      this.loading = true;
    }

    this.bookingDetailsComponent.bookingService.addShift(this.customForm.value, this.pEndShiftDate)
    .subscribe(
      resData => {
        this.bookingDetailsComponent.fetchEvents();
        this.onCancel();
        this.customForm.reset();
        this.loading = false;
      },
      error => {
        this.bookingDetailsComponent.bookingService.handleError(error.message);
        this.loading = false;
      }
    );
  }

  updateLength() {

    const startDate = this.customForm.controls.pStartTime.value.split(':');
    const endDate = this.customForm.controls.pEndTime.value.split(':');

    if (startDate.length > 1 && endDate.length > 1) {

      const startd = new Date(this.customForm.value.pShiftDate);
      startd.setHours(0, 0, 0, 0);
      startd.setHours (startd.getHours() + Number(startDate[0]));
      startd.setMinutes (startd.getMinutes() + Number(startDate[1]));

      const starte = new Date(startd);
      starte.setHours (starte.getHours() + (Number(endDate[0]) - Number(startDate[0])));
      starte.setMinutes (starte.getMinutes() + (Number(endDate[1]) - Number(startDate[1])));

      let diff = starte.getTime() - startd.getTime();
      let thours = Math.floor(diff / 1000 / 60 / 60);
      diff -= thours * 1000 * 60 * 60;
      let minutes = Math.floor(diff / 1000 / 60);
      // If using time pickers with 24 hours format, add the below line get exact hours
      if (minutes === 30) {
        minutes = 0.5;
      }
      if (thours < 4  && thours >= 0) {
        thours = 4;
        minutes = 0;
      } else if (thours <= -1) {
        starte.setDate(starte.getDate() + 1);
        thours = thours + 24;
      }

      const starte2 = new Date(startd);
      starte2.setHours (starte2.getHours() + (thours + minutes));

      this.pEndShiftDate = this.datepipe.transform(starte2, 'yyyy-MM-dd H:mm:ss');
      this.customForm.controls.pLength.setValue(thours + minutes);
    }
  }

  shiftDateSelected(event: string) {
    this.currentDateObj = event;
    this.currentDate = new Date(event);
  }
}
