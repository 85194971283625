import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { Data } from '../data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookingschart',
  templateUrl: './bookingschart.component.html',
  styleUrls: ['./bookingschart.component.css']
})
export class BookingschartComponent implements OnInit {

  url = environment.apiUrl + 'chart';
  data: Data[];
  pName = [];
  pTotal = [];
  pTotal2 = [];
  Linechart = [];
  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
      this.httpClient.get(this.url).subscribe((result: Data[]) => {
      result.forEach(x => {
        this.pName.push(x.pName);
        this.pTotal.push(x.pTotal);
        this.pTotal2.push(x.pTotal2);
      });

      const canvas: any = document.getElementById('bookingsHours');
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(20, 0, 0, 180);
      gradient.addColorStop(0, 'rgba(192, 2, 98, 0.2)');
      gradient.addColorStop(1, 'rgba(255, 234, 244, 0.2)');

      const gradient2 = ctx.createLinearGradient(0, 20, 0, 180);
      gradient2.addColorStop(0, 'rgba(0, 144, 255, 0.2)');
      gradient2.addColorStop(1, 'rgba(299, 245, 255, 0.2)');

      this.Linechart = new Chart('bookingsHours', {
        type: 'line',
        data: {
          labels: this.pName,

          datasets: [
            {
              label: 'This Year',
              data: this.pTotal,
              lineTension: 0,
              backgroundColor: gradient,
              borderColor: '#c00462',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#c00462',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBackgroundColor: '#00263E',
              pointHoverBorderColor: '#fff',
              pointRadius: 4,
              pointHitRadius: 10,
              spanGaps: false
            },
            {
              label: 'Last Year',
              data: this.pTotal2,
              lineTension: 0,
              backgroundColor: gradient2,
              borderColor: '#009DFF',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#009DFF',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBackgroundColor: '#00263E',
              pointHoverBorderColor: '#fff',
              pointRadius: 4,
              pointHitRadius: 10,
              spanGaps: false
            }
          ]
        },
        options: {
          layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0
              }
          },
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: true,
            position: 'top',
            labels: {
              fontSize: 14,
              fontFamily: 'Montserrat',
              usePointStyle: true,
              boxWidth: 6
            }
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                fontSize: 13,
                fontFamily: 'Montserrat',
                padding: 10
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                fontSize: 13,
                fontFamily: 'Montserrat',
                padding: 10
              }
            }],
          }
        }
      });
    });
  }
}
