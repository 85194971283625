import { Component, OnInit, Input } from '@angular/core';
import { BookingService } from '../../booking/booking.service';
import { SharedService } from '../../shared/shared.service';

export class Reviews {
  public pStarRating: number;
  public pClientName: string;
  public pBookingName: string;
  public pShiftName: string;
  public pShiftType: number;
  public pDate: string;
  public pReviews: string;
}
@Component({
  selector: 'app-profile-reviews',
  templateUrl: './profile-reviews.component.html',
  styleUrls: ['./profile-reviews.component.css']
})
export class ProfileReviewsComponent implements OnInit {

  constructor(
    public bookingService: BookingService,
    private sharedService: SharedService
  ) {
  }

  @Input() superstarID: number;
  reviews: Reviews[] = [];

  ngOnInit() {
    this.sharedService.getReviews(this.superstarID).subscribe((reviews: Reviews[]) => {
      this.reviews = reviews;
    });
  }

}
