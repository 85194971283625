import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Profile } from './profile.model';
import { ProfileService } from './profile.service';
import { SuperstarsService } from '../superstars/superstars.service';
import { Rates } from '../booking/rates.model';
import { BookingService } from '../booking/booking.service';
import { ClientsService } from '../clients/clients.service';
import { SharedService } from '../shared/shared.service';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

export class Skills {
  public pID: number;
  public pSuperstarID: number;
  public pRateID: number;
  public pValue: number;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) document, r: Renderer2,
    private profileService: ProfileService,
    private superstarsService: SuperstarsService,
    private bookingService: BookingService,
    private clientsService: ClientsService,
    private sharedService: SharedService,
    config: NgbModalConfig,
    private modal: NgbModal,
    private authService: AuthService
    ) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
  }


  profile: Profile[] = [];
  skills: Skills[] = [];
  rates: Rates[] = [];
  events: CalendarEvent[] = [];
  documents: any[] = [];
  refresh: Subject<any> = new Subject();
  isAuthenticated = false;
  private userSub: Subscription;
  public pUserID = null;
  public user = null;
  public pSuperstarID = null;
  public pClientID = null;
  public pRole = null;
  hasCVUploaded = true;
  files: NgxFileDropEntry[] = [];
  closed = false;

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  ngOnInit() {
    this.userSub = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;
      if (this.isAuthenticated) {

        this.pUserID = user.pID;

        this.profileService.getProfile(Number(user.pID)).subscribe((profile: Profile[]) => {
          this.profile = profile;
          this.pSuperstarID = profile[0].pSuperstarID;
          this.pClientID = profile[0].pClientID;
          this.pRole = profile[0].pRole;

          this.sharedService.getDocuments(this.pUserID).subscribe((documents: any[]) => {
            this.documents = documents;
            if (documents.length > 0 ) {
              if (Number(this.profile[0].pStatusID) === 9) {
                this.hasCVUploaded = true;
                this.profile[0].pStatusID = 2;
                this.superstarsService.changeSuperstarStatus(this.pSuperstarID, 2).subscribe();
              }
            } else {
              this.hasCVUploaded = false;
              this.profile[0].pStatusID = 9;
              this.superstarsService.changeSuperstarStatus(this.pSuperstarID, 9).subscribe();
            }
          });

          this.superstarsService.getSkillsBySuperstarID(profile[0].pSuperstarID).subscribe((skills: Skills[]) => {
            this.skills = skills;
          });

          this.bookingService.getRates().subscribe((rates: Rates[]) => {
            this.rates = rates;
          });

        });
      }
    });
  }

  getStatusName(pID: string) {
    return this.superstarsService.getStatusName(Number(pID));
  }

  getSkillName(pRateID: number) {
    // tslint:disable-next-line:triple-equals
    const rate = this.rates.find(i => i.pID == pRateID);
    if (rate) {
      return rate.pName;
    }
  }

  fetchEvents(): void {
    this.events = [];
    this.bookingService.getSuperstarCalendarShifts(this.pSuperstarID)
    .subscribe(
      (events: CalendarEvent[]) => {
        this.events = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < events.length; i++) {
            this.events.push({
                title: events[i].title,
                start: new Date(events[i].start),
                end: new Date(events[i].end),
                color: this.bookingService.getColor(String(events[i].color)),
                draggable: false,
                meta: events[i].meta,
                allDay: false
            });
        }
        this.refresh.next(true);
      });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();

          if (this.pRole === '1') {
            formData.append('pImage', file, droppedFile.relativePath);
            this.superstarsService.uploadSuperstarImage(this.pSuperstarID, formData)
            .subscribe(data => {
              if (data) {
                this.profile[0].pImage = data.pImage;
              }
            });
          }

          if (this.pRole === '2') {
            formData.append('pClientLogo', file, droppedFile.relativePath);
            this.clientsService.uploadClientImage(this.pClientID, formData)
            .subscribe(data => {
              if (data) {
                this.profile[0].pImage = data.pImage;
              }
            });
          }

          if (this.pRole === '3') {
            formData.append('pImage', file, droppedFile.relativePath);
            this.sharedService.uploadAdminImage(this.pUserID, formData)
            .subscribe(data => {
              if (data) {
                this.profile[0].pImage = data.pImage;
              }
            });
          }

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onFileSubmit() {
    this.onCancel();
    if (this.pRole === '1') {
      this.sharedService.addLog(this.pUserID, Number(this.pUserID), 'Update Profile Photo').subscribe();
    }
  }
}
