export class Superstars {
    public pID: number;
    public pUserID: string;
    public pFullName: string;
    public pFirstName: string;
    public pLastName: string;
    public pSummary: string;
    public pStatusID: number;
    public pRating: number;
    public pSkills: number;
    public pTotalHours: number;
    public pAvgHours: number;
    public pImage: string;
    public pPhoneNumber: string;
    public pEmail: string;
    public pAddress: string;
    public pDOB: string;
    public pPPS: string;
    public pEmployeeNo: string;
    public pVisaRequired: boolean;
    public pVisaType: string;
    public pVisaExpiryDate: string;
    public pFacebook: string;
    public pInstagram: string;
    public pCreated: string;
    public pModified: string;
  }
