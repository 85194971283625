import { Component, AfterViewInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements AfterViewInit {

  constructor() { }

  doughnutChart = [];
  @Input() ChartTotalA: string;
  @Input() ChartTotalB: string;
  @Input() ChartLabelA: string;
  @Input() ChartLabelB: string;
  @Input() ChartID: string;
  @Input() ChartLabel: string;
  @Input() ChartPacentage: string;

  ngAfterViewInit() {

    setTimeout( () => {

      let total = this.ChartTotalB;
      if (total.toString().length === 1) {
          total = '0' + total;
      }
      const label = this.ChartLabel;
      const pacentage = this.ChartPacentage;

      this.doughnutChart = new Chart(this.ChartID, {
          type: 'doughnut',
          data: {
            labels: [this.ChartLabelB, this.ChartLabelA],
            datasets: [{
              data: [this.ChartTotalB, this.ChartTotalA],
              backgroundColor: ['#53CE91', '#C7D0D6'],
              hoverBackgroundColor: ['#53CE91', '#C7D0D6']
          }]
          },
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                label(tooltipItem, data) {
                  return ' ' + data.datasets[0].data[tooltipItem.index] + pacentage;
                }
              }
            },
            elements: {
              arc: {
                roundedCornersFor: 0
              },
              center: {
                // the longest text that could appear in the center
                maxText: '100%',
                text: total + this.ChartPacentage,
                text2: label,
                fontColor: '#29546F',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontSize: 28,
                minFontSize: 1,
                maxFontSize: 256,
              }
            },
            responsive: false,
            cutoutPercentage: 76,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: false
              }],
              yAxes: [{
                display: false
              }],
            }
          }
        });

      const canvas: any = document.getElementById(this.ChartID);
      if (canvas) {
        // Round corners
        Chart.pluginService.register({
          beforeDraw(chart) {
          if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
            const arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
            arc.round = {
                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                radius: (chart.outerRadius + chart.innerRadius) / 2,
                thickness: (chart.outerRadius - chart.innerRadius) / 2 - 1,
                backgroundColor: '#53CE91'
            };
          }
          },

          afterDraw(chart) {
            if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
              const ctx = chart.chart.ctx;
              const arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
              const startAngle = Math.PI / 2 - arc._view.startAngle;
              const endAngle = Math.PI / 2 - arc._view.endAngle;
              if (arc.round !== undefined) {
              ctx.save();
              ctx.translate(arc.round.x, arc.round.y);
              ctx.fillStyle = '#53CE91';
              ctx.beginPath();
              ctx.arc(arc.round.radius * Math.sin(startAngle),
              arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
              ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
              ctx.closePath();
              ctx.fill();
              ctx.restore();
              }
            }
          }
        });
        // write text plugin
        Chart.pluginService.register({
          beforeDraw(chart) {
            if (chart.config.options.elements.center) {
              const helpers = Chart.helpers;
              const centerConfig = chart.config.options.elements.center;
              const globalConfig = Chart.defaults.global;
              const ctx = chart.chart.ctx;

              const fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
              const fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);
              let fontSize;
              if (centerConfig.fontSize) {
                fontSize = centerConfig.fontSize;
              } else {
                ctx.save();
                fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
                const maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
                const maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

                do {
                  ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
                  const textWidth = ctx.measureText(maxText).width;

                  // check if it fits, is within configured limits and that we are not simply toggling back and forth
                  if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) {
                    fontSize += 1;
                  } else {
                    // reverse last step
                    fontSize -= 1;
                    break;
                  }
                } while (true);
                ctx.restore();
              }

              // save properties
              chart.center = {
                font: helpers.fontString(fontSize, fontStyle, fontFamily),
                fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
              };
            }
          },
          afterDraw(chart) {
            if (chart.center) {
              const centerConfig = chart.config.options.elements.center;
              const ctx = chart.chart.ctx;
              ctx.save();
              ctx.font = chart.center.font;
              ctx.fillStyle = chart.center.fillStyle;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
              const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2.2;
              const centerY2 = (chart.chartArea.top + chart.chartArea.bottom) / 1.5;
              ctx.fillText(centerConfig.text, centerX, centerY);
              ctx.font = '14px Montserrat';
              ctx.fillText(centerConfig.text2, centerX, centerY2);
              ctx.restore();
            }
          },
        });
      }
    }, 200);
  }
}
