import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { AnimateDirective } from './shared/animate.directive';
import { AppRoutingModule } from './app-routing.module';
import { AuthComponent } from './auth/auth.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { BookingComponent } from './booking/booking.component';
import { ClientsComponent } from './clients/clients.component';
import { SuperstarsComponent } from './superstars/superstars.component';
import { BookingschartComponent } from './dashboard/bookingschart/bookingschart.component';
import { WeekshiftsComponent } from './dashboard/weekshifts/weekshifts.component';
import { MonthshiftsComponent } from './dashboard/monthshifts/monthshifts.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PasswordComponent } from './auth/password/password.component';
import { CweekComponent } from './calendar/utils/cweek.component';
import { CdayComponent } from './calendar/utils/cday.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { BookingService } from './booking/booking.service';
import { CalendarService } from './calendar/calendar.service';
import { ClientsService } from './clients/clients.service';
import { SuperstarsService } from './superstars/superstars.service';
import { ProfileService } from './profile/profile.service';
import { SharedService } from './shared/shared.service';
import { SuperstarsPipe } from './superstars/superstars.pipe';
import { SuperstarListsPipe } from './superstars/superstar-lists.pipe';
import { SbookingPipe } from './booking/booking-details/booking-superstars/sbooking.pipe';
import { FiltersPipe } from './shared/filters.pipe';
import { FilterBookingsPipe } from './shared/filter-bookings.pipe';
import { BookingDetailsComponent } from './booking/booking-details/booking-details.component';
import { AddShiftComponent } from './booking/add-shift/add-shift.component';
import { DatePipe } from '@angular/common';
import { CalendarShiftComponent } from './booking/calendar-shift/calendar-shift.component';
import { BookingSummaryComponent } from './booking/booking-details/booking-summary/booking-summary.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { HoursMonthlyComponent } from './clients/client-details/hours-monthly/hours-monthly.component';
import { DoughnutChartComponent } from './shared/doughnut-chart/doughnut-chart.component';
import { DoughnutChart2Component } from './shared/doughnut-chart2/doughnut-chart2.component';
import { UploadDocumentsComponent } from './shared/upload-documents/upload-documents.component';
import { AddNotesComponent } from './shared/add-notes/add-notes.component';
import { ListsComponent } from './superstars/utils/lists/lists.component';
import { SuperstarDetailsComponent } from './superstars/superstar-details/superstar-details.component';
import { RecentActivityComponent } from './shared/recent-activity/recent-activity.component';
import { SuperstarSummaryComponent } from './superstars/superstar-summary/superstar-summary.component';
import { BookingSuperstarsComponent } from './booking/booking-details/booking-superstars/booking-superstars.component';
import { BarChartsComponent } from './booking/utils/bar-charts/bar-charts.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SuperstarCalendarComponent } from './superstars/superstar-calendar/superstar-calendar.component';
import { ClientReviewsComponent } from './shared/client-reviews/client-reviews.component';
import { StrikesComponent } from './shared/strikes/strikes.component';
import { MonthlyReportComponent } from './superstars/utils/monthly-report/monthly-report.component';
import { ProfileSummaryComponent } from './profile/profile-summary/profile-summary.component';
import { ProfileCalendarComponent } from './profile/profile-calendar/profile-calendar.component';
import { ProfileReviewsComponent } from './profile/profile-reviews/profile-reviews.component';
import { SummaryEditComponent } from './superstars/summary-edit/summary-edit.component';
import { BookingAddComponent } from './booking/booking-add/booking-add.component';
import { BookingEditComponent } from './booking/booking-edit/booking-edit.component';
import { SuperstarBankComponent } from './superstars/superstar-bank/superstar-bank.component';
import { AngularIbanModule } from 'angular-iban';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    AnimateDirective,
    AuthComponent,
    LoadingSpinnerComponent,
    DashboardComponent,
    CalendarComponent,
    BookingComponent,
    ClientsComponent,
    SuperstarsComponent,
    SuperstarsPipe,
    SuperstarListsPipe,
    SbookingPipe,
    FiltersPipe,
    FilterBookingsPipe,
    BookingschartComponent,
    WeekshiftsComponent,
    MonthshiftsComponent,
    ProfileComponent,
    NotificationsComponent,
    PasswordComponent,
    CweekComponent,
    CdayComponent,
    BookingDetailsComponent,
    AddShiftComponent,
    CalendarShiftComponent,
    BookingSummaryComponent,
    ClientDetailsComponent,
    HoursMonthlyComponent,
    DoughnutChartComponent,
    DoughnutChart2Component,
    UploadDocumentsComponent,
    AddNotesComponent,
    ListsComponent,
    SuperstarDetailsComponent,
    RecentActivityComponent,
    SuperstarSummaryComponent,
    BookingSuperstarsComponent,
    BarChartsComponent,
    SuperstarCalendarComponent,
    ClientReviewsComponent,
    StrikesComponent,
    MonthlyReportComponent,
    ProfileSummaryComponent,
    ProfileCalendarComponent,
    ProfileReviewsComponent,
    SummaryEditComponent,
    BookingAddComponent,
    BookingEditComponent,
    SuperstarBankComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    NgSelectModule,
    Ng5SliderModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFileDropModule,
    BrowserAnimationsModule,
    AngularIbanModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AgmCoreModule.forRoot({
      libraries: ['places', 'geometry'],
      apiKey: 'AIzaSyAk6NT4UIiyW4xAAOq5h3pztDM3hC7WYqY'
    }),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
  providers: [
    BookingService,
    CalendarService,
    ClientsService,
    SuperstarsService,
    ProfileService,
    SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
