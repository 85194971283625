import { Component, OnInit , Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BookingService } from './booking.service';
import { Booking } from './booking.model';
import { Priority, bookingLocation } from '../booking/utils/clients';
import { Shifts } from './add-shift/shifts.model';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class BookingComponent implements OnInit {

  id: number;
  editMode = false;
  bookings: Booking[] = [];
  tempBookings: Booking[] = [];
  shifts: Shifts[] = [];
  totalRevenue: number;
  today = new Date();
  autoSearchBooking: any;
  priority = Priority;
  location = bookingLocation;
  showSpinner = true;

  numberOfHoursValue = 0;
  numberOfHours = 2000;
  numberOfHoursOption = {
    floor: 0,
    ceil: 2000
  };

  numberOfUnfilledHoursValue = 0;
  numberOfUnfilledHours = 2000;
  numberOfUnfilledHoursOption = {
    floor: 0,
    ceil: 2000
  };

  numberOfStaffValue = 0;
  numberOfStaff = 1000;
  numberOfStaffOption = {
    floor: 0,
    ceil: 1000
  };

  numberOfUnfilledStaffValue = 0;
  numberOfUnfilledStaff = 1000;
  numberOfUnfilledStaffOption = {
    floor: 0,
    ceil: 1000
  };

  constructor(
    @Inject(DOCUMENT) document, r: Renderer2,
    public bookingService: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    config: NgbModalConfig,
    private modal: NgbModal) {

    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');

    config.backdrop = 'static';
    config.keyboard = false;
  }

  selectedClient: string = null;

  page = 1;
  pageSize = 30;
  collectionSize = 0;

  get bookingItems(): Booking[] {
    return this.bookings
      .map((booking, i) => ({id: i + 1, ...booking}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.id = +params.id || 0;
        this.editMode = this.id !== 0;
    });

    this.bookingService.getBookings().subscribe((bookings: Booking[]) => {
      this.bookings = bookings;
      this.bookings.sort(this.sortArrayCreated);
      this.tempBookings = this.bookings;
      this.collectionSize = this.bookings.length;
      if (this.bookings) {
        this.showSpinner = false;
      }
    });
  }

  getBookingItem(pID: number) {
    this.id = pID;
    this.router.navigate(['/booking'], { queryParams: { id: [this.id] }});
  }

  sortArrayCreated(a, b) {
    const x = new Date(a.pCreated);
    const y = new Date(b.pCreated);
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  }

  onClientChange(event: any) {
    if (event) {
      this.bookings = [];
      if (event.pID) {
        const temp = this.tempBookings.filter(c => c.pClientID === event.pID);
        this.bookings = temp;
      }
    }
  }

  onClientTypeChange(event: any) {
    if (event) {
      this.bookings = [];
      if (event.value) {
        const temp = this.tempBookings.filter(c => c.pClientTypeID === event.value);
        this.bookings = temp;
      }
    }
  }

  onNumberOfHoursChange() {
    this.bookings = [];
    if (this.numberOfHoursValue) {
      const temp = this.tempBookings.filter(c => Number(c.totals.pTotalHoursAssigned) >= this.numberOfHoursValue);
      this.bookings = temp;
    }
  }

  onNumberOfUnfilledHoursChange() {
    this.bookings = [];
    if (this.numberOfUnfilledHoursValue) {
      const temp = this.tempBookings.filter(c => Number(c.totals.pNoHoursRequired) >= this.numberOfUnfilledHoursValue);
      this.bookings = temp;
    }
  }

  onNumberOfStaffChange() {
    this.bookings = [];
    if (this.numberOfStaffValue) {
      const temp = this.tempBookings.filter(c => Number(c.totals.pTotalStaffAssigned) >= this.numberOfStaffValue);
      this.bookings = temp;
    }
  }

  onNumberOfUnfilledStaffChange() {
    this.bookings = [];
    if (this.numberOfUnfilledStaffValue) {
      const temp = this.tempBookings.filter(c => Number(c.totals.pNoStaffRequired) >= this.numberOfUnfilledStaffValue);
      this.bookings = temp;
    }
  }

  onClearAll() {
    this.bookings = [];
    this.bookings = this.tempBookings;
  }

}
