import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) document, r: Renderer2) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
  }

  ngOnInit() {
  }

}
