import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SuperstarsService } from '../superstars/superstars.service';
import { ClientsService } from '../clients/clients.service';
import { BookingService } from '../booking/booking.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  activeSuperstars: number;
  superstarStatus: any[] = [];
  pActiveClients: number;
  pActiveWeeklyClients: number;
  pActiveMonthlyClients: number;
  pActiveYearlyClients: number;
  pParcentageTotals: string;
  pParcentageData: any[] = [];

  constructor(
  @Inject(DOCUMENT) document,
  r: Renderer2,
  private superstarsService: SuperstarsService,
  private clientsService: ClientsService,
  private bookingService: BookingService
  ) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
  }

  getStatusName(pID: string) {
    return this.superstarsService.getStatusName(Number(pID));
  }

  ngOnInit() {
    this.superstarsService.getSuperstarsTotals().subscribe((data: any[]) => {
      // tslint:disable-next-line:no-string-literal
      this.activeSuperstars = data['pActiveSuperstars'];
      // tslint:disable-next-line:no-string-literal
      this.superstarStatus = data['pSuperstarStatus'];
    });
    this.clientsService.getActiveClients().subscribe((data: any[]) => {
      // tslint:disable-next-line:no-string-literal
      this.pActiveClients = data['pActiveClients'];
      // tslint:disable-next-line:no-string-literal
      this.pActiveWeeklyClients = data['pActiveWeeklyClients'];
      // tslint:disable-next-line:no-string-literal
      this.pActiveMonthlyClients = data['pActiveMonthlyClients'];
      // tslint:disable-next-line:no-string-literal
      this.pActiveYearlyClients = data['pActiveYearlyClients'];
    });
    this.bookingService.getShiftTotalsByTimeline('year').subscribe((data: any[]) => {
      // tslint:disable-next-line:no-string-literal
      this.pParcentageTotals = data['pParcentageTotals'];
      // tslint:disable-next-line:no-string-literal
      this.pParcentageData = data['pParcentageData'];
    });
  }

}
