import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { CalendarWeekViewComponent, CalendarUtils } from 'angular-calendar';
import { WeekView, GetWeekViewArgs, WeekViewAllDayEvent, CalendarEvent } from 'calendar-utils';

// extend the interface to add the array of users
interface WeekViewScheduler extends WeekView {
  users: any[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgs): WeekViewScheduler {
    const view: WeekViewScheduler = {
      ...super.getWeekView(args),
      users: []
    };
    return view;
  }
}

@Component({
  // tslint:disable-line max-classes-per-file
  selector: 'app-cweek',
  providers: [
    {
      provide: CalendarUtils,
      useClass: DayViewSchedulerCalendarUtils
    }
  ],
  templateUrl: 'cweek.component.html'
})
export class CweekComponent extends CalendarWeekViewComponent {
  view: WeekViewScheduler;

  @Output() userChanged = new EventEmitter();

  numberOfDays(date1: string, date2: string) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    let diffInMs = Math.round(Math.abs((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
    if (Number.isNaN(diffInMs)) {
        diffInMs = 0;
    }
    diffInMs = diffInMs + 1;
    return diffInMs;
  }

  dayPostion(date: string) {
    const day = new Date(date);
    const weekday = new Array(7);
    let position = 0;
    weekday[0] =  'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    switch (weekday[day.getDay()]) {
        case 'Sunday': {
            position = 0;
            break;
        }
        case 'Monday': {
            position = 1;
            break;
        }
        case 'Tuesday': {
            position = 2;
            break;
        }
        case 'Wednesday': {
            position = 3;
            break;
        }
        case 'Thursday': {
            position = 4;
            break;
        }
        case 'Friday': {
            position = 5;
            break;
        }
        case 'Saturday': {
            position = 6;
            break;
        }
        default: {
            position = 0;
            break;
        }
     }

    return position;
  }

  getTime(date: string) {
    const day = new Date(date);
    const time = day.getHours() + ':' + day.getMinutes();
    return time;
  }

  getHours(date1: string, date2: string) {
    const oneDay =  60 * 60 * 1000;
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    let diffInMs = Math.round(Math.abs((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
    if (Number.isNaN(diffInMs)) {
        diffInMs = 0;
    }
    return diffInMs;
  }

}
