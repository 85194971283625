import { Component, Inject, Renderer2, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, PasswordResponseData } from '../auth.service';
import { MustMatch } from '../match.password';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  passwordResetForm: FormGroup;
  submitted = false;
  isLoading = false;
  error: string = null;
  pKeys: string =  null;
  isChangeMode = true;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder, private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(DOCUMENT) document, r: Renderer2,
              config: NgbModalConfig, private modalService: NgbModal) {

      r.addClass(document.body, 'login');
      r.removeClass(document.body, 'page');

      // customize default values of modals used by this component tree
      config.backdrop = 'static';
      config.keyboard = false;

}

  ngOnInit() {

    // Logout
    this.authService.logPassword();

    this.passwordResetForm = this.formBuilder.group({
      pEmail: ['', [Validators.required, Validators.email]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.pKeys = params.keys;
      if (this.pKeys) {
        this.onSwitchMode();
      }
    });
  }

  onSwitchMode() {

    this.passwordResetForm.reset();
    this.isChangeMode = false;
    this.error = null;
    // Init sign up form fields
    this.passwordResetForm = this.formBuilder.group({
      pPassword: ['', [Validators.required, Validators.minLength(6)]],
      pPassword2: ['', Validators.required]
    }, {
      validator: MustMatch('pPassword', 'pPassword2')
    });
  }

  open(content) {
      this.modalService.open(content, {size: 'xl'});
  }

  // Convenience getter for easy access to form fields
  get f() { return this.passwordResetForm.controls; }

  onSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      if (this.passwordResetForm.invalid) {
        return;
      }

      this.isLoading = true;

      const pEmail = this.passwordResetForm.value.pEmail;
      const pPassword = this.passwordResetForm.value.pPassword;
      const pKeys = this.pKeys;

      let passwordObs: Observable<PasswordResponseData>;
      if (this.isChangeMode) {
        passwordObs = this.authService.resetPassword(pEmail);
      } else {
        passwordObs = this.authService.resetPassword(null, pPassword, pKeys);
      }

      passwordObs.subscribe(
      resData => {
        this.error = resData.pMessage;
        this.passwordResetForm.reset();
        this.isLoading = false;
      },
      errorMessage => {
        this.error = errorMessage.error.pMessage;
        this.passwordResetForm.reset();
        this.isLoading = false;
      }
      );
  }
}
