export const colors: any = {
    green: {
      primary: '#53CE91',
      secondary: '#E5F8EF'
    },
    red: {
      primary: '#D83962',
      secondary: '#F9E2E8'
    },
    yellow: {
      primary: '#ED9D59',
      secondary: '#FBEBDE'
    },
    grey: {
      primary: '#C7D0D6',
      secondary: '#F2F3F7'
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#d1e8ff'
    }
  };
