import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams
} from '@angular/common/http';
import { take, exhaustMap, delay } from 'rxjs/operators';
import { User } from './user.model';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userData')));
    const currentUser = currentUserSubject.value;
    if (!currentUser) {
      return next.handle(req);
    }
    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${currentUser.pToken}`
      }
    });
    return next.handle(modifiedReq);
  }
}
