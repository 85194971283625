import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap, reduce } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'; 

import { User } from './user.model';
import { environment } from 'src/environments/environment';

export interface AuthResponseData {
  pID: string;
  pEmail: string;
  pFullname: string;
  pFirstname: string;
  pSurname: string;
  pToken: string;
  expireAt: string;
  pRole: string;
  pStatus: boolean;
  pMessage: string;
}

export interface PasswordResponseData {
  pStatus: boolean;
  pMessage: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private modalService: NgbModal, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userData')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  signup(email: string, password: string, fullname: string) {
    const formData = new FormData();
    const firstName = fullname.split(' ').slice(0, -1).join(' ');
    const lastName = fullname.split(' ').slice(-1).join(' ');

    formData.append('pEmail', email);
    formData.append('pPassword', password);
    formData.append('pFirstname', firstName);
    formData.append('pSurname', lastName);

    return this.http.post<AuthResponseData>(
      environment.apiUrl + 'users', formData)
      .pipe(
        tap(resData => {
          if (!resData.pStatus) {
            this.handleError(resData.pMessage);
          }
        })
      );
  }

  login(email: string, password: string) {
    const formData = new FormData();
    formData.append('pEmail', email);
    formData.append('pPassword', password);

    return this.http.post<AuthResponseData>(
      environment.apiUrl + 'login', formData)
        .pipe(
          tap(resData => {
            if (resData.pStatus) {
              this.handleAuthentication(
                resData.pID,
                resData.pEmail,
                resData.pFullname,
                resData.pFirstname,
                resData.pSurname,
                resData.pToken,
                resData.pRole,
                resData.expireAt
              );
            } else {
              this.handleError(resData.pMessage);
            }
        }));
  }

  autoLogin() {
    const userData: {
      pID: string;
      pEmail: string;
      pFullname: string;
      pFirstname: string;
      pSurname: string;
      pToken: string;
      pTokenExpirationDate: string;
      pRole: string;
    } = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.pID,
      userData.pEmail,
      userData.pFullname,
      userData.pFirstname,
      userData.pSurname,
      userData.pToken,
      userData.pRole,
      new Date(userData.pTokenExpirationDate)
    );

    if (loadedUser.pToken) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData.pTokenExpirationDate).getTime() -
        new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  logout() {
    this.modalService.dismissAll();
    this.user.next(null);
    localStorage.removeItem('userData');
    this.router.navigate(['/auth']);
  }

  logPassword() {
    this.user.next(null);
    localStorage.removeItem('userData');
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  resetPassword(email?: string, password?: string,  keys?: string) {
    const formData = new FormData();
    if (email) {
      formData.append('pEmail', email);
    } else {
      formData.append('pPassword', password);
      formData.append('pKeys', keys);
    }
    return this.http.post<PasswordResponseData>(
      environment.apiUrl + 'resetpassword', formData)
        .pipe(
          tap(resData => {
            if (resData.pStatus) {
              this.handleResponse(resData.pMessage);
            } else {
              this.handleError(resData.pMessage);
            }
        }));
  }

  public handleAuthentication(
    pID: string,
    pEmail: string,
    pFullname: string,
    pFirstname: string,
    pSurname: string,
    pToken: string,
    pRole: string,
    pTokenExpiry: string,
  ) {
    // tslint:disable-next-line:radix
    const expirationDate = new Date(new Date().getTime() + parseInt(pTokenExpiry));
    const user = new User(pID, pEmail, pFullname, pFirstname, pSurname, pToken, pRole, expirationDate);
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
    JSON.parse(localStorage.getItem('userData'));
  }

  handleError(error) {
    let errorMessage = '';
    if (error instanceof ErrorEvent) {
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = `${error}`;
    }
    return throwError(errorMessage);
  }

  handleResponse(response) {
    return throwError(response);
  }

 }

