import { Component, OnInit , Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Clients } from './clients.model';
import { ClientsService } from './clients.service';
import { NgbModalConfig, NgbModal, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Priority, bookingLocation } from '../booking/utils/clients';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  customForm: FormGroup;
  submitted = false;
  loadingf = false;
  showSpinner = true;
  editMode = false;
  checkAll = false;
  id: number;
  error: string = null;
  clients: Clients[] = [];
  tempClients: Clients[] = [];
  checkedClients: any[] = [];
  priority = Priority;
  location = bookingLocation;
  today = new Date();
  show = false;
  autohide = true;
  autoSearchClient: any;

  numberOfHoursValue = 0;
  numberOfHours = 2000;
  numberOfHoursOption = {
    floor: 0,
    ceil: 2000
  };

  numberOfRevenueValue = 0;
  numberOfRevenue = 20000;
  numberOfRevenueOption = {
    floor: 0,
    ceil: 20000
  };

  constructor(
    @Inject(DOCUMENT) document,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    config: NgbModalConfig,
    private datePipe: DatePipe,
    private modal: NgbModal,
    r: Renderer2) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;
      this.editMode = this.id !== 0;
    });

    this.initForm();

    this.clientsService.getClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
      this.clients.sort(this.sortArrayLastBooking);
      this.tempClients = this.clients;

      if (this.clients) {
        this.showSpinner = false;
      }
    });
  }

  private initForm() {
    let pClientName = '';
    let pContactName = '';
    let pContactNumber = '';
    let pContactEmail = '';
    let pClientTypeID = '';

    if (this.editMode) {
      pClientName = pClientName;
      pContactName = pContactName;
      pContactNumber = pContactNumber;
      pContactEmail = pContactEmail;
      pClientTypeID = pClientTypeID;
    }
    this.customForm = this.formBuilder.group({
      pClientName: [pClientName, Validators.required],
      pContactName: [pContactName, [Validators.required, this.contactNameValidator]],
      pContactNumber: [pContactNumber, Validators.required],
      pContactEmail: [pContactEmail, [Validators.required, Validators.email]],
      pClientTypeID: [pClientTypeID, Validators.required]
    });
  }

  contactNameValidator(control: AbstractControl) {
    if (control.value) {
        const name = control.value;
        const fullName = name.split(' ');
        if (fullName.length < 2) {
            return { invalidDob: true };
        } else {
            return null;
        }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      if (this.customForm.controls.pContactName.invalid) {
        this.error = 'Please input a first name and surname for contact.';
      }
      return;
    } else {
      this.loadingf = true;
    }

    if (this.editMode) {
    } else {
      this.clientsService.signup(this.customForm.value.pContactEmail, '123456', this.customForm.value.pContactName).subscribe(
        resData => {
          if (resData.pStatus) {
            this.clientsService.addClient(resData.pID, this.customForm.value).subscribe(
              resData2 => {
                if (resData.pStatus) {
                  this.clients.push({
                    pID: resData2.pID,
                    pUserID: resData.pID,
                    pClientTypeID: this.customForm.value.pClientTypeID,
                    pClientName: this.customForm.value.pClientName,
                    pClientLogo: '',
                    pLastBooking: this.datePipe.transform(this.today, 'yyyy-MM-dd'),
                    pTotalHoursBooked: String(0),
                    pTotalRevenue: String(0),
                    pContactName: this.customForm.value.pContactName,
                    pContactNumber: this.customForm.value.pContactNumber,
                    pContactEmail: this.customForm.value.pContactEmail,
                    pCreated: this.datePipe.transform(this.today, 'yyyy-MM-dd'),
                    pModified: this.datePipe.transform(this.today, 'yyyy-MM-dd'),
                    bookings: []
                  });
                  this.onCancel();
                  this.customForm.reset();
                  this.loadingf = false;
                  this.router.navigate(['/clients'], { queryParams: { id: resData2.pID } });
                } else {
                  this.loadingf = false;
                  this.error = resData.pMessage;
                }
              }
            );
          } else {
            this.loadingf = false;
            this.error = resData.pMessage;
          }
      },
      error => {
        this.clientsService.handleError(error.message);
      }
      );
    }
  }

  onCancel() {
    this.customForm.reset();
    this.modal.dismissAll();
  }

  getClientItem(pID: number) {
    this.id = pID;
    this.router.navigate(['/clients'], { queryParams: { id: [this.id] }});
  }

  checkedAllClients(event: any, clients: any) {
    this.checkedClients = [];
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.checkedClients = clients;
    } else {
      this.show = checked;
      this.checkedClients = [];
    }
    this.clients.forEach(client => this.checkAll = checked);
  }

  CheckedClient(event: any, pID: number, client: any) {
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.checkedClients.push(client);
    } else {
      const index = this.checkedClients.findIndex(x => x.pID === pID);
      if (index > -1) {
        this.checkedClients.splice(index, 1);
      }
    }
    if (this.checkedClients.length === 0) {
      this.show = false;
    }
  }

  sortArrayLastBooking(a, b) {
    const x = new Date(a.pCreated);
    const y = new Date(b.pCreated);
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  }

  onClientChange(event: any) {
    if (event) {
      this.clients = [];
      if (event.pID) {
        const temp = this.tempClients.filter(c => c.pID === event.pID);
        this.clients = temp;
      }
    }
  }

  onClientTypeChange(event: any) {
    if (event) {
      this.clients = [];
      if (event.value) {
        const temp = this.tempClients.filter(c => c.pClientTypeID === event.value);
        this.clients = temp;
      }
    }
  }

  onNumberOfHoursChange() {
    this.clients = [];
    if (this.numberOfHoursValue) {
      const temp = this.tempClients.filter(c => Number(c.pTotalHoursBooked) >= this.numberOfHoursValue);
      this.clients = temp;
    }
  }

  onNumberOfRevenueChange() {
    this.clients = [];
    if (this.numberOfRevenueValue) {
      const temp = this.tempClients.filter(c => Number(c.pTotalRevenue) >= this.numberOfRevenueValue);
      this.clients = temp;
    }
  }

  onClearAll() {
    this.clients = [];
    this.clients = this.tempClients;
  }

  sendMail() {
    const mail = document.createElement('a');
    let emails = '';
    this.checkedClients.forEach(item => {
      emails = emails + item.pContactEmail + ',';
    });
    mail.href = 'mailto:hello@gettheshifts.com?bcc=' + emails + '&subject=Get The Shifts&body=Hi ';
    mail.click();
  }
}
