import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';

export interface Logs {
  pID: number;
  pCurrentUserID: number;
  pUserID: number;
  pComments: string;
  pImage: string;
  pCreated: string;
}

@Injectable()
export class SharedService {

  constructor(private http: HttpClient) {}

  public pHrEmail = 'recruitment@gettheshifts.com';
  public pWebsite = 'https://gts.owidesign.com';

  locationRadius = 1000;

  mapstyles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ];

  getLogs(pUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'logs/' + String(pUserID));
  }

  addLog(pCurrentUserID: number , pUserID: number, comments: string) {
    const formData = new FormData();
    formData.append('pCurrentUserID', String(pCurrentUserID));
    formData.append('pUserID', String(pUserID));
    formData.append('pComments', comments);

    return this.http
      .post<Logs>(
        environment.apiUrl + 'logs',
        formData
      );
  }

  getDocuments(pUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'documents/' + String(pUserID));
  }

  uploadDocument(formData: any) {
    return this.http
      .post<any>(
        environment.apiUrl + 'documents',
        formData,
        {
          reportProgress: true
        }
      );
  }

  updateDocument(documentID: number , pName: string) {
    const formData = new FormData();
    formData.append('pName', pName);

    return this.http
      .post<any>(
        environment.apiUrl + 'documents/' + String(documentID),
        formData
      );
  }

  deleteDocument(pID: number) {
    return this.http
    .delete<any>(
      environment.apiUrl + 'documents/' + String(pID));
  }

  addReview(pSuperstarID: number , form: any, date: string) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pStarRating', form.pStarRating);
    formData.append('pClientID', form.pClientID);
    formData.append('pBookingID', form.pBookingID);
    formData.append('pShiftRatingID', form.pShiftRatingID);
    formData.append('pDate', date);
    formData.append('pReviews', form.pReviews);

    return this.http
      .post<any>(
        environment.apiUrl + 'reviews',
        formData
      );
  }

  getReviews(pSuperstarID: number) {
    return this.http.get<any>(environment.apiUrl + 'reviews/' + String(pSuperstarID));
  }

  addNote(pUserID: number, pCurrentUserID: number , form: any) {
    const formData = new FormData();
    formData.append('pCurrentUserID', String(pCurrentUserID));
    formData.append('pUserID', String(pUserID));
    formData.append('pNotes', form.pNotes);

    return this.http
      .post<any>(
        environment.apiUrl + 'notes',
        formData
      );
  }

  getNotes(pCurrentUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'notes/' + String(pCurrentUserID));
  }

  addStrike(pUserID: number, pCurrentUserID: number, pSuperstarID: number, form: any) {
    const formData = new FormData();
    formData.append('pCurrentUserID', String(pCurrentUserID));
    formData.append('pUserID', String(pUserID));
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pStrike', form.pStrike);

    return this.http
      .post<any>(
        environment.apiUrl + 'strikes',
        formData
      );
  }

  getStrikes(pCurrentUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'strikes/' + String(pCurrentUserID));
  }

  uploadAdminImage(pID: number, formData: any) {
    return this.http
      .post<any>(
        environment.apiUrl + 'image/admin/' + String(pID),
        formData,
        {
          reportProgress: true
        }
      );
  }
}
