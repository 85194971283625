import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { shiftViews } from '../../booking/utils/clients';
import { ProfileComponent } from '../../profile/profile.component';
import { HttpClient } from '@angular/common/http';
import { BookingService } from '../../booking/booking.service';
import 'rxjs/add/operator/map';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Shifts } from '../../booking/add-shift/shifts.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-calendar',
  templateUrl: './profile-calendar.component.html',
  styleUrls: ['./profile-calendar.component.css']
})
export class ProfileCalendarComponent implements OnInit {

  @Input() eventTemplate: TemplateRef<any>;

  view = 'week';

  viewDate: Date = new Date();

  @Input() calendarEvents: CalendarEvent[] = [];

  openedEvent: Shifts[] = [];

  activeDayIsOpen = false;

  id: number;

  shiftid: number;

  openedEventTitle: string;
  openEventDuration: string;
  openEventHours: string;

  shiftViews = shiftViews;

  selectedView: string = null;

  loadingMessage = 'Loading events...';

  constructor(
    private http: HttpClient,
    private bookingService: BookingService,
    public modal: NgbModal,
    private router: Router,
    private profileComponent: ProfileComponent
  ) {}

  ngOnInit(): void {
    this.id = this.profileComponent.pSuperstarID;
    setTimeout(() => {
      this.profileComponent.fetchEvents();
    }, 1000);
  }

  setView(viewType: string) {
    let view: CalendarView;
    if (viewType === 'Month') {
      view = CalendarView.Month;
    } else if (viewType === 'Week') {
      view = CalendarView.Week;
    } else {
      view = CalendarView.Day;
    }
    this.view = view;
  }

  getTime(date: string) {
    const day = new Date(date);
    const time = day.getHours() + ':' + day.getMinutes();
    return time;
  }

  getHours(date1: string, date2: string) {
    const oneDay =  60 * 60 * 1000;
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    let diffInMs = Math.round( Math.abs((secondDate.getTime() - firstDate.getTime()) / (oneDay)) * 10 ) / 10;
    if (Number.isNaN(diffInMs)) {
        diffInMs = 0;
    }
    return diffInMs;
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  eventClicked(event: any): void {
  }
}
