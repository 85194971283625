import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Profile } from './profile.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getProfile(pUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'profile/' + String(pUserID));
  }

  getProfileImage(pUserID: number) {
    return this.http.get<any>(environment.apiUrl + 'users/image/' + String(pUserID));
  }

  handleError(error) {
    let errorMessage = '';
    console.log(error);
    if (error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error: ${error.error.pMessage}`;
    }
    return throwError(errorMessage);
  }

}
