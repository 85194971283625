import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../booking/booking.service';
import { ActivatedRoute } from '@angular/router';
import { SuperstarsService } from '../superstars.service';
import { AuthService } from '../../auth/auth.service';
import {ValidatorService} from 'angular-iban';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-superstar-bank',
  templateUrl: './superstar-bank.component.html',
  styleUrls: ['./superstar-bank.component.css']
})
export class SuperstarBankComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public bookingService: BookingService,
    private superstarsService: SuperstarsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    configCal: NgbDatepickerConfig,
    private modal: NgbModal) {
      config.backdrop = 'static';
      config.keyboard = false;
      // customize default values of datepickers used by this component tree
      configCal.minDate = {year: 1960, month: 1, day: 1};
      configCal.maxDate = {year: 2080, month: 12, day: 31};
    }

  @Input() childSuperstarID: number;
  @Input() childSuperstarUserID: number;

  superstarBankDetails: any[] = [];
  customForm: FormGroup;
  submitted = false;
  isEditForm = false;
  loading = false;
  error: string = null;
  id: number;
  pAcountID: number;
  pCurrentUserID: number;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = +params.id || 0;

      this.initForm();

      this.authService.user.subscribe(user => {
        if (!!user) {
          this.pCurrentUserID = Number(user.pID);
        }
      });

      this.superstarsService.getBankDetailsBySuperstarID(this.childSuperstarID).subscribe((account: any[]) => {
        this.superstarBankDetails = account;
        if (this.superstarBankDetails.length > 0) {
          this.isEditForm = true;
          this.pAcountID = this.superstarBankDetails[0].pID;
          this.customForm.controls.pAccountName.setValue(this.superstarBankDetails[0].pAccountName);
          this.customForm.controls.pIBAN.setValue(this.superstarBankDetails[0].pIBAN);
          this.customForm.controls.pBIC.setValue(this.superstarBankDetails[0].pBIC);
        }
      });

    });
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

   // convenience getter for easy access to form fields
   get f() { return this.customForm.controls; }

   private initForm() {
     const pAccountName = '';
     const pIBAN = '';
     const pBIC = '';

     this.customForm = this.formBuilder.group({
        pAccountName: [pAccountName, Validators.required],
        pIBAN: [pIBAN, [Validators.required, ValidatorService.validateIban]],
        pBIC: [pBIC]
     });
   }

   onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      if (this.customForm.controls.pIBAN.invalid) {
        this.error = 'IBAN is invalid.';
      }
      return;
    } else {
      this.loading = true;
    }

    if (this.isEditForm) {
      this.superstarsService.updateSuperstarBankDetails(this.pAcountID, this.customForm.value)
      .subscribe(
        resData => {
          this.isEditForm = true;
          if (this.superstarBankDetails.length > 0) {
            this.superstarBankDetails[0].pAccountName = this.customForm.controls.pAccountName.value;
            this.superstarBankDetails[0].pIBAN = this.customForm.controls.pIBAN.value;
            this.superstarBankDetails[0].pBIC = this.customForm.controls.pBIC.value;
          }
          this.sharedService.addLog(this.pCurrentUserID, Number(this.childSuperstarUserID), 'Update Bank Details').subscribe();
          this.onCancel();
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        });

    } else {
      this.superstarsService.addSuperstarBankDetails(this.childSuperstarID, this.customForm.value)
      .subscribe(
        resData => {
          this.isEditForm = true;
          this.superstarBankDetails.push({
            pID: resData.pID,
            pSuperstarID: this.childSuperstarID,
            pAccountName: this.customForm.controls.pAccountName.value,
            pIBAN: this.customForm.controls.pIBAN.value,
            pBIC: this.customForm.controls.pBIC.value,
          });
          this.sharedService.addLog(this.pCurrentUserID, Number(this.childSuperstarUserID), 'Add Bank Details').subscribe();
          this.onCancel();
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        });
    }
  }

}
