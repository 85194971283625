import { Component,  OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { bookingViews, Priority } from '../booking/utils/clients';
import { NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { CalendarService } from './calendar.service';
import { BookingService } from '../booking/booking.service';
import { ClientsService } from '../clients/clients.service';
import { Booking } from '../booking/booking.model';
import { Rates } from '../booking/rates.model';
import { DatePipe } from '@angular/common';
import { Clients } from '../clients/clients.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})

export class CalendarComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) document,
    r: Renderer2,
    private bookingService: BookingService,
    private clientsService: ClientsService,
    private calendarService: CalendarService,
    private router: Router,
    public datepipe: DatePipe,
    private parserFormatter: NgbDateParserFormatter
    ) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
  }


  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  tempEvents: CalendarEvent[] = [];
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Week;
  viewDate = new Date();
  selectedDate: Date;
  activeDayIsOpen = true;
  eventsFiltered = [];

  bookings: Booking[];
  booking: Booking[] = [];
  selectedName: string = null;
  selectedView: string = null;
  bookingViews = bookingViews;
  selectedClient: string = null;
  clients: Clients[] = [];

  selectedShift: string = null;
  rates: Rates[] = [];

  selectedPriority: string = null;
  bookingPriority = Priority;

  numberOfHoursValue = 0;
  numberOfHours = 24;
  numberOfHoursOption = {
    floor: 0,
    ceil: 24
  };

  numberOfStaffsValue = 1;
  numberOfStaffs = 500;
  numberOfStaffOption = {
    floor: 1,
    ceil: 500
  };

  setView(viewType: string) {
    let view: CalendarView;
    if (viewType === 'Month') {
      view = CalendarView.Month;
    } else if (viewType === 'Week') {
      view = CalendarView.Week;
    } else {
      view = CalendarView.Day;
    }
    this.view = view;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  filterEventsByBookingName(event: any) {
    const bookingID = Number(event.pID);
    this.eventsFiltered = [];
    this.tempEvents.forEach((eventa) => {
      if (bookingID === Number(eventa.meta.pBookingID)) {
        this.eventsFiltered.push(eventa);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  filterEventsByShiftType(event: any) {
    const shiftID = Number(event.pID);
    this.eventsFiltered = [];
    this.tempEvents.forEach((eventa) => {
      if (shiftID === Number(eventa.meta.pShiftType)) {
        this.eventsFiltered.push(eventa);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  filterEventsByClientName(event: any) {
    const clientID = Number(event.pID);
    this.eventsFiltered = [];
    this.tempEvents.forEach((eventa) => {
      if (clientID === Number(eventa.meta.pClientID)) {
        this.eventsFiltered.push(eventa);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  filterEventsByDate(date: NgbDate) {
    let updatedDate = String(new Date(this.parserFormatter.format(date)));
    updatedDate = this.datepipe.transform(updatedDate, 'yyyy-MM-dd');
    this.eventsFiltered = [];
    this.tempEvents.forEach((event) => {
      if (updatedDate === this.datepipe.transform(event.start, 'yyyy-MM-dd')) {
        this.eventsFiltered.push(event);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  onClearAll() {
    this.events = this.tempEvents;
    this.refresh.next();
  }

  onNumberOfHoursChange() {
    this.eventsFiltered = [];
    this.tempEvents.forEach((eventa) => {
      if (this.numberOfHoursValue <= Number(eventa.meta.pLength)) {
        this.eventsFiltered.push(eventa);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  onNumberOfStaffsChange() {
    this.eventsFiltered = [];
    this.tempEvents.forEach((eventa) => {
      if (this.numberOfStaffsValue <= Number(eventa.meta.pNoStaffRequired)) {
        this.eventsFiltered.push(eventa);
      }
    });
    this.events = this.eventsFiltered;
    this.refresh.next();
  }

  loadAllEvents() {
    this.calendarService.getCalendarData().subscribe(
      (events: CalendarEvent[]) => {
        this.events = [];
        this.tempEvents = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < events.length; i++) {
            this.events.push({
                title: events[i].title,
                start: new Date(events[i].start),
                end: new Date(events[i].end),
                color: this.bookingService.getColor(String(events[i].color)),
                draggable: false,
                meta: events[i].meta,
                allDay: false
            });
            this.tempEvents.push({
              title: events[i].title,
              start: new Date(events[i].start),
              end: new Date(events[i].end),
              color: this.bookingService.getColor(String(events[i].color)),
              draggable: false,
              meta: events[i].meta,
              allDay: false
          });
        }
        this.refresh.next();
      });
  }

  eventClicked(event: any): void {
    this.router.navigate(['/booking'], { queryParams: { id: event.meta.pBookingID } });
  }

  ngOnInit() {

    this.loadAllEvents();

    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });
    this.clientsService.getClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
    });
    this.bookingService.getBookings().subscribe((bookings: Booking[]) => {
      this.bookings = bookings;
    });
  }

}
