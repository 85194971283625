import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { BookingComponent } from './booking/booking.component';
import { ClientsComponent } from './clients/clients.component';
import { SuperstarsComponent } from './superstars/superstars.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PasswordComponent } from './auth/password/password.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { Role } from './auth/role.model';

const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full',  data: { animation : 'fade'} },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin], animation : 'fade'}
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin], animation : 'fade'}
  },
  {
    path: 'booking',
    component: BookingComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin], animation : 'fade'}
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
    data: {  roles: [Role.Admin], animation : 'fade'}
  },
  {
    path: 'superstars',
    component: SuperstarsComponent,
    canActivate: [AuthGuard],
    data: {  roles: [Role.Admin], animation : 'fade'}
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { animation : 'fade'}
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
    data: { animation : 'fade'}
  },
  {
    path: 'password',
    component: PasswordComponent,
     data: { animation : 'fade'}
  },
  {
    path: 'auth',
    component: AuthComponent,
    data: { animation : 'fade'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
