import { Component, OnInit , Input} from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../../auth/auth.service';

export interface Documents {
  pID: number;
  pUserID: number;
  pName: string;
  pDocument: string;
  pImage: string;
  pCreated: string;
}

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {

  constructor(
    config: NgbModalConfig,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private authService: AuthService,
    private modal: NgbModal
    )
  {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  files: NgxFileDropEntry[] = [];
  pCurrentUserID: number;
  documentID: number;
  uploadFileForm: FormGroup;
  submitted = false;
  error: string = null;
  documents: Documents[] = [];
  @Input() pUserID: number;

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('pUserID', String(this.pUserID));
          formData.append('pName', 'Document Upload');
          formData.append('pDocument', file, droppedFile.relativePath);

          this.sharedService.uploadDocument(formData)
          .subscribe(data => {
            if (data) {
              this.documentID = data.pID;
              // Sanitized logo returned from backend
            }
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  onFileSubmit() {

    // stop here if form is invalid
    if (this.submitted) {
      if (this.uploadFileForm.invalid) {
        this.error = 'Please input file name';
        return;
      } else {
        this.sharedService.updateDocument(this.documentID , this.uploadFileForm.controls.pName.value).subscribe(data => {
          this.onCancel();
          this.sharedService.addLog(this.pCurrentUserID, Number(this.pUserID), 'Upload File').subscribe();
          this.ngOnInit();
        });
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.uploadFileForm.controls; }

  ngOnInit() {
    this.authService.user.subscribe(user => {
      if (!!user) {
        this.pCurrentUserID = Number(user.pID);
      }
    });

    this.sharedService.getDocuments(this.pUserID).subscribe((documents: Documents[]) => {
      this.documents = documents;
    });

    const pName = '';
    this.uploadFileForm = this.formBuilder.group({
      pName: [pName, [Validators.required]]
    });

  }

  removeDocument(pID: number) {
    this.sharedService.deleteDocument(pID).subscribe(data => {
      this.ngOnInit();
    });
  }
}
