import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalendarService {

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe
    ) {}

  getCalendarData() {
    return this.http.get<any>(environment.apiUrl + 'superstar/calendarpage');
  }
}
