import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { bookingLocation } from '../../utils/clients';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.css']
})
export class BookingSummaryComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public bookingService: BookingService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    private modal: NgbModal) {
      config.backdrop = 'static';
      config.keyboard = false;
    }

  @Input() childBookings: any;

  lat: number;
  lng: number;
  cordinates: any;
  pMapCordinates: string;
  countyLists = bookingLocation;

  mapstyles: any;
  locationRadius: number;
  icon: any;

  editBookingForm: FormGroup;
  editLocationForm: FormGroup;
  submittedDetails = false;
  submittedLocation = false;
  editMode = false;
  error: string = null;
  id: number;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = +params.id || 0;
      this.initForm();
      this.mapstyles = this.sharedService.mapstyles;

      this.pMapCordinates = this.childBookings[0].pMapCordinates.split('|');
      this.lat = Number(this.pMapCordinates[0]);
      this.lng = Number(this.pMapCordinates[1]);
      this.locationRadius = this.sharedService.locationRadius * 2;
      this.icon = {
        url: 'assets/images/map.svg',
        scaledSize: {
          width: 20,
          height: 20
        }
      };
    });
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editBookingForm.controls; }
  // convenience getter for easy access to form fields
  get g() { return this.editLocationForm.controls; }


  private initForm() {
    this.editMode = !this.editMode;

    let pBookingSummary = '';
    let pEntryInstructions = '';
    let pGroomingPolicy = '';
    let pUniformRequirements = '';
    let pContactAddress = '';
    let pCountyName = '';
    let pMapCordinates = '';

    if (this.editMode) {
      pBookingSummary = pBookingSummary;
      pEntryInstructions = pEntryInstructions;
      pGroomingPolicy = pGroomingPolicy;
      pUniformRequirements = pUniformRequirements;
      pContactAddress = pContactAddress;
      pCountyName = pCountyName;
      pMapCordinates = pMapCordinates;
    }

    this.editBookingForm = this.formBuilder.group({
      pBookingSummary: [pBookingSummary, [Validators.required, Validators.maxLength(200)]],
      pEntryInstructions: [pEntryInstructions, [Validators.required, Validators.maxLength(200)]],
      pGroomingPolicy: [pGroomingPolicy, [Validators.required, Validators.maxLength(200)]],
      pUniformRequirements: [pUniformRequirements, [Validators.required,   Validators.maxLength(200)]]
    });

    this.editLocationForm = this.formBuilder.group({
      pContactAddress: [pContactAddress, [Validators.required, Validators.maxLength(200)]],
      pCountyName: [pCountyName, [Validators.required]],
      pMapCordinates: [pMapCordinates, [Validators.required, Validators.maxLength(200)]],
    });

    this.editBookingForm.controls.pBookingSummary.setValue(this.childBookings[0].pBookingSummary);
    this.editBookingForm.controls.pEntryInstructions.setValue(this.childBookings[0].pEntryInstructions);
    this.editBookingForm.controls.pGroomingPolicy.setValue(this.childBookings[0].pGroomingPolicy);
    this.editBookingForm.controls.pUniformRequirements.setValue(this.childBookings[0].pUniformRequirements);

    this.cordinates = this.lng + '|' + this.lat;
    if (this.childBookings[0].pMapCordinates) {
      this.cordinates = this.childBookings[0].pMapCordinates;
    }

    this.pMapCordinates = this.cordinates.split('|');

    this.lng = Number(this.pMapCordinates[0]);
    this.lat = Number(this.pMapCordinates[1]);

    this.editLocationForm.controls.pContactAddress.setValue(this.childBookings[0].pContactAddress);
    this.editLocationForm.controls.pCountyName.setValue(this.childBookings[0].pCountyName);
    this.editLocationForm.controls.pMapCordinates.setValue(this.childBookings[0].pMapCordinates);
  }

  onUpdateLocation(event: any) {
    if (event) {
      this.editLocationForm.controls.pMapCordinates.setValue(event.cordinates);
    }
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.submittedDetails) {
      if (this.editBookingForm.invalid) {
        this.error = 'The maximum characters is 200 per field.';
        return;
      } else {
        this.bookingService.updateBooking(this.id, this.editBookingForm.value).subscribe(
          (res) => {
            this.childBookings[0].pBookingSummary = this.editBookingForm.controls.pBookingSummary.value;
            this.childBookings[0].pEntryInstructions =  this.editBookingForm.controls.pEntryInstructions.value;
            this.childBookings[0].pGroomingPolicy =  this.editBookingForm.controls.pGroomingPolicy.value;
            this.childBookings[0].pUniformRequirements =  this.editBookingForm.controls.pUniformRequirements.value;
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }

    // stop here if form is invalid
    if (this.submittedLocation) {
      if (this.editLocationForm.invalid) {
        this.error = 'Please complete all the required fields';
        return;
      } else {
        this.bookingService.updateLocation(this.id, this.editLocationForm.value).subscribe(
          (res) => {
            this.cordinates = this.editLocationForm.controls.pMapCordinates.value;
            this.childBookings[0].pContactAddress = this.editLocationForm.controls.pContactAddress.value;
            this.childBookings[0].pCountyName =  this.editLocationForm.controls.pCountyName.value;
            this.childBookings[0].pMapCordinates =  this.editLocationForm.controls.pMapCordinates.value;
            this.pMapCordinates = this.cordinates.split('|');
            this.lng = Number(this.pMapCordinates[0]);
            this.lat = Number(this.pMapCordinates[1]);
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  onCancel() {
    this.modal.dismissAll();
  }

  placeMarker($event) {
    this.editLocationForm.controls.pMapCordinates.setValue($event.coords.lng + '|' + $event.coords.lat);
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
  }

}
