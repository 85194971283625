import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Booking } from '../booking.model';
import { BookingService } from '../../booking/booking.service';
import { Clients } from '../../clients/clients.model';
import { ClientsService } from '../../clients/clients.service';
import { Radius } from '../../shared/radius';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-booking-add',
  templateUrl: './booking-add.component.html',
  styleUrls: ['./booking-add.component.css']
})
export class BookingAddComponent implements OnInit {

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private sharedService: SharedService,
    public bookingService: BookingService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    config: NgbModalConfig,
    private modal: NgbModal
  ) { }

  customForm: FormGroup;
  submitted = false;
  loading = false;
  error: string = null;
  bookings: Booking[] = [];
  today = new Date();
  clients: Clients[] = [];
  pMapCordinates: string;
  latitude = 53.350140;
  longitude = -6.266155;
  zoom: number;
  address: string;
  geoCoder: any;
  mapstyles: any;
  radiusKM = Radius;
  locationRadius: number;

  @ViewChild('searchElement', {static: false}) searchElement: ElementRef;

  ngOnInit() {

    this.mapstyles = this.sharedService.mapstyles;
    this.locationRadius = this.sharedService.locationRadius;

    this.initForm();
    this.clientsService.getClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
    });

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      // tslint:disable-next-line:new-parens
      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
        types: []
      });
      autocomplete.setComponentRestrictions({country: ['ie']});
      autocomplete.addListener('place_changed', () => {

        this.ngZone.run(() => {

          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom
          this.latitude = Number(place.geometry.location.lat());
          this.longitude = Number(place.geometry.location.lng());
          this.pMapCordinates = this.latitude + '|' + this.longitude;
          this.address = place.formatted_address;
          this.customForm.controls.pContactAddress.setValue(this.address);
          this.zoom = 12;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = Number(position.coords.latitude);
        this.longitude = Number(position.coords.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
        this.pMapCordinates = this.latitude + '|' + this.longitude;
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = Number($event.coords.lat);
    this.longitude = Number($event.coords.lng);
    this.getAddress(this.latitude, this.longitude);
    this.customForm.controls.pContactAddress.setValue(this.address);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.pMapCordinates = latitude + '|' + longitude;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  centerChange($event) {
    if ($event) {
      const km = $event.target.value;
      this.locationRadius = this.sharedService.locationRadius;
      this.locationRadius = this.locationRadius * km;
      if (this.locationRadius >= 10000) {
        this.zoom = 9;
      } else {
        this.zoom = 12;
      }
    }
  }

  onCancel() {
    this.customForm.reset();
    this.modal.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  private initForm() {
    const pClientID = '';
    const pBookingName = '';
    const pBookingSummary = '';
    const pContactName = '';
    const pContactNumber = '';
    const pContactEmail = '';
    const pContactAddress = '';

    this.customForm = this.formBuilder.group({
      pClientID: [pClientID, Validators.required],
      pBookingName: [pBookingName, Validators.required],
      pBookingSummary: [pBookingSummary, Validators.required],
      pContactName: [pContactName, Validators.required],
      pContactNumber: [pContactNumber, Validators.required],
      pContactEmail: [pContactEmail, [Validators.required, Validators.email]],
      pContactAddress: [pContactAddress, Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      return;
    } else {
      this.loading = true;
    }

    this.bookingService.addBooking(this.customForm.value, this.pMapCordinates)
      .subscribe(
        resData => {
          this.bookings.push({
              pID: resData.pID,
              pBookingName: this.customForm.value.pBookingName,
              pBookingSummary: this.customForm.value.pBookingSummary,
              pEntryInstructions: '',
              pGroomingPolicy: '',
              pUniformRequirements: '',
              pUserID: this.getUserID(this.customForm.value.pClientID),
              pClientLogo: this.getClientLogo(this.customForm.value.pClientID),
              pClientID: this.customForm.value.pClientID,
              pClientName: this.getClientName(this.customForm.value.pClientID),
              pClientTypeID: this.getClientTypeID(this.customForm.value.pClientID),
              pContactEmail: this.customForm.value.pContactEmail,
              pContactName: this.customForm.value.pContactName,
              pContactNumber: this.customForm.value.pContactNumber,
              pContactAddress: this.customForm.value.pContactAddress,
              pCountyName: '',
              pMapCordinates: this.pMapCordinates,
              pCreated: this.datePipe.transform(this.today, 'yyyy-MM-dd'),
              totals: [{
                pTotalStaffAssigned: 0,
                pNoStaffRequired: 0,
                pTotalHoursAssigned: 0,
                pNoHoursRequired: 0,
                pTotalRevenueAssigned: 0,
                pNoRevenueRequired: 0
              }]
          });
          this.onCancel();
          this.loading = false;
          this.router.navigate(['/booking'], { queryParams: { id: resData.pID } });
        },
        error => {
          this.bookingService.handleError(error.message);
          this.loading = false;
        }
      );
  }

  getClientName(pClientID: number) {
    // tslint:disable-next-line:triple-equals
    const client = this.clients.find(i => i.pID == pClientID);
    return client.pClientName;
  }

  getUserID(pClientID: number) {
    // tslint:disable-next-line:triple-equals
    const client = this.clients.find(i => i.pID == pClientID);
    return client.pUserID;
  }

  getClientLogo(pClientID: number) {
    // tslint:disable-next-line:triple-equals
    const client = this.clients.find(i => i.pID == pClientID);
    return client.pClientLogo;
  }

  getClientTypeID(pClientID: number) {
    // tslint:disable-next-line:triple-equals
    const client = this.clients.find(i => i.pID == pClientID);
    return client.pClientTypeID;
  }

}
