import { Component, OnInit , Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Superstars } from './superstars.model';
import { Rates } from '../booking/rates.model';
import { SuperstarsService } from './superstars.service';
import { Clients } from '../clients/clients.model';
import { ClientsService } from '../clients/clients.service';
import { BookingService } from '../booking/booking.service';
import { NgbModalConfig, NgbModal, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { bookingLocation } from '../booking/utils/clients';
import { LabelType } from 'ng5-slider';
import { SuperstarStatus } from '../shared/status';
import { Router } from '@angular/router';

@Component({
  selector: 'app-superstars',
  templateUrl: './superstars.component.html',
  styleUrls: ['./superstars.component.css']
})

export class SuperstarsComponent implements OnInit {

  customForm: FormGroup;
  submitted = false;
  loadingf = false;
  editMode = false;
  id: number;
  error: string = null;
  superstars: Superstars[] = [];
  mainSuperstars: Superstars[] = [];
  tempSuperstars: Superstars[] = [];
  checkedSuperstars: any[] = [];
  clients: Clients[] = [];
  rates: Rates[] = [];
  today = new Date();
  autohide = true;
  show = false;
  checkAll = false;
  bookingLocation = bookingLocation;
  autoSearchSuperstars: any;
  superstarsStatus = SuperstarStatus;

  numberOfLastWorkedValue = 1;
  numberOfLastWorked = 12;
  numberOfLastWorkedOptions = {
    floor: 1,
    ceil: 12,
    translate: (value: string, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return  value;
        case LabelType.High:
          return '> ' + value + ' Months';
        default:
          return value;
      }
    }
  };

  numberOfTotalHoursWorkedValue = 1;
  numberOfTotalHoursWorked = 1000;
  numberOfTotalHoursWorkedOptions = {
    floor: 1,
    ceil: 1000,
    translate: (value: string, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return  value;
        case LabelType.High:
          return value + ' +';
        default:
          return value;
      }
    }
  };

  numberOfAvgHoursPerMonthValue = 1;
  numberOfAvgHoursPerMonth = 200;
  numberOfAvgHoursPerMonthOptions = {
    floor: 0,
    ceil: 200,
    translate: (value: string, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return  value;
        case LabelType.High:
          return value + ' +';
        default:
          return value;
      }
    }
  };

  numberOfStarRatingValue = 0;
  numberOfStarRating = 5;
  numberOfStarRatingOptions = {
    floor: 0,
    ceil: 5
  };

  numberOfStrikesValue = 0;
  numberOfStrikes = 3;
  numberOfStrikesOptions = {
    floor: 0,
    ceil: 3
  };

  constructor(
    @Inject(DOCUMENT) document,
    private superstarsService: SuperstarsService,
    private clientsService: ClientsService,
    private bookingService: BookingService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    config: NgbModalConfig,
    private router: Router,
    private datePipe: DatePipe,
    private modal: NgbModal,
    r: Renderer2) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;
      this.editMode = this.id !== 0;
    });

    this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
      this.superstars = superstars;
    });

    this.clientsService.getClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
    });

    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });

    this.initForm();
  }

  private initForm() {
    let pFirstName = '';
    let pLastName = '';
    let pEmail = '';
    let pPhoneNumber = '';

    if (this.editMode) {
      pFirstName = pFirstName;
      pLastName = pLastName;
      pEmail = pEmail;
      pPhoneNumber = pPhoneNumber;
    }
    this.customForm = this.formBuilder.group({
      pFirstName: [pFirstName, Validators.required],
      pLastName: [pLastName, Validators.required],
      pEmail: [pEmail, [Validators.required, Validators.email]],
      pPhoneNumber: [pPhoneNumber, Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      return;
    } else {
      this.loadingf = true;
    }

    if (this.editMode) {
    } else {
      this.superstarsService.signup(
        this.customForm.value.pEmail,
        '123456', this.customForm.value.pFirstName,
        this.customForm.value.pLastName).subscribe(
        resData => {
          if (resData.pStatus) {
            this.superstarsService.addSuperstar(resData.pID, this.customForm.value).subscribe(
              resData2 => {
                if (resData2) {
                  this.mainSuperstars.push({
                    pID: resData2.pID,
                    pUserID: resData.pID,
                    pFullName: this.customForm.value.pFirstName + ' ' + this.customForm.value.pLastName,
                    pFirstName: this.customForm.value.pFirstName,
                    pLastName: this.customForm.value.pLastName,
                    pSummary: this.customForm.value.pSummary,
                    pStatusID: 2,
                    pRating: 0,
                    pSkills: 0,
                    pTotalHours: 0,
                    pAvgHours: 0,
                    pImage: '',
                    pPhoneNumber: this.customForm.value.pPhoneNumber,
                    pEmail: this.customForm.value.pEmail,
                    pAddress: '',
                    pDOB: '',
                    pPPS: '',
                    pEmployeeNo: 'GTS-' + resData2.pID,
                    pVisaRequired: false,
                    pVisaType: '',
                    pVisaExpiryDate: '',
                    pFacebook: '',
                    pInstagram: '',
                    pCreated: this.datePipe.transform(this.today, 'yyyy-MM-dd'),
                    pModified: this.datePipe.transform(this.today, 'yyyy-MM-dd')
                  });
                  this.onCancel();
                  this.customForm.reset();
                  this.loadingf = false;
                  this.router.navigate(['/superstars'], { queryParams: { id: resData2.pID } });
                } else {
                  this.error = resData.pMessage;
                  this.loadingf = false;
                }
              }
            );
          } else {
            this.error = resData.pMessage;
            this.loadingf = false;
          }
      },
      error => {
        this.superstarsService.handleError(error.message);
      }
      );
    }
  }

  onCancel() {
    this.customForm.reset();
    this.modal.dismissAll();
  }

  checkedAllSuperstars(event: any, superstars: any) {
    this.checkedSuperstars = [];
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.checkedSuperstars = superstars;
    } else {
      this.show = checked;
      this.checkedSuperstars = [];
    }
    return checked;
  }

  CheckedSuperstar(event: any, pID: number, superstar: any) {
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.checkedSuperstars.push(superstar);
    } else {
      const index = this.checkedSuperstars.findIndex(x => x.pID === pID);
      if (index > -1) {
        this.checkedSuperstars.splice(index, 1);
      }
    }
    if (this.checkedSuperstars.length === 0) {
      this.show = false;
    }
  }

  sendMail() {
    const mail = document.createElement('a');
    let emails = '';
    this.checkedSuperstars.forEach(item => {
      emails = emails + item.pEmail + ',';
    });
    mail.href = 'mailto:recruitment@gettheshifts.com?bcc=' + emails + '&subject=Get The Shifts&body=Hi ';
    mail.click();
  }

  sendSuperstarEmail(pEmail: string, pFullName: string) {
    const pSubject = 'Schedule Your Interview';
    const pMessage = `
    Congratulations! You have passed the first step to becoming a superstar with Get the Shifts!
    <br /><br />
    Now it’s time to set up an interview with us. One of our team will give you a call using WhatsApp Video Chat 
    for a video interview at a time that suits you! Just follow the link <a href="https://calendly.com/">here</a> 
    to book your slot for a Whatsapp Video interview.
    <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
    this.superstarsService.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage).subscribe();
  }

  openReminder(modalInterviewReminder) {
    this.modal.open(modalInterviewReminder, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'center-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  sendInterviewReminder(modalInterviewReminder) {
    this.checkedSuperstars.forEach(item => {
      this.superstarsService.sendInterviewReminder(item).subscribe((data: any[]) => {
        this.modal.open(modalInterviewReminder, {
          ariaLabelledBy: 'modal-basic',
          windowClass: 'center-modal'
        }).result.then((result) => {
        }, (reason) => {
        });
      });
    });
  }

  deActivate(modalDeactivateSuperstar) {
    this.modal.open(modalDeactivateSuperstar, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'center-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  deActivateSuperstars() {
    this.checkedSuperstars.forEach(item => {
    this.superstarsService.deactivateSuperstar(item.pID).subscribe((data: any[]) => {
      this.onCancel();
      this.ngOnInit();
    });
    });
  }

  removeSuperstarWarning(pWarningID: number) {
    this.superstarsService.removeSuperstarWarning(pWarningID).subscribe();
  }

  addSuperstarWarning(pSuperstarID: number, pWarningID: number, pComments: string) {
    this.superstarsService.addSuperstarWarning(pSuperstarID, pWarningID, pComments ).subscribe();
  }

  onStatusChange(event: any) {
    if (event) {
      const itemID = event.pID;
      this.superstars = [];
      this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
        this.tempSuperstars = superstars.filter(s => Number(s.pStatusID) === Number(itemID));
        this.superstars = this.tempSuperstars;
      });
    }
  }

  onRoleChange(event: any) {
    if (event) {
      const itemID = event.pID;
      this.superstars = [];
      this.superstarsService.getSuperstarsBySkillID(itemID).subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    }
  }

  onClientChange(event: any) {
    if (event) {
      const itemID = event.pID;
      this.superstars = [];
      this.superstarsService.getSuperstarsByClientID(itemID).subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    }
  }

  onClientFavouriteChange(event: any) {
    if (event) {
      const itemID = event.pID;
      this.superstars = [];
      this.superstarsService.getSuperstarsByClientFavID(itemID).subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    }
  }

  onLocationChange(event: any) {
    if (event) {
      const name = event.value;
      this.superstars = [];
      this.superstarsService.getSuperstarsByLocation(name).subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    }
  }

  onNumberOfStarRatingChange() {
    this.superstars = [];
    if (this.numberOfStarRatingValue) {
      this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
        this.tempSuperstars = superstars.filter(s => s.pRating >= this.numberOfStarRatingValue);
        this.superstars = this.tempSuperstars;
      });
    }
  }

  onNumberOfStrikesChange() {
    this.superstars = [];
    if (this.numberOfStrikesValue) {
      this.superstarsService.getSuperstarsByStrikes(this.numberOfStrikesValue).subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    }
  }

  onClearAll() {
    this.superstars = [];
    this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
      this.superstars = superstars;
    });
  }

  sortArrayCreated(a, b) {
    const x = new Date(a.pCreated);
    const y = new Date(b.pCreated);
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  }

}
