import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../booking/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperstarsService } from '../superstars.service';
import { Clients } from '../../clients/clients.model';
import { ClientsService } from '../../clients/clients.service';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../../auth/auth.service';

export class FavouriteClients {
  public pID: number;
  public pClientID: number;
  public pClientName: string;
}

@Component({
  selector: 'app-superstar-summary',
  templateUrl: './superstar-summary.component.html',
  styleUrls: ['./superstar-summary.component.css']
})
export class SuperstarSummaryComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public bookingService: BookingService,
    private router: Router,
    private superstarsService: SuperstarsService,
    private clientsService: ClientsService,
    private sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    configCal: NgbDatepickerConfig,
    private modal: NgbModal) {
      config.backdrop = 'static';
      config.keyboard = false;
      // customize default values of datepickers used by this component tree
      configCal.minDate = {year: 1960, month: 1, day: 1};
      configCal.maxDate = {year: 2080, month: 12, day: 31};
    }

  @Input() childSuperstars: any;

  editVisaForm: FormGroup;
  submittedVisa = false;
  error: string = null;
  id: number;
  pCurrentUserID: number;
  visaExpiryDate = '';
  visaExpiryDateObj: any;
  pVisaRequired = null;
  clients: Clients[] = [];
  user: any[] = [];
  selectedClients = [];

  visaTypes = [
    { label: 'Stamp 0', value: 'Stamp 0' },
    { label: 'Stamp 1', value: 'Stamp 1' },
    { label: 'Stamp 1A', value: 'Stamp 1A' },
    { label: 'Stamp 1G', value: 'Stamp 1G' },
    { label: 'Stamp 2', value: 'Stamp 2' },
    { label: 'Stamp 2A', value: 'Stamp 2A' },
    { label: 'Stamp 3',  value: 'Stamp 3' },
    { label: 'Stamp 4',  value: 'Stamp 4' },
    { label: 'Stamp 5', value: 'Stamp 5' },
    { label: 'Stamp 6', value: 'Stamp 6' }
  ];

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = +params.id || 0;
      this.initVisaForm();

      this.superstarsService.getUserBySuperstarID(this.id).subscribe(user => {
          this.user = user;
      });

      this.authService.user.subscribe(user => {
        if (!!user) {
          this.pCurrentUserID = Number(user.pID);
        }
      });

      this.clientsService.getClients().subscribe((clients: Clients[]) => {
        this.clients = clients;
      });

      this.superstarsService.getFavouriteClients(this.childSuperstars[0].pID).subscribe((selectedClients: FavouriteClients[]) => {
        this.selectedClients = selectedClients;
      });

      this.visaExpiryDateObj = this.dateCalendarSelected(this.visaExpiryDate);
    });
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  // convenience getter for easy access to form fields
  get fv() { return this.editVisaForm.controls; }

  private initVisaForm() {
    const pVisaType = '';
    const pVisaExpiryDate = '';

    this.editVisaForm = this.formBuilder.group({
      pVisaType: [pVisaType, [Validators.required]],
      pVisaExpiryDate: [pVisaExpiryDate, [Validators.required]],
    });


    this.pVisaRequired = this.childSuperstars[0].pVisaRequired;
    if (this.pVisaRequired !== '1') {
      this.pVisaRequired = null;
    }

    this.editVisaForm.controls.pVisaType.setValue(this.childSuperstars[0].pVisaType);
    this.visaExpiryDate = this.childSuperstars[0].pVisaExpiryDate;
    this.editVisaForm.controls.pVisaExpiryDate.setValue(this.childSuperstars[0].pVisaExpiryDate);
  }

  onVisaSubmit() {

    // stop here if form is invalid
    if (this.submittedVisa) {
      if (this.editVisaForm.invalid) {
        this.error = 'Please complete all the required fields';
        return;
      } else {

        this.superstarsService.updateVisaDetails(this.id,
          this.pVisaRequired,
          this.editVisaForm.controls.pVisaType.value,
          this.dateSelected(this.visaExpiryDateObj)).subscribe(
          (res) => {
            this.childSuperstars[0].pVisaRequired = this.pVisaRequired;
            this.childSuperstars[0].pVisaType = this.editVisaForm.controls.pVisaType.value;
            this.childSuperstars[0].pVisaExpiryDate = this.dateSelected(this.visaExpiryDateObj);
            this.sharedService.addLog(this.pCurrentUserID, Number(this.childSuperstars[0].pUserID), 'Update Visa').subscribe();
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  onCancel() {
    this.modal.dismissAll();
  }

  dateSelected(date: NgbDateStruct): string {
    return date ? date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2) : null;
  }

  dateCalendarSelected(value: string): NgbDateStruct {
    if (!value) {
      return null;
     }
    const temp = value.split(' ');
    const parts = temp[0].split('-');
    return {
       year: + parts[0], month: + parts[1], day: + parts[2]
      };
  }

  visaRequiredCheck(event) {
    this.pVisaRequired = null;
    if (event.target.checked) {
      this.pVisaRequired = '1';
    }
  }

  updateFavouriteClient(event) {
    this.superstarsService.getFavouriteClients(this.childSuperstars[0].pID).subscribe((selectedClients: FavouriteClients[]) => {
      selectedClients.forEach((item) => {
        this.superstarsService.deleteFavouriteClient(item.pID).subscribe();
      });
    });
    event.forEach((item) => {
      this.superstarsService.addFavouriteClients(this.childSuperstars[0].pID, item).subscribe();
    });
  }
}
