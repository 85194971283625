export const SuperstarStatus: any = [
    {
      pName: 'Active',
      pID: '1',
    },
    {
      pName: 'CV Review',
      pID: '2',
    },
    {
      pName: 'Awaiting Interview',
      pID: '3',
    },
    {
      pName: 'Reschedule Interview',
      pID: '4',
    },
    {
      pName: 'Awaiting Reference',
      pID: '5',
    },
    {
      pName: 'Awaiting Training',
      pID: '6',
    },
    {
      pName: 'Awaiting Payment',
      pID: '7',
    },
    {
      pName: 'Awaiting Paperwork',
      pID: '8',
    },
    {
      pName: 'Postponed',
      pID: '9',
    },
    {
      pName: 'Suspended - Work Permit Issue',
      pID: '10',
    },
    {
      pName: 'Suspended - Disciplinary Action',
      pID: '101',
    },
    {
      pName: 'Suspended - Three Strikes',
      pID: '102',
    },
    {
      pName: 'Suspended - Compliance',
      pID: '103',
    },
    {
      pName: 'Rejected',
      pID: '11',
    },
    {
      pName: 'Rejected - CV Review',
      pID: '111',
    },
    {
      pName: 'Rejected - Interview',
      pID: '112',
    },
    {
      pName: 'Rejected - Compliance',
      pID: '113',
    },
    {
      pName: 'Deactivated',
      pID: '12',
    },
    {
      pName: 'Drop-Off Interview',
      pID: '131',
    },
    {
      pName: 'Drop-Off Reference',
      pID: '132',
    },
    {
      pName: 'Drop-Off Payment',
      pID: '133',
    },
    {
      pName: 'Drop-Off Training',
      pID: '134',
    },
    {
      pName: 'Drop-Off Paperwork',
      pID: '135',
    }
];
