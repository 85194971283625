import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Superstars } from './superstars.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { DatePipe } from '@angular/common';

export interface AuthResponseData {
  pID: string;
  pStatus: boolean;
  pMessage: string;
}

@Injectable()
export class SuperstarsService {

  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getSuperstars() {
    return this.http.get(environment.apiUrl + 'superstars');
  }

  getSuperstarsByShiftID(pShiftID: number) {
    return this.http.get(environment.apiUrl + 'assigned/superstars/' + String(pShiftID));
  }

  getAvailableSuperstars(pShiftID: number, rateID: number) {
    return this.http.get(
      environment.apiUrl + 'notassigned/superstars/' + String(pShiftID) + '/' + String(rateID)
      );
  }

  getNotAvailableSuperstars() {
    return this.http.get(environment.apiUrl + 'assigned/superstars');
  }

  getSuperstar(pID: number) {
    return this.http.get<Superstars>(environment.apiUrl + 'superstars/' + String(pID));
  }

  getSuperstarTotals(pID: number) {
    return this.http.get<any>(environment.apiUrl + 'totals/superstars/' + String(pID));
  }

  getUserBySuperstarID(pID: number) {
    return this.http.get<any>(environment.apiUrl + 'users/superstars/' + String(pID));
  }

  getSkillsBySuperstarID(pID: number) {
    return this.http.get<any>(environment.apiUrl + 'skills/' + String(pID));
  }

  getLocationsBySuperstarID(pID: number) {
    return this.http.get<any>(environment.apiUrl + 'locations/' + String(pID));
  }

  getBankDetailsBySuperstarID(pID: number) {
    return this.http.get<any>(environment.apiUrl + 'accounts/' + String(pID));
  }

  getFavouriteSuperstars(pClientID: number) {
    return this.http.get<any>(environment.apiUrl + 'favourites/superstars/' + String(pClientID));
  }

  getNotWantWorkWithSuperstars(pClientID: number) {
    return this.http.get<any>(environment.apiUrl + 'notwantworkwith/superstars/' + String(pClientID));
  }

  getSuperstarWarnings(pSuperstarID: number) {
    return this.http.get<any>(environment.apiUrl + 'warnings/' + String(pSuperstarID));
  }

  getSuperstarsBySkillID(pSkillID: number) {
    return this.http.get(environment.apiUrl + 'superstars/skills/' + String(pSkillID));
  }

  getSuperstarsByClientID(pClientID: number) {
    return this.http.get(environment.apiUrl + 'superstars/client/' + String(pClientID));
  }

  getSuperstarsByClientFavID(pClientID: number) {
    return this.http.get(environment.apiUrl + 'superstars/clientfav/' + String(pClientID));
  }

  getSuperstarsByLocation(name: string) {
    return this.http.get(environment.apiUrl + 'superstars/location/' + name);
  }

  getSuperstarsByStrikes(noStrikes: number) {
    return this.http.get(environment.apiUrl + 'superstars/strikes/' + String(noStrikes));
  }

  getSuperstarsTotals() {
    return this.http.get(environment.apiUrl + 'totals/superstars');
  }

  addSuperstar(pUserID: string, superstars: Superstars) {
    const formData = new FormData();
    formData.append('pUserID', pUserID);
    formData.append('pEmail', superstars.pEmail);
    formData.append('pPhoneNumber', superstars.pPhoneNumber);
    formData.append('pFirstName', superstars.pFirstName);
    formData.append('pLastName', superstars.pLastName);

    return this.http
      .post<Superstars>(
        environment.apiUrl + 'superstars',
        formData
      );
  }

  addSuperstarBankDetails(pID: number, details: any) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pID));
    formData.append('pAccountName', details.pAccountName);
    formData.append('pIBAN', details.pIBAN);
    formData.append('pBIC', details.pBIC);

    return this.http
      .post<Superstars>(
        environment.apiUrl + 'accounts',
        formData
      );
  }

  superstarSignup(pUserID: string, pFullName: string, pEmail: string) {
    const formData = new FormData();
    const firstName = pFullName.split(' ').slice(0, -1).join(' ');
    const lastName = pFullName.split(' ').slice(-1).join(' ');

    formData.append('pUserID', pUserID);
    formData.append('pEmail', pEmail);
    formData.append('pPhoneNumber', '');
    formData.append('pFirstName', firstName);
    formData.append('pLastName', lastName);

    return this.http
      .post<Superstars>(
        environment.apiUrl + 'signup/superstar',
        formData
      );
  }

  addFavouriteSuperstar(pSuperstarID: number, pClientID: number) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pClientID', String(pClientID));

    return this.http
      .post<any>(
        environment.apiUrl + 'favourites/superstars',
        formData
      );
  }

  addNotWantWorkWithSuperstar(pSuperstarID: number, pClientID: number) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pClientID', String(pClientID));

    return this.http
      .post<any>(
        environment.apiUrl + 'notwantworkwith/superstars',
        formData
      );
  }

  addSuperstarWarning(pSuperstarID: number, pWarningID: number, pComments: string) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pWarningID', String(pWarningID));
    formData.append('pComments', pComments);
    return this.http
      .post<any>(
        environment.apiUrl + 'warnings',
        formData
      );
  }

  addSkills(superstarID: number, rateID: number) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(superstarID));
    formData.append('pRateID', String(rateID));
    formData.append('pValue', '0');

    return this.http
      .post<any>(
        environment.apiUrl + 'skills',
        formData
      );
  }

  addLocation(superstarID: number, location: any) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(superstarID));
    formData.append('pLocationName', location.pCountyName);
    formData.append('pLocationAddress', location.pCountyName);
    formData.append('pMapCordinates', location.pMapCordinates);

    return this.http
      .post<any>(
        environment.apiUrl + 'locations',
        formData
      );
  }

  addNewLocation(superstarID: number, pLocationName: string, pLocationAddress: string, pMapCordinates: string) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(superstarID));
    formData.append('pLocationName', pLocationName);
    formData.append('pLocationAddress', pLocationAddress);
    formData.append('pMapCordinates', pMapCordinates);

    return this.http
      .post<any>(
        environment.apiUrl + 'locations',
        formData
      );
  }

  updateLocation(ID: number, pLocationName: string, pLocationAddress: string, pMapCordinates: string) {
    const formData = new FormData();
    formData.append('pLocationName', pLocationName);
    formData.append('pLocationAddress', pLocationAddress);
    formData.append('pMapCordinates', pMapCordinates);
    return this.http
      .post<any>(
        environment.apiUrl + 'locations/' + String(ID),
        formData
      );
  }

  updateSuperstarBankDetails(pID: number, details: any) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pID));
    formData.append('pAccountName', details.pAccountName);
    formData.append('pIBAN', details.pIBAN);
    formData.append('pBIC', details.pBIC);
    return this.http
      .post<any>(
        environment.apiUrl + 'accounts/' + String(pID),
        formData
      );
  }

  updateSuperstar(superstarID: number, pAvailable: number) {
    const formData = new FormData();
    formData.append('pAvailable', String(pAvailable));
    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  updateSuperstarStatus(superstarID: number, superstars: Superstars) {
    const formData = new FormData();
    formData.append('pStatusID', String(superstars.pStatusID));

    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  changeSuperstarStatus(superstarID: number, pStatusID: number) {
    const formData = new FormData();
    formData.append('pStatusID', String(pStatusID));

    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  updateSuperstarDetails(superstarID: number, superstars: Superstars, pDOB: string ) {
    const formData = new FormData();
    formData.append('pEmail', superstars.pEmail);
    formData.append('pPhoneNumber', superstars.pPhoneNumber);
    formData.append('pFirstName', superstars.pFirstName);
    formData.append('pLastName', superstars.pLastName);
    formData.append('pDOB', pDOB);
    formData.append('pPPS', superstars.pPPS);
    formData.append('pAddress', superstars.pAddress);
    formData.append('pSummary', superstars.pSummary);
    formData.append('pFacebook', superstars.pFacebook);
    formData.append('pInstagram', superstars.pInstagram);

    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  updateProfileDetails(superstarID: number, superstars: Superstars, pDOB: string ) {
    const formData = new FormData();
    formData.append('pEmail', superstars.pEmail);
    formData.append('pPhoneNumber', superstars.pPhoneNumber);
    formData.append('pAddress', superstars.pAddress);
    formData.append('pDOB', pDOB);
    formData.append('pPPS', superstars.pPPS);
    formData.append('pSummary', superstars.pSummary);
    formData.append('pFacebook', superstars.pFacebook);
    formData.append('pInstagram', superstars.pInstagram);

    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  updateVisaDetails(superstarID: number, pVisaRequired: string, pVisaType: string, pVisaExpiryDate: string) {
    const formData = new FormData();
    formData.append('pStatusID', '1');
    formData.append('pVisaRequired', pVisaRequired);
    formData.append('pVisaType', pVisaType);
    formData.append('pVisaExpiryDate', pVisaExpiryDate);

    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(superstarID),
        formData
      );
  }

  updateUser(pUserID: number, pEmail: string, pFirstName: string , pLastName: string) {
    const formData = new FormData();
    formData.append('pEmail', pEmail);
    formData.append('pFirstname', pFirstName);
    formData.append('pSurname', pLastName);

    return this.http
      .post<any>(
        environment.apiUrl + 'users/' + String(pUserID),
        formData
      );
  }

  updateUserEmail(pUserID: number, pEmail: string) {
    const formData = new FormData();
    formData.append('pEmail', pEmail);

    return this.http
      .post<any>(
        environment.apiUrl + 'users/' + String(pUserID),
        formData
      );
  }


  updateAssignedShiftSuperstar(pID: number, pStatusID: number) {
    const formData = new FormData();
    formData.append('pStatusID', String(pStatusID));
    return this.http
      .post<any>(
        environment.apiUrl + 'assigned/superstars/' + String(pID),
        formData
      );
  }

  updateSkills(pID: number,  pValue: number) {
    const formData = new FormData();
    formData.append('pValue', String(pValue));

    return this.http
      .post<any>(
        environment.apiUrl + 'skills/' + String(pID),
        formData
      );
  }

  updateSuperstarSkill(pSuperstarID: number) {
    return this.http.get<any>(environment.apiUrl + 'superstar/skills/' + String(pSuperstarID));
  }

  uploadSuperstarImage(pID: number, formData: any) {
    return this.http
      .post<any>(
        environment.apiUrl + 'image/superstar/' + String(pID),
        formData,
        {
          reportProgress: true
        }
      );
  }

  deactivateSuperstar(pID: number) {
    const formData = new FormData();
    formData.append('pAvailable', String(0));
    formData.append('pStatusID', String(12));
    return this.http
      .post<any>(
        environment.apiUrl + 'superstars/' + String(pID),
        formData
      );
  }

  deleteAssignedShiftSuperstar(pID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'assigned/superstars/' + String(pID));
  }

  deleteLocation(pID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'locations/' + String(pID));
  }

  removedFavouriteSuperstar(pID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'favourites/superstars/' + String(pID));
  }

  removeNotWantWorkWithSuperstar(pID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'notwantworkwith/superstars/' + String(pID));
  }

  removeSuperstarWarning(pSuperstarID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'warnings/' + String(pSuperstarID));
  }

  addFavouriteClients(pSuperstarID: number, form: any) {
    const formData = new FormData();
    formData.append('pSuperstarID', String(pSuperstarID));
    formData.append('pClientID', String(form.pID));
    formData.append('pUserID', String(form.pUserID));

    return this.http
      .post<any>(
        environment.apiUrl + 'favclients',
        formData
      );
  }

  getFavouriteClients(pSuperstarID: number) {
    return this.http.get<any>(environment.apiUrl + 'favclients/' + String(pSuperstarID));
  }

  deleteFavouriteClient(pID: number) {
    return this.http
      .delete<any>(
        environment.apiUrl + 'favclients/' + String(pID));
  }


  sendInterviewReminder(superstars: Superstars) {
    const formData = new FormData();
    formData.append('pEmail', superstars.pEmail);
    formData.append('pFullName', superstars.pFullName);
    formData.append('pSubject', 'REMINDER: Schedule Your Interview');
    formData.append('pMessage', `
                    Just a quick reminder to follow the link <a href="https://calendly.com/">here</a> 
                    to book your slot for a Whatsapp Video interview with one of our recruitment team.
                    <br /><br />
                    If you are no longer interested in joining our amazing team of superstars, just let us 
                    know and we’ll stop emailing you!
                    <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`);
    return this.http
      .post<any>(
        environment.apiUrl + 'email/superstar',
        formData
      );
  }

  sendSuperstarEmail(pEmail: string, pFullName: string, pSubject: string, pMessage: string ) {
    const formData = new FormData();
    formData.append('pEmail', pEmail);
    formData.append('pFullName', pFullName);
    formData.append('pSubject', pSubject);
    formData.append('pMessage', pMessage);
    return this.http
      .post<any>(
        environment.apiUrl + 'email/superstar',
        formData
      );
  }

  signup(email: string, password: string, firstName: string, lastName: string) {

    const formData = new FormData();
    formData.append('pEmail', email);
    formData.append('pPassword', password);
    formData.append('pFirstname', firstName);
    formData.append('pSurname', lastName);
    formData.append('pRole', String(1));

    return this.http
      .post<AuthResponseData>(
        environment.apiUrl + 'users',
        formData
      );
  }

  getStatusName(pID: number) {
    let pStatus = '';
    switch (String(pID)) {
      case '1': {
        pStatus = 'Active';
        break;
      }
      case '2': {
        pStatus = 'CV Review';
        break;
      }
      case '3': {
        pStatus = 'Awaiting Interview';
        break;
      }
      case '4': {
        pStatus = 'Reschedule Interview';
        break;
      }
      case '5': {
        pStatus = 'Awaiting Reference';
        break;
      }
      case '6': {
        pStatus = 'Awaiting Training';
        break;
      }
      case '7': {
        pStatus = 'Awaiting Payment';
        break;
      }
      case '8': {
        pStatus = 'Awaiting Paperwork';
        break;
      }
      case '9': {
        pStatus = 'Postponed';
        break;
      }
      case '10': {
        pStatus = 'Suspended - Work Permit Issue';
        break;
      }
      case '101': {
        pStatus = 'Suspended - Disciplinary Action';
        break;
      }
      case '102': {
        pStatus = 'Suspended - Three Strikes';
        break;
      }
      case '103': {
        pStatus = 'Suspended - Compliance';
        break;
      }
      case '11': {
        pStatus = 'Rejected';
        break;
      }
      case '111': {
        pStatus = 'Rejected - CV Review';
        break;
      }
      case '112': {
        pStatus = 'Rejected - Interview';
        break;
      }
      case '113': {
        pStatus = 'Rejected - Compliance';
        break;
      }
      case '12': {
        pStatus = 'Deactivated';
        break;
      }
      case '131': {
        pStatus = 'Drop-Off Interview';
        break;
      }
      case '132': {
        pStatus = 'Drop-Off Reference';
        break;
      }
      case '133': {
        pStatus = 'Drop-Off Payment';
        break;
      }
      case '134': {
        pStatus = 'Drop-Off Training';
        break;
      }
      case '135': {
        pStatus = 'Drop-Off Paperwork';
        break;
      }
      default: {
        pStatus = 'All';
        break;
      }
   }
    return pStatus;
  }

  handleError(error) {
    let errorMessage = '';
    console.log(error);
    if (error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error: ${error.error.pMessage}`;
    }
    return throwError(errorMessage);
  }

}
