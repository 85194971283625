export class User {
  constructor(
    public pID: string,
    public pEmail: string,
    public pFullname: string,
    public pFirstname: string,
    public pSurname: string,
    public pToken: string,
    public pRole: string,
    private pTokenExpirationDate: Date
  ) {}

  get token() {
    if (!this.pTokenExpirationDate || new Date() > this.pTokenExpirationDate) {
      return null;
    }
    return this.pToken;
  }
}
