import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sfilter2'
})
export class SuperstarListsPipe implements PipeTransform {
    transform(value: any, args: string[]): any {
        const filter = args;
        return filter ? value.filter(item => (item.pStatusID).toString().indexOf(filter) !== -1) : value;
    }
}
