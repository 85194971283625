import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { BookingService } from '../../booking/booking.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-weekshifts',
  templateUrl: './weekshifts.component.html',
  styleUrls: ['./weekshifts.component.css']
})
export class WeekshiftsComponent implements OnInit {

  url = environment.apiUrl + 'past2/shifts/week';
  pName = [];
  pTotal = [];
  pTotal2 = [];
  barchart = [];
  pParcentageWeekTotals: number;
  pParcentageWeek2Totals: number;

  constructor(private bookingService: BookingService, private httpClient: HttpClient) { }

  ngOnInit() {

    this.bookingService.getShiftTotalsByTimeline('week').subscribe((data: any[]) => {
      if (data) {
        // tslint:disable-next-line:no-string-literal
        this.pParcentageWeekTotals = Number(data['pParcentage2Totals']);
        // tslint:disable-next-line:no-string-literal
        this.pParcentageWeek2Totals = 100 - Number(data['pParcentage2Totals']);
      }
    });

    this.httpClient.get(this.url).subscribe((result: any[]) => {
      if (result) {
        result.forEach(x => {
          this.pName.push(x.pName);
          this.pTotal.push(x.pAssignedValue);
          this.pTotal2.push(x.pRequiredValue);
        });
      }
      this.barchart = new Chart('weekShifts', {
        type: 'bar',
        data: {
          labels: this.pName,
          datasets: [{
              label: 'Hrs Filled',
              data: this.pTotal,
              backgroundColor: '#29546F'
          },
          {
            label: 'Hrs Unfilled',
            data: this.pTotal2,
            backgroundColor: '#C7D0D6'
          }]
        },
        options: {
          responsive: false,
          legend: {
            display: true,
            labels: {
              fontSize: 13,
              fontFamily: 'Montserrat',
              usePointStyle: true,
              boxWidth: 6
            }
          },
          scales: {
            xAxes: [{
              barPercentage: 0.9,
              categoryPercentage: 0.8,
              barThickness: 80,
              stacked: true,
              ticks: {
                fontSize: 13,
                fontFamily: 'Montserrat',
                padding: 10
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              display: false,
              stacked: true,
              categoryPercentage: 0.8,
              gridLines: {
                display: false
              }
            }],
          }
      }
    });
    });
  }
}
