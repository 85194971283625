import { Component, Inject, Renderer2, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { SuperstarsService } from '../superstars/superstars.service';
import { MustMatch } from './match.password';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})

export class AuthComponent implements OnInit {

  loginForm: FormGroup;
  signupForm: FormGroup;
  loginFormSubmitted = false;
  signupFormSubmitted = false;

  files: NgxFileDropEntry[] = [];
  pUserID: number;
  pSupertstarID: number;
  isCVUploaded = false;
  isLoginMode = true;
  isSignupMode = true;
  isLoading = false;
  error: string = null;

  constructor(
    private authService: AuthService,
    private superstarsService: SuperstarsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    @Inject(DOCUMENT) document, r: Renderer2,
    config: NgbModalConfig, private modalService: NgbModal
    ) {
    r.addClass(document.body, 'login');
    r.removeClass(document.body, 'page');

    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;

  }

  ngOnInit() {

    // Logout
    this.authService.logout();

    // Init login form fields
    this.loginForm = this.formBuilder.group({
      pEmail: ['', [Validators.required, Validators.email]],
      pPassword: ['', [Validators.required, Validators.minLength(6)]]
    });

    // Init signup form fields
    this.signupForm = this.formBuilder.group({
      pEmail: ['', [Validators.required, Validators.email]],
      pPassword: ['', [Validators.required, Validators.minLength(6)]],
      pPassword2: ['', Validators.required],
      pFullname: ['', Validators.required],
    }, {
      validator: MustMatch('pPassword', 'pPassword2')
    });
  }

  open(content) {
    this.modalService.open(content, {size: 'xl'});
  }

  onCancel() {
    this.modalService.dismissAll();
  }

  // Convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get s() { return this.signupForm.controls; }

  onSwitchMode() {
    this.loginForm.reset();
    this.signupForm.reset();
    this.isLoginMode = !this.isLoginMode;
    this.error = null;
  }

  onLoginFormSubmit() {
    this.loginFormSubmitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.error = 'Please enter your email and password.';
      return;
    }

    this.isLoading = true;
    const pEmail = this.loginForm.value.pEmail;
    const pPassword = this.loginForm.value.pPassword;

    this.authService.login(pEmail, pPassword).subscribe(
      resData => {
        this.error = resData.pMessage;
        this.isLoading = false;
        if (resData.pRole === '3') {
          this.router.navigate(['/dashboard']);
        } else
        if (resData.pRole === '2') {
          this.router.navigate(['/profile']);
        } else {
          this.router.navigate(['/profile']);
        }
        this.loginForm.reset();
      },
      errorMessage => {
        this.error = errorMessage.error.pMessage;
        this.isLoading = false;
      }
    );
  }

  onSignupFormSubmit() {
    this.signupFormSubmitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      this.error = 'Please complete all the required fields.';
      return;
    }

    this.isLoading = true;
    const pEmail = this.signupForm.value.pEmail;
    const pPassword = this.signupForm.value.pPassword;
    const pFullname = this.signupForm.value.pFullname;

    this.authService.signup(pEmail, pPassword, pFullname).subscribe(
      resData => {
        if (resData.pStatus) {
          this.pUserID = Number(resData.pID);
          this.superstarsService.superstarSignup(resData.pID, pFullname, pEmail).subscribe(
            resData2 => {
              if (resData2) {
                this.isLoading = false;
                this.pSupertstarID = Number(resData2.pID);
                this.isSignupMode = !this.isSignupMode;
                this.authService.handleAuthentication(
                  resData.pID,
                  resData.pEmail,
                  resData.pFullname,
                  resData.pFirstname,
                  resData.pSurname,
                  resData.pToken,
                  resData.pRole,
                  resData.expireAt
                );
              }
            },
            errorMessage => {
              this.error = errorMessage.error.pMessage;
              this.isLoading = false;
            }
          );
        } else {
          this.error = resData.pMessage;
          this.isLoading = false;
        }
      }
    );
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('pUserID', String(this.pUserID));
          formData.append('pName', 'CV Upload');
          formData.append('pDocument', file, droppedFile.relativePath);

          this.sharedService.uploadDocument(formData)
          .subscribe(data => {
            if (data) {
              this.isCVUploaded = true;
              this.sharedService.addLog(this.pUserID, Number(this.pUserID), 'CV Upload on Signup').subscribe();
              // Sanitized logo returned from backend
            }
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onCVFileSubmit(modalCVConfirmation) {
    if (this.isCVUploaded) {
      this.router.navigate(['/profile']);
      return;
    } else {
      this.modalService.open(modalCVConfirmation, {
        ariaLabelledBy: 'modal-basic',
        windowClass: 'center-modal'
      }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  noCVConfirmation() {
    this.superstarsService.changeSuperstarStatus(this.pSupertstarID, 9).subscribe(
      (res) => {
        this.sharedService.addLog(this.pUserID, Number(this.pSupertstarID), 'No CV - Postponed').subscribe();
        this.onCancel();
        this.router.navigate(['/profile']);
      },
      (err) => this.onCancel()
    );
  }

}
