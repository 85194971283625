import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css']
})
export class MonthlyReportComponent implements OnInit {

  pMonthName = [];
  pTotal = [];
  barchart = [];
  @Input() superstarID: number;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    const url = environment.apiUrl + 'superstar/bookings/' + this.superstarID;
    this.http.get(url).subscribe((result: any[]) => {
      result.forEach(x => {
        this.pMonthName.push(x.pMonthName);
        this.pTotal.push(x.pTotal);
      });
      this.barchart = new Chart('shoursMonthly', {
        type: 'bar',
        data: {
          labels: this.pMonthName,
          datasets: [{
              data: this.pTotal,
              backgroundColor: '#C00262'
          }]
        },
        options: {
          curvature: 1,
          responsive: false,
          legend: false,
          scales: {
            xAxes: [{
              barPercentage: 0.8,
              categoryPercentage: 0.8,
              barThickness: 40,
              ticks: {
                fontSize: 13,
                fontFamily: 'Montserrat',
                padding: 3,
              },
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              display: true,
              barPercentage: 0.8,
              categoryPercentage: 0.8,
              barThickness: 40,
              ticks: {
                fontSize: 13,
                fontFamily: 'Montserrat',
                padding: 30,
                stepSize: 0.25,
              },
              gridLines: {
                display: true,
              }
            }],
          }
        }
      });
    });

    const canvas2: any = document.getElementById('shoursMonthly');
    if (canvas2) {
      // Round corners
      /*
      Chart.plugins.register({
        afterDraw(chart) {
          if (chart.config.options.curvature !== undefined) {
            const datasets = chart.data.datasets;
            const ctx = canvas2.getContext('2d');
            // override the rectangle draw with ours
            datasets.forEach((dataset) => {
              const fulldata = dataset._meta[6].data;
              fulldata.forEach((item) => {
                const bar = item._view;
                const halfWidth = bar.width / 2;
                let leftX = bar.x - halfWidth;
                let rightX = bar.x + halfWidth;
                let top = bar.base - (bar.base - bar.y) - 16;
                const halfStroke = bar.strokeWidth / 2;
                let radius = halfWidth;

                if (bar.showStroke) {
                  leftX += halfStroke;
                  rightX -= halfStroke;
                  top += halfStroke;
                }

                ctx.beginPath();
                ctx.fillStyle = '#C00262';
                ctx.strokeStyle = '#C00262';
                ctx.lineWidth = this.strokeWidth;


                if (radius >  (bar.base - top) / 2) {
                  radius = (this.base - top) / 2;
                }
                ctx.lineTo(leftX, top + radius);
                ctx.quadraticCurveTo(leftX, top, leftX + radius, top);
                ctx.quadraticCurveTo(rightX, top, rightX, top + radius);

                console.log(fulldata);

                ctx.fill();
                if (bar.showStroke) {
                    ctx.stroke();
                }

              });
            });
          }
        },
      });
      */
    }
  }
}

