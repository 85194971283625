import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Superstars } from '../superstars.model';
import { SuperstarsService } from '../superstars.service';
import { BookingService } from '../../booking/booking.service';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../../auth/auth.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Rates } from '../../booking/rates.model';
import { bookingLocation } from '../../booking/utils/clients';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { SuperstarStatus } from '../../shared/status';
import { Router } from '@angular/router';

export class Skills {
  public pID: number;
  public pSuperstarID: number;
  public pRateID: number;
  public pValue: number;
}

export class Locations {
  public pID: number;
  public pLocationName: string;
  public pMapCordinates: string;
}

@Component({
  selector: 'app-superstar-details',
  templateUrl: './superstar-details.component.html',
  styleUrls: ['./superstar-details.component.css']
})
export class SuperstarDetailsComponent implements OnInit {

  constructor(
    private superstarsService: SuperstarsService,
    private bookingService: BookingService,
    private sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    private router: Router,
    private formBuilder: FormBuilder,
    private modal: NgbModal
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  superstars: Superstars[] = [];
  rates: Rates[] = [];
  skills: Skills[] = [];
  strikes: any[] = [];
  editSuperstarForm: FormGroup;
  editSkillsForm: FormGroup;
  submitted = false;
  id: number;
  pCurrentUserID: number;
  error: string = null;
  countyLists = bookingLocation;
  editLocationForm: FormGroup;
  submittedLocation = false;
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  statusTextMessage: string;
  isStatusEmail = false;

  locations = [];
  selectedLocations = [];

  files: NgxFileDropEntry[] = [];

  cordinates: any;
  pMapCordinates: string;

  mapstyles: any;
  locationRadius: number;

  superstarsStatus = SuperstarStatus;

  markers: any[] = [];

  lat = 53.3498;
  lng = -6.2603;

  icon = {
    url: 'assets/images/map.svg',
    scaledSize: {
      width: 16,
      height: 16
    }
  };

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;
    });

    this.mapstyles = this.sharedService.mapstyles;
    this.locationRadius = this.sharedService.locationRadius;

    this.authService.user.subscribe(user => {
      if (!!user) {
        this.pCurrentUserID = Number(user.pID);
      }
    });

    this.renderData();
    this.renderLocatonsData();

    this.superstarsService.getSuperstar(this.id)
    .subscribe(
      resData => {
        this.superstars.push({
          pID: resData[0].pID,
          pUserID: resData[0].pUserID,
          pFullName: resData[0].pFullName,
          pFirstName: resData[0].pFirstName,
          pLastName: resData[0].pLastName,
          pSummary: resData[0].pSummary,
          pStatusID: resData[0].pStatusID,
          pRating: resData[0].pRating,
          pSkills: resData[0].pRating,
          pTotalHours: resData[0].pTotalHours,
          pAvgHours: resData[0].pAvgHours,
          pImage: resData[0].pImage,
          pPhoneNumber: resData[0].pPhoneNumber,
          pEmail: resData[0].pEmail,
          pAddress: resData[0].pAddress,
          pDOB: resData[0].pDOB,
          pPPS: resData[0].pPPS,
          pEmployeeNo: resData[0].pEmployeeNo,
          pVisaRequired: resData[0].pVisaRequired,
          pVisaType: resData[0].pVisaType,
          pVisaExpiryDate: resData[0].pVisaExpiryDate,
          pFacebook: resData[0].pFacebook,
          pInstagram: resData[0].pInstagram,
          pCreated: resData[0].pCreated,
          pModified: resData[0].pModified
        });
        this.initSuperstarForm();
        this.initLocationForm();
        this.sharedService.getStrikes(Number(resData.pUserID)).subscribe((strikes: any[]) => {
          this.strikes = strikes;
        });
      },
      error => {
        this.superstarsService.handleError(error.message);
      }
    );
  }

  renderData() {
    this.rates = [];
    this.skills = [];
    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
      const group = [];
      this.rates.forEach((item) => {
        group[item.pID] = new FormControl('', [Validators.max(100), Validators.min(0)]);
      });

      this.editSkillsForm = this.formBuilder.group(group);
      this.superstarsService.getSkillsBySuperstarID(this.id).subscribe((skills: Skills[]) => {
        this.skills = skills;
        this.skills.forEach((item) => {
          this.editSkillsForm.controls[item.pRateID].setValue(item.pValue);
        });
      });
    });
  }

  renderLocatonsData() {
    this.markers = [];
    this.superstarsService.getLocationsBySuperstarID(this.id).subscribe((locations: Locations[]) => {
      this.selectedLocations = locations;
      // tslint:disable-next-line:forin
      for (const data in locations) {
        const pMapCordinates = locations[data].pMapCordinates.split('|');
        this.lat = Number(pMapCordinates[0]);
        this.lng = Number(pMapCordinates[1]);
        this.markers.push({
            lat: this.lat,
            lng: this.lng,
            radius: (Number(locations[data].pLocationName) * 1000),
            icon: {
              url: 'assets/images/map.svg',
              scaledSize: {
                width: 16,
                height: 16
              }
            },
        });
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editSuperstarForm.controls; }

  private initSuperstarForm() {
    const pStatusID = '';

    this.editSuperstarForm = this.formBuilder.group({
      pStatusID: [pStatusID, [Validators.required]]
    });

    this.editSuperstarForm.controls.pStatusID.setValue(this.superstars[0].pStatusID);
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.submitted) {
      if (this.editSuperstarForm.invalid) {
        this.error = 'Please complete all the required fields.';
        if (this.editSuperstarForm.controls.pSummary.invalid) {
          this.error = 'The maximum characters is 200 per field.';
        }
        return;
      } else {
        this.superstarsService.updateSuperstarStatus(this.id, this.editSuperstarForm.value).subscribe(
          (res) => {
            this.superstars[0].pStatusID = this.editSuperstarForm.controls.pStatusID.value;
            this.sharedService.addLog(this.pCurrentUserID, Number(this.superstars[0].pUserID), 'Update Status').subscribe();
            this.onSaveStatus(
              this.editSuperstarForm.controls.pStatusID.value,
              this.superstars[0].pEmail,
              this.superstars[0].pFullName
              );
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  getStatusName(pID: string) {
    return this.superstarsService.getStatusName(Number(pID));
  }

  onStatusChange(event: any) {
    if (event) {
      switch (String(event.pID)) {
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '10':
        case '101':
        case '111':
        case '112':
        case '113':
        case '12':
        case '134':
        case '135': {
          this.isStatusEmail = true;
          this.statusTextMessage = `
          You have changed Superstar status to "${event.pName}",
          Email will be triggered to Superstar on save the changes.`;
          break;
        }
        default: {
          this.isStatusEmail = false;
          this.statusTextMessage = 'You have changed Superstar status to "' + event.pName + '"';
          break;
        }
     }
    }
  }

  onSaveStatus(statusID: number, pEmail: string, pFullName: string) {
    switch (String(statusID)) {
      case '111': {
        const pSubject = 'Your Application to Join Get the Shifts';
        const pMessage = `
        Thank you for your application to Get the Shifts, and for your interest in joining our team of Superstars.
        <br /><br />
        Unfortunately you have been unsuccessful this time. Competition for roles here at Get the Shifts is always strong,
        and we often have to make tough decisions.
        <br /><br />
        If you gain more experience in the hospitality sector, please do get back in touch with an updated CV!
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '3': {
        const pSubject = 'Schedule Your Interview';
        const pMessage = `
        Congratulations! You have passed the first step to becoming a superstar with Get the Shifts!
        <br /><br />
        Now it’s time to set up an interview with us. You'll soon be sent a link to book your WhatsApp Video Interview.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '4': {
        const pSubject = 'Reschedule Your Interview';
        const pMessage = `
        I tried to call you on WhatsApp video for your scheduled interview.
        <br /><br />
        Unfortunately, I couldn’t reach you. If you would like to reschedule your interview for another time, please let me know.
        <br /><br />
        If you are no longer interested in joining our amazing team of superstars, just let us know and we’ll stop emailing you!
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '5': {
        const pSubject = 'Hurray! You Passed Your Interview!';
        const pMessage = `
        Congratulations on passing the interview stage; you’re one step closer to being a Superstar with Get the Shifts!
        <br /><br />
        Now we just need to perform a reference check. As we said on the call, if you could email through your reference 
        contact details or letter of reference (on Headed Company Paper).
        <br /><br />
        Once we hear back from your reference, we’ll let you know!
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '112': {
        const pSubject = 'Your Recent Interview with Get the Shifts';
        const pMessage = `
        Thank you for your application to Get the Shifts, and for your interest in joining our team of Superstars.
        <br /><br />
        While it was great speaking with you and learning more about your experience, unfortunately you have not been 
        selected for further consideration at this time. Competition for roles here at Get the Shifts is always strong, 
        and we often have to make tough decisions.
        <br /><br />
        If you gain more experience in the hospitality sector, please do get back in touch with an updated CV!
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '8': {
        const pSubject = 'Welcome to the Team!';
        const pMessage = `
        Welcome to Ireland's largest pool of temporary workers in the hospitality industry!
        <br /><br />
        We are different because we deliver exceptional service every time we fill a shift and as one of our 
        Superstars you are expected to represent Get the Shifts every time you work with us. We are changing the 
        way temporary staff solutions can support the hospitality industry.
        <br /><br />
        You choose when and where you work and if you don't accept a shift that is no problem! If you do accept a 
        shift and don't turn up then we will not be able to have you as part of our team anymore and that would be 
        so sad! Please don't let us down.
        <br /><br />
        By working with us, you will need to register with Revenue – once you have worked your first shift please 
        get in contact with us and we will issue the company registration number to you.
        <br /><br />
        Please sign and return the last page of the attached contract & employee handbook and fill in the Employee 
        Registration Form and return to us (We will not be able to pay you if you do not return these 3 forms to us!)
        <br /><br />
        We also have a handy facebook page for all of those awkward to get to shifts so that you, the superstars 
        can share lifts with each other.  <a href="https://www.facebook.com/groups/460179054602532/">Get The Lifts Facebook Page</a>
        ....check it out!
        <br /><br />
        And whilst you’re at it don’t forget to like and follow us on Facebook, Instagram, LinkedIn and Twitter….
        We’ll love you just a little bit more for it! If you have any questions or difficulties, please let us know!
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '7': {
        const pSubject = 'Training Invoice';
        const pMessage = `
        Thanks for sending through your reference details!
        <br /><br />
        Please find attached as discussed your invoice for the mandatory online training.
        <br /><br />
        Once payment has been received and reference has been verified we will issue you the Welcome Pack and training link.
        <br /><br />
        If you have any questions please let me know.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '6': {
        const pSubject = 'Mandatory Online Induction and Training';
        const pMessage = `
        As you know quality is everything to us here at Get the Shifts, from your contact with the office to how 
        we approach our clients and everything in between. So in an effort to offer an even higher quality service 
        we have decided to introduce our certified training modules.
        <br /><br />
        This not only means that you get to upskill or refresh your skills, it also means that we will have the best 
        service with the most experienced and trained superstars in IRELAND!
        <br /><br />
        The training will consist of different courses:
        <br />
        <ol>
          <li>A standard introduction to Get the Shifts</li>
          <li>Health & Safety</li>
          <li>Food Safety Level 1</li>
          <li>Responsible Service of Alcohol</li>
          <li>Customer Service</li>
        </ol>
        <br /><br />
        You will be sent the courses that are suitable to you but if you wish to do any others please shout 
        (or ask us in a quiet voice...we don't mind!)
        <br /><br />
        These courses are <strong>compulsory</strong> and if you wish to get shifts you must complete them, I have done all the 
        courses and they do not take very long at all!
        <br /><br />
        Please follow these simple 5 step instructions, which will enable you to self-enroll in the relevant online courses for your group:
        <br /><br />
        Learner Instructions
        <ol>
          <li>Go to https://gettheshifts.dulannelearning.com</li>
          <li>
              Click on "Create an account" below the Login and Password fields on the left hand side. 
              You will only have to create an account once.
          </li>
          <li>
              Please complete all fields that are marked with a red *-symbol and submit. You must choose your own log-in and password. 
              Please choose ones that you will remember.
          </li>
          <li>
              After accepting the disclaimer statement, you need to click on the golden "Key"-icon on 
              the right of the screen ("Group/Lesson Key").
          </li>
          <li>
              Copy and paste your Unique Group Key: DUBW into the blank field "Unique Group key" and click "Submit". 
              You are now ready to take the required courses for your group.
            </li>
        </ol>
        <br /><br />
        The Manual Handling course will require a brief practical assessment when the theory part has been completed online. 
        When you have completed the theory part online you will receive a “Passport” certificate to say you have completed 
        the theory part only.
        <br /><br />
        You must then present this certificate to the QQI Level 6 Manual Handling instructor we will arrange, and they will 
        complete the practical assessment.
        <br /><br />
        We hope you enjoy the learning experience and should you require any technical assistance, please contact support@dulann.com
        <br /><br />
        If you have any questions please let me know.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '135': {
        const pSubject = 'Your Paperwork for Get the Shifts';
        const pMessage = `
        We notice that you are still sitting in our pending pile! Just checking to see if you are still interested in 
        applying to work with us? (As we would hate to forget about someone!)
        <br /><br />
        If so, that's great, just send us a quick email and we can go through the next steps with you...if not equally 
        let us know and we can be sure to remove you from our database.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '134': {
        const pSubject = 'Your Training for Get the Shifts';
        const pMessage = `
        We notice that you are still sitting in our pending pile! Just checking to see if you are still interested in 
        applying to work with us? (As we would hate to forget about someone!)
        <br /><br />
        If so, that's great, just send us a quick email and we can go through the next steps with you...if not equally let us 
        know and we can be sure to remove you from our database.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '113': {
        const pSubject = 'Your Application for Get the Shifts';
        const pMessage = `
        Thank you for your application to Get the Shifts, and for your interest in joining our team of Superstars. 
        <br /><br />
        Unfortunately upon full review of your application, you have not been selected for further consideration. 
        Competition for roles here at Get the Shifts is always strong, and we often have to make tough decisions. 
        We wish you all the best.
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '10': {
        const pSubject = 'URGENT: Your Work Permit Has Expired';
        const pMessage = `
        We have noticed that your work permit documents have expired today. As we no longer have a valid work permit for you on file, 
        we are unable to continue to send you shifts.
        <br /><br />
        As you have been advised, your account is now suspended pending receipt of up-to-date work permit documents.
        <br /><br />
        From today, you have 30 days to send us these documents, or we will have to remove you from our system.
        <br /><br />
        <strong>If not received we will assume you no longer wish to work with Get the Shifts and 
        we will notify the accounts department to issue your P45 to revenue immediately. </strong>
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage);
        break;
      }
      case '101': {
        // tslint:disable-next-line:no-shadowed-variable
        const pEmail = this.sharedService.pHrEmail;
        const pLink = this.sharedService.pWebsite + this.router.url;
        const pSubject = 'Superstar Suspended - Disciplinary Action';
        const pMessage = `
        Just to let you know, <strong>${pFullName}</strong> has been suspended pending disciplinary action.
        <br /><br />
        HR now needs to follow up with this Superstar to engage in disciplinary proceedings.
        <br /><br />
        <strong>Take me to their profile: <a href="${pLink}">${pLink}</a></strong>
        <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
        this.sendSuperstarEmail(pEmail, 'Get The Shifts - Recruitment', pSubject, pMessage);
        break;
      }
    }
  }

  sendSuperstarEmail(pEmail: string, pFullName: string, pSubject: string, pMessage: string) {
    this.superstarsService.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage).subscribe((data: any[]) => {
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('pImage', file, droppedFile.relativePath);

          this.superstarsService.uploadSuperstarImage(this.id, formData)
          .subscribe(data => {
            this.superstars[0].pImage = data.pImage;
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onFileSubmit() {
    this.onCancel();
    this.sharedService.addLog(this.pCurrentUserID, Number(this.superstars[0].pUserID), 'Update Profile Photo').subscribe();
  }

  onSkillSubmit() {
    if (this.submitted) {
      if (this.editSkillsForm.invalid) {
        this.error = 'Please enter value between 0 to 100.';
        return;
      }
      this.skills.forEach((item, index) => {
        let pvalue = 0;
        if (this.editSkillsForm.value[item.pRateID] !== '') {
          pvalue = this.editSkillsForm.value[item.pRateID];
        }
        this.superstarsService.updateSkills(item.pID, pvalue).subscribe(
          (res) => {
            this.renderData();
            this.onCancel();
        });
      });
      this.superstarsService.updateSuperstarSkill(this.superstars[0].pID).subscribe();
      this.sharedService.addLog(this.pCurrentUserID, Number(this.superstars[0].pUserID), 'Update Skills').subscribe();
    }
  }

  getSkillName(pRateID: number) {
    // tslint:disable-next-line:triple-equals
    const rate = this.rates.find(i => i.pID == pRateID);
    return rate.pName;
  }

  placeMarker($event) {
    this.editLocationForm.controls.pMapCordinates.setValue($event.coords.lng + '|' + $event.coords.lat);
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
  }

  // convenience getter for easy access to form fields
  get g() { return this.editLocationForm.controls; }

  private initLocationForm() {

    const pCountyName = '';
    const pMapCordinates = '';

    this.editLocationForm = this.formBuilder.group({
      pCountyName: [pCountyName, [Validators.required]],
      pMapCordinates: [pMapCordinates, [Validators.required]]
    });

  }

  onUpdateLocation(event: any) {
    if (event) {
      this.editLocationForm.controls.pMapCordinates.setValue(event.cordinates);
    }
  }

  onSaveLocation() {
    this.superstarsService.getLocationsBySuperstarID(this.id).subscribe((locations: Locations[]) => {
      locations.forEach((item) => {
        // tslint:disable-next-line:triple-equals
        const location = this.selectedLocations.filter(i => i.pID == item.pID);
        if (location.length < 1) {
          this.superstarsService.deleteLocation(item.pID).subscribe(
            res => {
              this.renderLocatonsData();
            }
          );
        }
        this.onCancel();
        this.renderLocatonsData();
      });
      this.sharedService.addLog(this.pCurrentUserID, Number(this.superstars[0].pUserID), 'Update Locations').subscribe();
    });
  }

  onSubmitLocation() {
    if (this.submittedLocation) {
      if (this.editLocationForm.invalid) {
        this.error = 'Please complete all the required fields.';
        return;
      }
      this.superstarsService.addLocation(this.id, this.editLocationForm.value).subscribe(res => {

        this.selectedLocations.push({
          pID: res.pID,
          pLocationName: this.editLocationForm.value.pCountyName,
          pMapCordinates: this.editLocationForm.value.pMapCordinates
        });
        this.selectedLocations = this.selectedLocations.slice(0);

        this.editLocationForm.controls.pCountyName.setValue('');
        this.editLocationForm.controls.pMapCordinates.setValue('');

        }
      );
    }
  }

  fetchEvents(): void {
    this.events = [];
    this.bookingService.getSuperstarCalendarShifts(this.superstars[0].pID)
    .subscribe(
      (events: CalendarEvent[]) => {
        this.events = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < events.length; i++) {
            this.events.push({
                title: events[i].title,
                start: new Date(events[i].start),
                end: new Date(events[i].end),
                color: this.bookingService.getColor(String(events[i].color)),
                draggable: false,
                meta: events[i].meta,
                allDay: false
            });
        }
        this.refresh.next(true);
      });
  }
}
