import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { ProfileService } from '../../profile/profile.service';
import { User } from '../../auth/user.model';
import { Role } from '../../auth/role.model';
import { Profile } from '../../profile/profile.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  private userSub: Subscription;
  public pFullname = null;
  public user = null;
  public pImage = null;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userSub = this.authService.user.subscribe(user => {

      this.user = user;
      this.isAuthenticated = !!user;

      if (this.isAuthenticated) {
        this.pFullname = user.pFullname;
        setTimeout(() => {
          this.profileService.getProfileImage(Number(user.pID)).subscribe((profile: Profile[]) => {
            this.pImage = profile[0].pImage;
          });
          }, 1000);
      }
    });
  }

  get isLogin() {
    const currentUrl = this.router.url;
    let isLogin  = false;
    if (currentUrl === '/auth') { isLogin = true; }
    return isLogin;
  }

  get isAdmin() {
    return this.user && (this.user.pRole === Number(Role.Admin) || this.user.pRole === Role.Admin);
  }

  get isClient() {
    return this.user && (this.user.pRole === Number(Role.Client) || this.user.pRole === Role.Client);
  }

  get isSuperstars() {
    return this.user && (this.user.pRole === Number(Role.Superstar) || this.user.pRole === Role.Superstar);
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
