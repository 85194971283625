import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../shared.service';

export interface Logs {
  pID: number;
  pCurrentUserID: number;
  pUserID: number;
  pComments: string;
  pImage: string;
  pCreated: string;
}
@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.css']
})
export class RecentActivityComponent implements OnInit {

  constructor(
    private sharedService: SharedService
  ) { }

  @Input() userID: number;
  logs: Logs[] = [];

  ngOnInit() {
    this.sharedService.getLogs(this.userID).subscribe((logs: Logs[]) => {
      this.logs = logs;
    });
  }

}
