import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../shared.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

export class Strikes {
  public pID: number;
  public pCurrentUserID: number;
  public pFirstName: string;
  public pLastName: string;
  public pStrike: string;
  public pCreated: string;
}

@Component({
  selector: 'app-strikes',
  templateUrl: './strikes.component.html',
  styleUrls: ['./strikes.component.css']
})
export class StrikesComponent implements OnInit {

  constructor(
    config: NgbModalConfig,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  @Input() currentUserID: number;
  @Input() userID: number;
  addStrikeForm: FormGroup;
  submitted = false;
  error: string = null;
  strikes: Strikes[] = [];
  id: number;

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addStrikeForm.controls; }

  private initAddStrikesForm() {

    const pStrike = '';

    this.addStrikeForm = this.formBuilder.group({
      pStrike: [pStrike, [Validators.required, Validators.maxLength(1500)]],
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.submitted) {
      if (this.addStrikeForm.invalid) {
        this.error = 'The maximum characters is 1500 per field.';
        return;
      } else {
        this.sharedService.addStrike(this.userID, this.currentUserID, this.id, this.addStrikeForm.value)
        .subscribe(
          (res) => {
            this.ngOnInit();
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;

      this.initAddStrikesForm();

      this.sharedService.getStrikes(this.currentUserID).subscribe((strikes: Strikes[]) => {
        this.strikes = strikes;
      });
    });
  }

}
