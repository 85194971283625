import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Clients } from './clients.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

export interface AuthResponseData {
  pID: string;
  pStatus: boolean;
  pMessage: string;
}

@Injectable()
export class ClientsService {

  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getClients() {
    return this.http.get(environment.apiUrl + 'clients');
  }

  getClient(pID: number) {
    return this.http.get<Clients>(environment.apiUrl + 'clients/' + String(pID));
  }
  getActiveClients() {
    return this.http.get<any>(environment.apiUrl + 'totals/clients');
  }

  getClientTotals(pclientID: number) {
    return this.http.get<any>(environment.apiUrl + 'totals/client/' + String(pclientID));
  }

  getBookingTotals(pBookingID: number) {
    return this.http.get<any>(environment.apiUrl + 'totals/booking/' + String(pBookingID));
  }

  getShiftTotals(pShiftID: number) {
    return this.http.get<any>(environment.apiUrl + 'totals/shift/' + String(pShiftID));
  }

  getBookingsByClientID(pclientID: number) {
    return this.http.get(environment.apiUrl + 'bookings/client/' + String(pclientID));
  }

  addClient(pUserID: string, client: Clients) {
    const formData = new FormData();
    formData.append('pUserID', pUserID);
    formData.append('pClientName', client.pClientName);
    formData.append('pContactName', client.pContactName);
    formData.append('pContactEmail', client.pContactEmail);
    formData.append('pContactNumber', client.pContactNumber);
    formData.append('pClientTypeID', String(client.pClientTypeID));

    return this.http
      .post<Clients>(
        environment.apiUrl + 'clients',
        formData
      );
  }

  updateClient(pclientID: number, client: Clients) {
    const formData = new FormData();
    formData.append('pClientName', client.pClientName);
    formData.append('pContactName', client.pContactName);
    formData.append('pContactEmail', client.pContactEmail);
    formData.append('pContactNumber', client.pContactNumber);
    formData.append('pClientTypeID', String(client.pClientTypeID));

    return this.http
      .post<any>(
        environment.apiUrl + 'client/' + String(pclientID),
        formData
      );
  }

  signup(email: string, password: string, fullname: string) {

    const formData = new FormData();
    const firstName = fullname.split(' ').slice(0, -1).join(' ');
    const lastName = fullname.split(' ').slice(-1).join(' ');

    formData.append('pEmail', email);
    formData.append('pPassword', password);
    formData.append('pFirstname', firstName);
    formData.append('pSurname', lastName);
    formData.append('pRole', String(2));

    return this.http
      .post<AuthResponseData>(
        environment.apiUrl + 'users',
        formData
      );
  }

  uploadClientImage(pID: number, formData: any) {
    return this.http
      .post<any>(
        environment.apiUrl + 'image/client/' + String(pID),
        formData,
        {
          reportProgress: true
        }
      );
  }

  handleError(error) {
    let errorMessage = '';
    console.log(error);
    if (error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error: ${error.error.pMessage}`;
    }
    return throwError(errorMessage);
  }

}
