import { Component, OnInit, ElementRef, ViewChild, NgZone, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { SuperstarsService } from '../superstars.service';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../../auth/auth.service';
import { NgbModalConfig, NgbModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Radius } from '../../shared/radius';

export class Locations {
  public pID: number;
  public pLocationName: string;
  public pLocationAddress: string;
  public pMapCordinates: string;
}

@Component({
  selector: 'app-summary-edit',
  templateUrl: './summary-edit.component.html',
  styleUrls: ['./summary-edit.component.css']
})
export class SummaryEditComponent implements OnInit {

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private superstarsService: SuperstarsService,
    private sharedService: SharedService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    config: NgbModalConfig,
    configCal: NgbDatepickerConfig,
    private modal: NgbModal
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    // customize default values of datepickers used by this component tree
    configCal.minDate = {year: 1960, month: 1, day: 1};
    configCal.maxDate = {year: 2080, month: 12, day: 31};
  }

  @Input() childSuperstars: any;
  @Input() childUser: any;
  @Input() id: number;

  currentDate = '';
  currentDateObj: any;
  error: string = null;
  pCurrentUserID: number;
  editSuperstarForm: FormGroup;
  submittedDetails = false;
  latitude = 53.350140;
  longitude = -6.266155;
  zoom: number;
  address: string;
  geoCoder: any;
  mapstyles: any;
  radiusKM = Radius;
  locationRadius: number;
  location: Locations[];
  pMapCordinates: string;
  @ViewChild('searchElement', {static: true}) searchElement: ElementRef;

  ngOnInit() {

    if (this.childUser) {
      this.initForm();
    }

    this.authService.user.subscribe(user => {
      if (!!user) {
        this.pCurrentUserID = Number(user.pID);
      }
    });

    this.mapstyles = this.sharedService.mapstyles;
    this.locationRadius = this.sharedService.locationRadius;

    this.currentDateObj = this.dateCalendarSelected(this.currentDate);

    this.superstarsService.getLocationsBySuperstarID(this.id).subscribe((locations: Locations[]) => {
        this.location = locations;

        if (this.location.length > 0) {
          this.address = this.location[0].pLocationAddress;
          this.editSuperstarForm.controls.pAddress.setValue(this.address);
          this.editSuperstarForm.controls.pRadius.setValue(this.location[0].pLocationName);
          const cordinates = this.location[0].pMapCordinates;
          const cordinatesObj = cordinates.split('|');
          this.latitude = Number(cordinatesObj[0]);
          this.longitude = Number(cordinatesObj[1]);

          this.locationRadius = this.sharedService.locationRadius;
          this.locationRadius = this.locationRadius * Number(this.location[0].pLocationName);
          if (this.locationRadius >= 10000) {
            this.zoom = 9;
          } else {
            this.zoom = 12;
          }
        }
        // load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          // tslint:disable-next-line:new-parens
          this.geoCoder = new google.maps.Geocoder;

          const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
            types: []
          });
          autocomplete.setComponentRestrictions({country: ['ie']});
          autocomplete.addListener('place_changed', () => {

            this.ngZone.run(() => {

              // get the place result
              const place: google.maps.places.PlaceResult = autocomplete.getPlace();

              // verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              // set latitude, longitude and zoom
              this.latitude = Number(place.geometry.location.lat());
              this.longitude = Number(place.geometry.location.lng());
              this.pMapCordinates = this.latitude + '|' + this.longitude;
              this.address = place.formatted_address;
              this.editSuperstarForm.controls.pAddress.setValue(this.address);
              this.zoom = 12;
            });
          });
        });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = Number(position.coords.latitude);
        this.longitude = Number(position.coords.longitude);
        if (this.location.length > 0) {
          const cordinates = this.location[0].pMapCordinates;
          const cordinatesObj = cordinates.split('|');
          this.latitude = Number(cordinatesObj[0]);
          this.longitude = Number(cordinatesObj[1]);
        }
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = Number($event.coords.lat);
    this.longitude = Number($event.coords.lng);
    this.getAddress(this.latitude, this.longitude);
    this.editSuperstarForm.controls.pAddress.setValue(this.address);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: Number(latitude), lng: Number(longitude) } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.pMapCordinates = latitude + '|' + longitude;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  centerChange($event) {
    if ($event) {
      const km = $event.target.value;
      this.locationRadius = this.sharedService.locationRadius;
      this.locationRadius = this.locationRadius * km;
      if(this.locationRadius >= 10000) {
        this.zoom = 9;
      } else {
        this.zoom = 12;
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.editSuperstarForm.controls; }

  private initForm() {

    const pFirstName = '';
    const pLastName = '';
    const pEmail = '';
    const pDOB = '';
    const pPhoneNumber = '';
    const pAddress = '';
    const pRadius = '';
    const pPPS = '';
    const pEmployeeNo = '';
    const pSummary = '';
    const pFacebook = '';
    const pInstagram = '';

    this.editSuperstarForm = this.formBuilder.group({
      pFirstName: [pFirstName, [Validators.required]],
      pLastName: [pLastName, [Validators.required]],
      pEmail: [pEmail, [Validators.required]],
      pDOB: [pDOB, [this.dobValidator]],
      pPhoneNumber: [pPhoneNumber, [Validators.required]],
      pAddress: [pAddress, [Validators.required]],
      pRadius: [pRadius, [Validators.required]],
      pPPS: [pPPS],
      pEmployeeNo: [pEmployeeNo],
      pSummary: [pSummary],
      pFacebook: [pFacebook],
      pInstagram: [pInstagram]
    });

    if (this.childSuperstars.pDOB === '0000-00-00 00:00:00') {
      this.childSuperstars.pDOB = null;
    }
    this.editSuperstarForm.controls.pFirstName.setValue(this.childUser.pFirstname);
    this.editSuperstarForm.controls.pLastName.setValue(this.childUser.pSurname);
    this.editSuperstarForm.controls.pEmail.setValue(this.childUser.pEmail);
    this.currentDate = this.childSuperstars.pDOB;
    this.editSuperstarForm.controls.pDOB.setValue(this.childSuperstars.pDOB);
    this.editSuperstarForm.controls.pPhoneNumber.setValue(this.childSuperstars.pPhoneNumber);
    this.editSuperstarForm.controls.pAddress.setValue(this.address);
    this.editSuperstarForm.controls.pPPS.setValue(this.childSuperstars.pPPS);
    this.editSuperstarForm.controls.pEmployeeNo.disable();
    this.editSuperstarForm.controls.pEmployeeNo.setValue(this.childSuperstars.pEmployeeNo);
    this.editSuperstarForm.controls.pSummary.setValue(this.childSuperstars.pSummary);
    this.editSuperstarForm.controls.pFacebook.setValue(this.childSuperstars.pFacebook);
    this.editSuperstarForm.controls.pInstagram.setValue(this.childSuperstars.pInstagram);
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.submittedDetails) {
      if (this.editSuperstarForm.invalid) {
        this.error = 'Please complete all the required fields';
        if (this.editSuperstarForm.controls.pDOB.invalid) {
          this.error = 'Superstar must be at least 18 years old to sign up';
        }
        return;
      } else {
        this.superstarsService.updateSuperstarDetails(this.id,
          this.editSuperstarForm.value,
          this.dateSelected(this.currentDateObj)).subscribe(
          (res) => {

            this.currentDate = this.dateSelected(this.currentDateObj);
            this.childSuperstars.pFirstName = this.editSuperstarForm.controls.pFirstName.value;
            this.childSuperstars.pLastName = this.editSuperstarForm.controls.pLastName.value;
            this.childSuperstars.pFullName = this.editSuperstarForm.controls.pFirstName.value + ' '  +
            this.editSuperstarForm.controls.pLastName.value;
            this.childSuperstars.pEmail = this.editSuperstarForm.controls.pEmail.value;
            this.childSuperstars.pDOB = this.dateSelected(this.currentDateObj);
            this.childSuperstars.pPhoneNumber = this.editSuperstarForm.controls.pPhoneNumber.value;
            this.childSuperstars.pAddress = this.editSuperstarForm.controls.pAddress.value;
            this.childSuperstars.pPPS = this.editSuperstarForm.controls.pPPS.value;
            this.childSuperstars.pSummary = this.editSuperstarForm.controls.pSummary.value;
            this.childSuperstars.pFacebook = this.editSuperstarForm.controls.pFacebook.value;
            this.childSuperstars.pInstagram = this.editSuperstarForm.controls.pInstagram.value;

            if (this.location.length > 0) {
              this.superstarsService.updateLocation(
                this.location[0].pID,
                this.editSuperstarForm.controls.pRadius.value,
                this.editSuperstarForm.controls.pAddress.value,
                this.pMapCordinates).subscribe();
            } else {
              this.superstarsService.addNewLocation(
                this.id,
                this.editSuperstarForm.controls.pRadius.value,
                this.editSuperstarForm.controls.pAddress.value,
                this.pMapCordinates).subscribe();
            }

            this.superstarsService.updateUser(
              this.childSuperstars.pUserID,
              this.editSuperstarForm.controls.pEmail.value,
              this.editSuperstarForm.controls.pFirstName.value,
              this.editSuperstarForm.controls.pLastName.value
            ).subscribe();
            this.sharedService.addLog(this.pCurrentUserID, Number(this.childSuperstars.pUserID), 'Update Details').subscribe();
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  dobValidator(control: AbstractControl) {
    const currentDate = new Date();
    if (control.value) {
        const dob = new Date(control.value);
        let dobYear = dob.getFullYear();
        const maxDobYear = currentDate.getFullYear() - 18;
        if (Number.isNaN(dobYear)) {
          dobYear = Number(control.value.year);
        }
        if (maxDobYear < dobYear) {
            return { invalidDob: true };
        } else {
            return null;
        }
    }
  }

  dateSelected(date: NgbDateStruct): string {
    return date ? date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2) : null;
  }

  dateCalendarSelected(value: string): NgbDateStruct {
    if (!value) {
      return null;
     }
    const temp = value.split(' ');
    const parts = temp[0].split('-');
    return {
       year: + parts[0], month: + parts[1], day: + parts[2]
      };
  }

  onCancel() {
    this.modal.dismissAll();
  }

}
