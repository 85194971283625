export const Radius: any = [
  { id: '1 KM', value: 1 },
  { id: '2 KM', value: 2 },
  { id: '3 KM', value: 3 },
  { id: '4 KM', value: 4 },
  { id: '5 KM', value: 5 },
  { id: '6 KM', value: 6 },
  { id: '7 KM', value: 7 },
  { id: '8 KM', value: 8 },
  { id: '9 KM', value: 9 },
  { id: '10 KM', value: 10 },
  { id: '15 KM', value: 15 },
  { id: '20 KM', value: 20 },
  { id: '25 KM', value: 25 },
  { id: '30 KM', value: 30 },
  { id: '35 KM', value: 35 },
  { id: '40 KM', value: 40 },
  { id: '45 KM', value: 45 },
  { id: '50 KM', value: 50 },
  { id: '55 KM', value: 55 },
  { id: '60 KM', value: 60 },
  { id: '65 KM', value: 65 }
];