import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AuthService } from './auth/auth.service';
import { RouterOutlet } from '@angular/router';
import { fader } from './route-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fader
  ]
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthService, @Inject(DOCUMENT) document, r: Renderer2) {
    r.addClass(document.body, 'page');
    r.removeClass(document.body, 'login');
  }

  ngOnInit() {
    this.authService.autoLogin();
  }

  prepareRoute(outlet: RouterOutlet) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
