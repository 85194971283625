import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../booking/booking.service';
import { SuperstarsService } from '../../superstars/superstars.service';
import { SharedService } from '../../shared/shared.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

export interface Documents {
  pID: number;
  pUserID: number;
  pName: string;
  pDocument: string;
  pImage: string;
  pCreated: string;
}

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.css']
})
export class ProfileSummaryComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public bookingService: BookingService,
    private superstarsService: SuperstarsService,
    private sharedService: SharedService,
    config: NgbModalConfig,
    configCal: NgbDatepickerConfig,
    private modal: NgbModal) {
      config.backdrop = 'static';
      config.keyboard = false;
      // customize default values of datepickers used by this component tree
      configCal.minDate = {year: 1960, month: 1, day: 1};
      configCal.maxDate = {year: 2080, month: 12, day: 31};
    }

  @Input() childProfile: any;

  editSuperstarForm: FormGroup;
  uploadFileForm: FormGroup;
  submittedDetails = false;
  currentDate = '';
  currentDateObj: any;
  submittedFile = false;
  error: string = null;
  id: number;
  pCurrentUserID: number;
  pFullname: string;
  documentID: number;
  documents: Documents[] = [];
  files: NgxFileDropEntry[] = [];
  user: any[] = [];

  ngOnInit(): void {

    const pName = '';
    this.uploadFileForm = this.formBuilder.group({
      pName: [pName, [Validators.required]]
    });

    if (this.childProfile[0]) {
        this.pCurrentUserID = this.childProfile[0].pUserID;
        this.id = this.childProfile[0].pSuperstarID;
        this.pFullname = this.childProfile[0].pFullname;
        this.sharedService.getDocuments(this.pCurrentUserID).subscribe((documents: Documents[]) => {
          this.documents = documents;
        });

        this.superstarsService.getUserBySuperstarID(this.id).subscribe(user => {
          this.user = user;
        });
    }

  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('pUserID', String(this.pCurrentUserID));
          formData.append('pName', 'Document Upload');
          formData.append('pDocument', file, droppedFile.relativePath);

          this.sharedService.uploadDocument(formData)
          .subscribe(data => {
            if (data) {
              this.documentID = data.pID;
              // Sanitized logo returned from backend
            }
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  // convenience getter for easy access to form fields
  get g() { return this.uploadFileForm.controls; }

  onFileSubmit() {
    // stop here if form is invalid
    if (this.submittedFile) {
      if (this.uploadFileForm.invalid) {
        this.error = 'Please input file name';
        return;
      } else {
        this.sharedService.updateDocument(this.documentID , this.uploadFileForm.controls.pName.value).subscribe(data => {
          this.onCancel();
          this.sharedService.addLog(this.pCurrentUserID, Number(this.pCurrentUserID), 'Upload File').subscribe();
          this.sendSuperstarEmail(this.pFullname, this.id);
          this.ngOnInit();
        });
      }
    }
  }

  sendSuperstarEmail(pFullName: string, pID: number) {
    const pEmail = this.sharedService.pHrEmail;
    const pLink = this.sharedService.pWebsite + '/#/superstars?id=' + pID;
    const pSubject = 'New File Upload from ' + pFullName;
    const pMessage = `
    There has been a new file uploaded for <strong>${pFullName}</strong> through the Get the Shifts app. 
    Please review this file should any action be needed.
    <br /><br /> 
    <strong>Take me to their profile: <a href="${pLink}">${pLink}</a></strong>
    <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
    this.superstarsService.sendSuperstarEmail(pEmail, 'Get The Shifts - No Reply', pSubject, pMessage).subscribe((data: any[]) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }
}
