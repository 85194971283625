import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clients } from '../clients.model';
import { ClientsService } from '../clients.service';
import { BookingService } from '../../booking/booking.service';
import { AuthService } from '../../auth/auth.service';
import { Rates } from '../../booking/rates.model';
import { SuperstarsService } from '../../superstars/superstars.service';
import { Superstars } from '../../superstars/superstars.model';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Priority } from '../../booking/utils/clients';


@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent implements OnInit {

  constructor(
    private clientsService: ClientsService,
    private bookingService: BookingService,
    private superstarsService: SuperstarsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private modal: NgbModal
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  clients: Clients[] = [];
  clientTotals: any [] = [];
  clientMonthyTotals: any [] = [];
  bookings: any [] = [];
  bookingTotals: any [] = [];
  bookingMonthyTotals: any [] = [];
  clientBookings: any [] = [];
  shifts: any [] = [];
  rates: Rates[] = [];
  superstars: Superstars[] = [];
  favouriteSuperstars: any[] = [];
  notWantWorkWithSuperstars: any[] = [];
  id: number;
  editMode = false;
  pCurrentUserID: number;
  files: NgxFileDropEntry[] = [];
  isAddFavouriteSuperstar = false;
  show = false;
  autoSearch: any;
  selectedSuperstar = [];
  customForm: FormGroup;
  submitted = false;
  loadingf = false;
  error: string = null;
  priority = Priority;

  filterBy = [
    {
      label: 'Sort by name',
      value: 1,
    },
    {
      label: 'Sort by rating',
      value: 2,
    },
    {
      label: 'Sort by experience',
      value: 3,
    }
  ];

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal right-modal2'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;

      this.initForm();

      this.clientsService.getClientTotals(this.id).subscribe(data => {
        this.clientTotals = data.totals;
        this.clientMonthyTotals = data.monthlyTotals;
      });

      this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
        this.superstars = superstars;
      });
    });

    this.authService.user.subscribe(user => {
      if (!!user) {
        this.pCurrentUserID = Number(user.pID);
      }
    });

    this.fetchClientData();
    this.getSuperstars();
  }

  fetchClientData() {
    this.clientsService.getClient(this.id)
    .subscribe(
      resData => {
          this.clients.push({
            pID: resData.pID,
            pUserID: resData.pUserID,
            pClientTypeID: resData.pClientTypeID,
            pClientName: resData.pClientName,
            pClientLogo: resData.pClientLogo,
            pLastBooking: resData.pLastBooking,
            pTotalHoursBooked: resData.pTotalHoursBooked,
            pTotalRevenue: resData.pTotalRevenue,
            pContactName: resData.pContactName,
            pContactNumber: resData.pContactNumber,
            pContactEmail: resData.pContactEmail,
            pCreated: resData.pCreated,
            pModified: resData.pModified,
            bookings: []
          });

          this.customForm.controls.pClientName.setValue(resData.pClientName);
          this.customForm.controls.pClientTypeID.setValue(resData.pClientTypeID);
          this.customForm.controls.pContactName.setValue(resData.pContactName);
          this.customForm.controls.pContactNumber.setValue(resData.pContactNumber);
          this.customForm.controls.pContactEmail.setValue(resData.pContactEmail);
          this.fetchBookingData();
        });
  }

  fetchBookingData() {
    this.clientsService.getBookingsByClientID(this.id).subscribe((bookingdata: any []) => {
      const newBookingData = [];
      bookingdata.forEach(booking => {
        if (newBookingData.findIndex(i => i.pID === booking.pID) === -1) {
            newBookingData.push(booking);
        }
      });
      newBookingData.forEach((booking, index) => {
        this.bookings.push({
            pID: booking.pID,
            pBookingName: booking.pBookingName,
            pBookingSummary: booking.pBookingSummary,
            pEntryInstructions: booking.pEntryInstructions,
            pGroomingPolicy: booking.pGroomingPolicy,
            pUniformRequirements: booking.pUniformRequirements,
            pClientID: booking.pClientID,
            pContactEmail: booking.pContactEmail,
            pContactName: booking.pContactName,
            pContactNumber: booking.pContactNumber,
            pContactAddress: booking.pContactAddress,
            pMapCordinates: booking.pContactAddress,
            pCreated: booking.pCreated,
            pTotalNoStaffRequired: 0,
            shifts: [],
            totals: []
        });
        this.clients[0].bookings = this.bookings;
        this.fetchShiftsData(index, booking.pID);
      });
  });
  }

  fetchShiftsData(index: number, bookingID: number) {
    let totalNoStaffRequired = 0;

    this.clientsService.getBookingTotals(bookingID).subscribe(data => {
      this.bookings[index].totals = data;
    });

    this.bookingService.getCalendarShifts(bookingID).subscribe((resData: any []) => {
      resData.forEach(shift => {
        totalNoStaffRequired = totalNoStaffRequired + Number(shift.meta.pNoStaffRequired);
        this.clientsService.getShiftTotals(shift.meta.pid).subscribe(data => {
          this.bookings[index].shifts.push({
            pShiftName: shift.title,
            pShiftType: shift.meta.pShiftType,
            pNoStaffRequired: shift.meta.pNoStaffRequired,
            pTotalStaffAssigned: data[0].pTotalStaffAssigned
          });
        });
      });
      this.bookings[index].pTotalNoStaffRequired = totalNoStaffRequired;
    });
  }

  sortArrayCreated(a, b) {
    const x = a.pCreated.toLowerCase();
    const y = b.pCreated.toLowerCase();
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('pClientLogo', file, droppedFile.relativePath);

          this.clientsService.uploadClientImage(this.id, formData)
          .subscribe(data => {
            // Sanitized logo returned from backend
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  onFileSubmit() {
    this.onCancel();
    this.clients = [];
    this.ngOnInit();
  }

  private initForm() {
    let pClientName = '';
    let pContactName = '';
    let pContactNumber = '';
    let pContactEmail = '';
    let pClientTypeID = '';

    if (this.editMode) {
      pClientName = pClientName;
      pContactName = pContactName;
      pContactNumber = pContactNumber;
      pContactEmail = pContactEmail;
      pClientTypeID = pClientTypeID;
    }
    this.customForm = this.formBuilder.group({
      pClientName: [pClientName, Validators.required],
      pContactName: [pContactName, [Validators.required, this.contactNameValidator]],
      pContactNumber: [pContactNumber, Validators.required],
      pContactEmail: [pContactEmail, [Validators.required, Validators.email]],
      pClientTypeID: [pClientTypeID, Validators.required]
    });
  }

  contactNameValidator(control: AbstractControl) {
    if (control.value) {
        const name = control.value;
        const fullName = name.split(' ');
        if (fullName.length < 2) {
            return { invalidDob: true };
        } else {
            return null;
        }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      if (this.customForm.controls.pContactName.invalid) {
        this.error = 'Please input a first name and surname for contact.';
      }
      return;
    } else {
      this.loadingf = true;
    }

    this.clientsService.updateClient(this.id, this.customForm.value).subscribe(
      resData => {
        if (resData) {
          this.clients[0].pClientName = this.customForm.controls.pClientName.value;
          this.clients[0].pClientTypeID = this.customForm.controls.pClientTypeID.value;
          this.clients[0].pContactName = this.customForm.controls.pContactName.value;
          this.clients[0].pContactNumber = this.customForm.controls.pContactNumber.value;
          this.clients[0].pContactEmail = this.customForm.controls.pContactEmail.value;
          this.onCancel();
          this.loadingf = false;
        }
      }
    );
  }

  findSuperstars(templateID: any) {
    this.isAddFavouriteSuperstar = true;
    this.show = false;
    this.selectedSuperstar = [];
    this.open(templateID);
  }

  findSuperstarsNotWantWorkWith(templateID: any) {
    this.isAddFavouriteSuperstar = false;
    this.show = false;
    this.selectedSuperstar = [];
    this.open(templateID);
  }

  sortSuperstarBy(event: any) {
    const value = event.value;
    this.superstarsService.getSuperstars().subscribe((superstars: Superstars[]) => {
      this.superstars = superstars;
      if (value === 1) {
        this.superstars = this.superstars.sort();
      } else if (value === 2) {
        this.superstars = this.superstars.filter(s => s.pRating >= 4);
      } else if (value === 3) {
        this.superstars = this.superstars.filter(s => s.pSkills >= 20);
      }
    });
  }

  checkedSuperstar(event: any, pID: number, pEmail: string) {
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.selectedSuperstar.push({
        eventID: pID,
        eventEmail: pEmail
      });
    } else {
      const index: number = this.selectedSuperstar.findIndex(x => x.eventEmail === pEmail);
      if (index !== -1) {
        this.selectedSuperstar.splice(index, 1);
      }
    }
  }

  getSuperstars() {
    this.superstarsService.getFavouriteSuperstars(this.id).subscribe((superstars: any[]) => {
      this.favouriteSuperstars = superstars;
    });

    this.superstarsService.getNotWantWorkWithSuperstars(this.id).subscribe((superstars: any[]) => {
      this.notWantWorkWithSuperstars = superstars;
    });
  }

  addFavouriteSuperstars() {
    this.selectedSuperstar.forEach(item => {
      this.superstarsService.addFavouriteSuperstar(item.eventID, this.id)
        .subscribe(
          (res) => {
            this.onCancel();
            this.getSuperstars();
          },
          (err) => this.onCancel()
        );
    });
  }

  addNotWantWorkWithSuperstars() {
    this.selectedSuperstar.forEach(item => {
      this.superstarsService.addNotWantWorkWithSuperstar(item.eventID, this.id)
        .subscribe(
          (res) => {
            this.onCancel();
            this.getSuperstars();
          },
          (err) => this.onCancel()
        );
    });
  }

  removeFavouriteSuperstars(item: any) {
    this.superstarsService.removedFavouriteSuperstar(item.pID)
    .subscribe(
      (res) => {
        this.getSuperstars();
      }
      );
  }

  removeNotWantWorkWithSuperstars(item: any) {
    this.superstarsService.removeNotWantWorkWithSuperstar(item.pID)
    .subscribe(
      (res) => {
        this.getSuperstars();
      }
      );
  }
}
