export const bookingViews: any[] = [
    { value: 'Week', label: 'Week View' },
    { value: 'Month', label: 'Month View' },
    { value: 'Day', label: 'Day View' }
];

export const shiftViews: any[] = [
    { value: 'Week', label: '3 Days View' },
    { value: 'Day', label: 'Day View' }
];

export const Priority: any[] = [
    { value: '1', label: 'Bronze' },
    { value: '2', label: 'Gold' },
    { value: '3', label: 'Silver' }
];

export const bookingLocation: any[] = [
    { value: 'Antrim', label: 'Antrim', cordinates: '-6.143637910742768|54.864725500000006' },
    { value: 'Armagh', label: 'Armagh', cordinates: '-6.6540432|54.3481977' },
    { value: 'Carlow', label: 'Carlow', cordinates: '-6.9261131|52.8408344' },
    { value: 'Clare', label: 'Clare', cordinates: '-8.937435925994537|52.857257450000006' },
    { value: 'Cavan', label: 'Cavan', cordinates: '-8.937435925994537|52.857257450000006' },
    { value: 'Cork', label: 'Cork', cordinates: '-8.4705806|51.8979282' },
    { value: 'Derry', label: 'Derry', cordinates: '-7.3213056|54.9978678' },
    { value: 'Donegal', label: 'Donegal', cordinates: '-7.95238521465131|54.92075415' },
    { value: 'Down', label: 'Down', cordinates: '-5.961567692639536|54.3517857' },
    { value: 'Dublin', label: 'Dublin', cordinates: '-6.2602732|53.3497645' },
    { value: 'Fermanagh', label: 'Fermanagh', cordinates: '-7.65671064264496|54.361874650000004' },
    { value: 'Galway', label: 'Galway', cordinates: '-9.0490632|53.2744122' },
    { value: 'Kerry', label: 'Kerry', cordinates: '-9.517401092833236|52.14533445' },
    { value: 'Kildare', label: 'Kildare', cordinates: '-6.8184175660976445|53.15436455' },
    { value: 'Kilkenny', label: 'Kilkenny', cordinates: '-7.2484948|52.6510216' },
    { value: 'Laois', label: 'Laois', cordinates: '-7.3980337750324185|52.9984575' },
    { value: 'Leitrim', label: 'Leitrim', cordinates: '-8.052478216276088|54.140162200000006' },
    { value: 'Limerick', label: 'Limerick', cordinates: '-8.6301239|52.661252' },
    { value: 'Longford', label: 'Longford', cordinates: '-7.7963816|53.7278' },
    { value: 'Louth', label: 'Louth', cordinates: '-6.532050201271116|53.90628485' },
    { value: 'Mayo', label: 'Mayo', cordinates: '-9.298304863654256|53.9087056' },
    { value: 'Meath', label: 'Meath', cordinates: '-6.588529492009938|53.649784350000004' },
    { value: 'Monaghan', label: 'Monaghan', cordinates: '-6.9692664|54.2475233' },
    { value: 'Offaly', label: 'Offaly', cordinates: '-7.810301873855741|53.1361633' },
    { value: 'Roscommon', label: 'Roscommon', cordinates: '-8.19068|53.6312446' },
    { value: 'Sligo', label: 'Sligo', cordinates: '-8.4751357|54.2720696' },
    { value: 'Tipperary', label: 'Tipperary', cordinates: '-8.1614446|52.4734839' },
    { value: 'Tyrone', label: 'Tyrone', cordinates: '-7.261142574271752|54.63536485' },
    { value: 'Waterford', label: 'Waterford', cordinates: '-7.1119081|52.2609997' },
    { value: 'Westmeath', label: 'Westmeath', cordinates: '-7.3478558260097575|53.5577902' },
    { value: 'Wexford', label: 'Wexford', cordinates: '-6.4601397|52.3367671' },
    { value: 'Wicklow', label: 'Wicklow', cordinates: '-6.0414184|52.9802376' },
];


