import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'superfilter'
})
export class SbookingPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!args) {
         return value;
        }
        return value.filter(
          item => item.pFullName.toLowerCase().indexOf(args.toLowerCase()) > -1
       );
      }
}
