import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { Booking } from './booking.model';
import { Shifts } from './add-shift/shifts.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { colors } from '../calendar/utils/colors';

@Injectable()
export class BookingService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe) {}

  bookingsChanged = new Subject<Booking[]>();
  bookings: Booking[] = [];
  rateAmount: number;

  shiftsChanged = new Subject<Shifts[]>();
  private shifts: Shifts[] = [];

  getBookings() {
    return this.http.get(environment.apiUrl + 'bookings');
  }

  getBooking(pID: number) {
    return this.http.get<Booking>(environment.apiUrl + 'bookings/' + String(pID));
  }

  getShifts() {
    return this.http.get(environment.apiUrl + 'shifts');
  }

  getShiftbyID(pID: number) {
    return this.http.get(environment.apiUrl + 'shifts/' + String(pID));
  }

  getCalendarLatestShifts(pBookingID: number) {
    return this.http.get(environment.apiUrl + 'shifts/booking/' + String(pBookingID) + '/latest');
  }

  getCalendarShifts(pBookingID: number) {
    return this.http.get(environment.apiUrl + 'shifts/calendar/' + String(pBookingID));
  }

  getSuperstarCalendarShifts(pSuperstarID: number) {
    return this.http.get(environment.apiUrl + 'superstar/calendar/' + String(pSuperstarID));
  }

  getShiftTotals(pBookingID: number) {
    return this.http.get(environment.apiUrl + 'shifts/booking/' + String(pBookingID));
  }

  getShiftTotalsByTimeline(pTimeline: string) {
    return this.http.get(environment.apiUrl + 'past/shifts/' + String(pTimeline));
  }

  getRates() {
    return this.http.get(environment.apiUrl + 'rates');
  }

  getRate(rateID: number) {
    return this.http.get(environment.apiUrl + 'rates/' + String(rateID));
  }

  addBooking(booking: Booking, pMapCordinates: string) {
    const formData = new FormData();
    formData.append('pClientID', booking.pClientID);
    formData.append('pBookingName', booking.pBookingName);
    formData.append('pBookingSummary', booking.pBookingSummary);
    formData.append('pContactName', booking.pContactName);
    formData.append('pContactEmail', booking.pContactEmail);
    formData.append('pContactNumber', booking.pContactNumber);
    formData.append('pContactAddress', booking.pContactAddress);
    formData.append('pMapCordinates', pMapCordinates);

    return this.http
      .post<Booking>(
        environment.apiUrl + 'bookings',
        formData
      );
  }

  addShift(shifts: Shifts, pEndShiftDate: string) {
    const formData = new FormData();
    formData.append('pBookingID', String(shifts.pBookingID));
    formData.append('pShiftDate', this.datePipe.transform(shifts.pShiftDate, 'yyyy-MM-dd'));
    formData.append('pEndShiftDate', pEndShiftDate);
    formData.append('pStartTime', shifts.pStartTime);
    formData.append('pEndTime', shifts.pEndTime);
    formData.append('pLength', shifts.pLength);
    formData.append('pShiftType', shifts.pShiftType);
    formData.append('pNoStaffRequired', shifts.pNoStaffRequired);
    formData.append('pContactName', shifts.pContactName);
    formData.append('pContactNumber', shifts.pContactNumber);
    formData.append('pUniformRequirements', shifts.pUniformRequirements);

    return this.http
      .post<Shifts>(
        environment.apiUrl + 'shifts',
        formData
      );
  }

  addSuperstarAssignedShifts(selectedSuperstar: any, selectedShiftID: number, selectedShiftHours: number, totalRevenue: number) {
    const formData = new FormData();
    formData.append('pShiftID', String(selectedShiftID));
    formData.append('pSuperstarID', selectedSuperstar.eventID);
    formData.append('pTotalHours', String(selectedShiftHours));
    formData.append('pAvgHours', String(selectedShiftHours));
    formData.append('pTotalRevenue', String(totalRevenue));
    formData.append('pStatusID', '0');
    return this.http
      .post<any>(
        environment.apiUrl + 'assigned/superstars',
        formData
      );
  }

  updateBooking(index: number, booking: Booking) {
    const formData = new FormData();
    formData.append('pBookingSummary', booking.pBookingSummary);
    formData.append('pEntryInstructions', booking.pEntryInstructions);
    formData.append('pGroomingPolicy', booking.pGroomingPolicy);
    formData.append('pUniformRequirements', booking.pUniformRequirements);

    return this.http
      .post<any>(
        environment.apiUrl + 'booking/' + String(index),
        formData
      );
  }

  updateBookingDetails(index: number, booking: Booking, pMapCordinates: string) {
    const formData = new FormData();
    formData.append('pClientID', booking.pClientID);
    formData.append('pBookingName', booking.pBookingName);
    formData.append('pBookingSummary', booking.pBookingSummary);
    formData.append('pContactName', booking.pContactName);
    formData.append('pContactNumber', booking.pContactNumber);
    formData.append('pContactEmail', booking.pContactEmail);
    formData.append('pContactAddress', booking.pContactAddress);
    formData.append('pMapCordinates', pMapCordinates);

    return this.http
      .post<any>(
        environment.apiUrl + 'booking/' + String(index),
        formData
      );
  }

  updateShift(index: number, shift: Shifts, pEndShiftDate: string, pLength: number) {
    const formData = new FormData();
    formData.append('pShiftDate', this.datePipe.transform(shift.pShiftDate, 'yyyy-MM-dd'));
    formData.append('pStartTime', shift.pStartTime);
    if (pEndShiftDate) {
      formData.append('pEndShiftDate', pEndShiftDate);
    }
    formData.append('pEndTime', shift.pEndTime);
    formData.append('pLength', String(pLength));
    formData.append('pShiftType', shift.pShiftType);
    formData.append('pNoStaffRequired', shift.pNoStaffRequired);
    formData.append('pContactName', shift.pContactName);
    formData.append('pContactNumber', shift.pContactNumber);
    formData.append('pUniformRequirements', shift.pUniformRequirements);
    formData.append('pEntryInstructions', shift.pEntryInstructions);
    formData.append('pGroomingPolicy', shift.pGroomingPolicy);

    return this.http
      .post<any>(
        environment.apiUrl + 'shift/' + String(index),
        formData
      );
  }

  updateLocation(index: number, newBooking: Booking) {
    const formData = new FormData();
    formData.append('pContactAddress', newBooking.pContactAddress);
    formData.append('pCountyName', newBooking.pCountyName);
    formData.append('pMapCordinates', newBooking.pMapCordinates);

    return this.http
      .post<any>(
        environment.apiUrl + 'booking/' + String(index),
        formData
      );
  }

  deleteBooking(index: number) {
    this.bookings.splice(index, 1);
    this.bookingsChanged.next(this.bookings.slice());
  }

  getColor(color: string) {
    let colorz;
    switch (color) {
      case '1': {
        colorz = colors.red;
        break;
      }
      case '2': {
        colorz = colors.yellow;
        break;
      }
      case '3': {
        colorz = colors.green;
        break;
      }
      case '4': {
        colorz = colors.blue;
        break;
      }
      default: {
        colorz = colors.red;
        break;
      }
   }
    return colorz;
  }

  handleError(error) {
    let errorMessage = '';
    if (error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error: ${error.error.pMessage}`;
    }
    return throwError(errorMessage);
  }
}
