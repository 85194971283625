import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'clientsFilter'
})
export class FiltersPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!args) {
         return value;
        }
        return value.filter(
          item => item.pClientName.toLowerCase().indexOf(args.toLowerCase()) > -1
       );
      }
}
