import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Booking } from '../../booking/booking.model';
import { Rates } from '../../booking/rates.model';
import { Clients } from '../../clients/clients.model';
import { ClientsService } from '../../clients/clients.service';
import { BookingService } from '../../booking/booking.service';
import { SharedService } from '../shared.service';
import { NgbModalConfig, NgbModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Reviews {
  public pStarRating: number;
  public pClientName: string;
  public pBookingName: string;
  public pShiftName: string;
  public pShiftType: number;
  public pDate: string;
  public pReviews: string;
}

@Component({
  selector: 'app-client-reviews',
  templateUrl: './client-reviews.component.html',
  styleUrls: ['./client-reviews.component.css']
})
export class ClientReviewsComponent implements OnInit {

  constructor(
    config: NgbModalConfig,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private clientsService: ClientsService,
    public bookingService: BookingService,
    private sharedService: SharedService,
    configCal: NgbDatepickerConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    // customize default values of datepickers used by this component tree
    configCal.minDate = {year: 2019, month: 1, day: 1};
    configCal.maxDate = {year: 2080, month: 12, day: 31};
  }

  @Input() superstarID: number;
  addReviewForm: FormGroup;
  submitted = false;
  error: string = null;
  clients: Clients[] = [];
  bookings: Booking[] = [];
  rates: Rates[] = [];
  reviews: Reviews[] = [];
  currentDate = '';
  currentDateObj: any;

  starRatings = [
    {pID: 1, pName: '1 Star'},
    {pID: 2, pName: '2 Stars'},
    {pID: 3, pName: '3 Stars'},
    {pID: 4, pName: '4 Stars'},
    {pID: 5, pName: '5 Stars'}
  ];

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  dateSelected(date: NgbDateStruct): string {
    return date ? date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2) : null;
  }

  dateCalendarSelected(value: string): NgbDateStruct {
    if (!value) {
      return null;
     }
    const temp = value.split(' ');
    const parts = temp[0].split('-');
    return {
       year: + parts[0], month: + parts[1], day: + parts[2]
      };
  }

  changeClient(event: any) {
    const clientID = event.pID;
    this.bookings = [];
    this.bookingService.getBookings().subscribe((bookings: Booking[]) => {
      this.bookings = bookings.filter(booking => booking.pClientID === clientID);
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addReviewForm.controls; }

  private initAddReviewForm() {

    const pStarRating = '';
    const pClientID = '';
    const pBookingID = '';
    const pShiftRatingID = '';
    const pDate = '';
    const pReviews = '';

    this.addReviewForm = this.formBuilder.group({
      pStarRating: [pStarRating, [Validators.required]],
      pClientID: [pClientID, [Validators.required]],
      pBookingID: [pBookingID, [Validators.required]],
      pShiftRatingID: [pShiftRatingID, [Validators.required]],
      pDate: [pDate, [Validators.required]],
      pReviews: [pReviews, [Validators.required, Validators.maxLength(200)]],
    });
  }

  onSubmit() {
    // stop here if form is invalid
    this.submitted = true;
    if (this.submitted) {
      if (this.addReviewForm.invalid) {
        this.error = 'Please complete all required fields.';
        return;
      } else {
        this.sharedService.addReview(this.superstarID, this.addReviewForm.value, this.dateSelected(this.currentDateObj))
        .subscribe(
          (res) => {
            this.ngOnInit();
            this.onCancel();
          },
          (err) => this.onCancel()
        );
      }
    }
  }

  ngOnInit() {

    this.initAddReviewForm();

    this.clientsService.getClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
    });

    this.bookingService.getBookings().subscribe((bookings: Booking[]) => {
      this.bookings = bookings;
    });

    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });

    this.currentDateObj = this.dateCalendarSelected(this.currentDate);

    this.sharedService.getReviews(this.superstarID).subscribe((reviews: Reviews[]) => {
      this.reviews = reviews;
    });
  }

}
