import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Superstars } from '../../superstars.model';
import { SuperstarsComponent } from '../../superstars.component';
import { SuperstarsService } from '../../superstars.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {

  constructor(
    private router: Router,
    private superstarsComponent: SuperstarsComponent,
    private superstarsService: SuperstarsService
    ) { }

  id: number;
  checkAll = false;
  showSpinner = true;
  show = false;
  @Input() mainSuperstars: Superstars;
  @Input() autoSearchSuperstars: any;

  ngOnInit() {
    if (this.mainSuperstars) {
      setTimeout(() => {
        this.showSpinner = false;
        }, 1000);
    }
  }

  getSuperstarItem(pID: number) {
    this.id = pID;
    this.router.navigate(['/superstars'], { queryParams: { id: [this.id] }});
  }

  checkedAllSuperstars(event: any, superstars: any) {
    const checked = this.superstarsComponent.checkedAllSuperstars(event, superstars);
    superstars.forEach(item => this.checkAll = checked);
  }

  CheckedSuperstar(event: any, pID: number, superstar: any) {
    this.superstarsComponent.CheckedSuperstar(event, pID, superstar);
  }

  getStatusName(pID: string) {
    return this.superstarsService.getStatusName(Number(pID));
  }

}
