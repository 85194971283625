import { Component, OnInit, Input } from '@angular/core';
import {NgbModalConfig, NgbModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Superstars } from '../../../superstars/superstars.model';
import { Rates } from '../../rates.model';
import { SuperstarsService } from '../../../superstars/superstars.service';
import { BookingService } from '../../../booking/booking.service';
import { hours } from '../../utils/hours';
import { Shifts } from '../../../booking/add-shift/shifts.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-booking-superstars',
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  templateUrl: './booking-superstars.component.html',
  styleUrls: ['./booking-superstars.component.css']
})
export class BookingSuperstarsComponent implements OnInit {

  constructor(
    private superstarsService: SuperstarsService,
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    public datepipe: DatePipe,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    config2: NgbModalConfig,
    private mapsAPILoader: MapsAPILoader,
    public modal: NgbModal
    ) {
      config2.backdrop = 'static';
      config2.keyboard = false;
    }

  @Input() childShifts: any;
  @Input() childBooking: any;
  rates: Rates[] = [];
  shifts: Shifts[] = [];
  superstars: Superstars[] = [];
  filteredSuperstars: Superstars[] = [];
  bookedSuperstars: Superstars[] = [];
  superstarsByShiftID: any[] = [];
  show = false;
  customForm: FormGroup;
  submitted = false;
  loading = false;
  editMode = false;
  error: string = null;
  id: number;
  index: number;
  pLength = 0;
  hours: any;
  dateString: string;
  autoSearch: any;
  superstarName: string;
  shiftName: string;
  currentSuperstarID: number;
  currentAssignedSuperstarID: number;
  currentShiftID: any;
  pEndShiftDate: string;
  selectedShiftID: number;
  selectedShiftName: string;
  selectedShiftHours: number;
  selectedRateID: number;
  selectedShiftDate: string;
  selectedShiftStart: string;
  selectedShiftEnd: string;
  selectedShiftAddress: string;
  selectedShiftUniform: string;
  selectedShiftCountyName: string;
  selectedShiftClientName: string;
  selectedShiftClientAmount2: string;
  selectedSuperstar: any[] = [];

  filterBy = [
    {
      label: 'Sort by name',
      value: 1,
    },
    {
      label: 'Sort by rating',
      value: 2,
    },
    {
      label: 'Sort by experience',
      value: 3,
    }
  ];

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal right-modal2'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  openRemove(content, superstar: any, shiftID: number, shiftName: string) {

    this.superstarName = superstar.pFullName;
    this.shiftName = shiftName;
    this.currentSuperstarID = superstar.pSuperstarID;
    this.currentAssignedSuperstarID = superstar.pID;
    this.currentShiftID = shiftID;

    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'center-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  ngOnInit() {
    this.hours = hours;
    this.loadRates();
    this.reloadData();
    this.initForm();
    this.customForm.controls.pLength.disable();
  }

  loadSuperstars(pShiftID: number, pMapCordinates: string, pRateID: number) {
    this.superstarsService.getAvailableSuperstars(pShiftID, pRateID).subscribe((superstars: Superstars[]) => {
      this.superstars = [];
      this.mapsAPILoader.load().then(() => {
        const cordinatesObj = pMapCordinates.split('|');
        const center = new google.maps.LatLng(Number(cordinatesObj[0]), Number(cordinatesObj[1]));
        this.filteredSuperstars = superstars.filter(m => {
          // tslint:disable-next-line:no-string-literal
          const skm = Number(m['pLocationName']);
          // tslint:disable-next-line:no-string-literal
          const cordinatesObj2 = m['pMapCordinates'].split('|');
          const markerLoc = new google.maps.LatLng(Number(cordinatesObj2[0]), Number(cordinatesObj2[1]));
          const distanceInKm = Number(google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center)) / 1000;
          if (distanceInKm < skm) {
            this.superstars.push(m);
          }
        });
      });
    });
  }

  loadRates() {
    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });
  }

  empty() {
    this.superstarsByShiftID = [];
  }

  reloadData(): void {
    this.empty();
    this.childShifts.forEach(item => {
      this.selectedShiftName = item.title;
      this.selectedShiftCountyName = item.meta.county;
      this.selectedShiftClientName = item.meta.clientName;
      this.selectedShiftClientAmount2 = item.meta.pAmount2;
      this.selectedShiftAddress = item.meta.pContactAddress;
      this.selectedShiftUniform = item.meta.pUniformRequirements;
      this.superstarsService.getSuperstarsByShiftID(item.meta.pid)
      .subscribe((superstars: Superstars[]) => {
        this.superstarsByShiftID.push({
          pID: item.meta.pid,
          pName: item.title,
          pBookingID: item.meta.bookingid,
          pShiftDate: item.meta.pShiftDate,
          pStartTime: item.meta.pStartTime,
          pEndTime: item.meta.pEndTime,
          pLength: item.meta.pLength,
          pShiftType: item.meta.pShiftType,
          color: item.color,
          pNoStaffRequired: item.meta.pNoStaffRequired,
          superstars
        });
        this.superstarsByShiftID.sort(this.sortArrayByShiftDate);
      });
    });
  }

  sortArrayByShiftDate(a, b) {
    const x = a.pShiftDate;
    const y = b.pShiftDate;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  }

  onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.dateString = this.ngbDateParserFormatter.format(date);
      this.updateLength();
    }
  }

  sortSuperstarBy(event: any) {
    this.selectedSuperstar = [];
    const value = event.value;
    this.childBooking.forEach(item => {
      const location = item.pCountyName;
      if (location !== 'undefined') {
        this.superstarsService.getAvailableSuperstars(
          this.selectedShiftID,
          this.selectedRateID).subscribe((superstars: Superstars[]) => {
          this.superstars = superstars;
          if (value === 1) {
            this.superstars = this.superstars.sort(this.sortArrayByName);
          } else if (value === 2) {
            this.superstars = this.superstars.sort(this.sortArrayByRating);
          } else if (value === 3) {
            this.superstars = this.superstars.sort(this.sortArrayBySkills);
          }
        });
      }
    });
  }

  sortArrayByName(a, b) {
    const x = a.pFullName.toLowerCase();
    const y = b.pFullName.toLowerCase();
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  }

  sortArrayByRating(a, b) {
    const x = Number(a.pRating);
    const y = Number(b.pRating);
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  }

  sortArrayBySkills(a, b) {
    const x = Number(a.pSkills);
    const y = Number(b.pSkills);
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  }

  findSuperstars(templateID, shifts: any) {
    this.selectedSuperstar = [];
    this.selectedShiftID = shifts.pID;
    this.selectedShiftHours = Number(shifts.pLength);
    this.selectedRateID = Number(shifts.pShiftType);
    this.selectedShiftDate = this.datepipe.transform(shifts.pShiftDate, 'EEEE, d MMMM y');
    this.selectedShiftStart = shifts.pStartTime;
    this.selectedShiftEnd = shifts.pEndTime;
    this.childBooking.forEach(item => {
      const pMapCordinates = item.pMapCordinates;
      if (pMapCordinates !== 'undefined') {
        this.loadSuperstars(shifts.pID, pMapCordinates, Number(shifts.pShiftType));
      }
    });
    this.open(templateID);
  }

  approveSuperstar(currentID: number, currentAssignedID: number) {
    this.superstarsService.updateSuperstar(currentID, 0)
    .subscribe(superstars => {
      if (superstars.pStatus) {
        this.superstarsService.updateAssignedShiftSuperstar(currentAssignedID, 1)
        .subscribe(item => {
          if (item.pStatus) {
            this.reloadData();
            this.onCancel();
          }
        });
      }
    });
  }

  removeSuperstar() {
    this.superstarsService.updateSuperstar(this.currentSuperstarID, 1)
    .subscribe(superstars => {
      if (superstars.pStatus) {
        this.superstarsService.deleteAssignedShiftSuperstar(this.currentAssignedSuperstarID)
        .subscribe(data => {
          const index: number = this.superstarsByShiftID.findIndex(x => x.pID === this.currentShiftID);
          if (index !== -1) {
            this.reloadData();
          }
          this.onCancel();
        });
      }
    });
  }

  updateShift(templateID, pShiftID, index) {
    this.open(templateID);
    this.bookingService.getShiftbyID(pShiftID).subscribe((shifts: Shifts[]) => {
      this.shifts = shifts;
      this.id = this.shifts[0].pID;
      this.index = index;
      this.dateString = this.shifts[0].pShiftDate;
      this.customForm.get('pShiftDate').setValue(new Date(this.dateString));
      this.customForm.controls.pStartTime.setValue(this.shifts[0].pStartTime);
      this.customForm.controls.pEndTime.setValue(this.shifts[0].pEndTime);
      this.customForm.controls.pLength.setValue(this.shifts[0].pLength);
      this.customForm.controls.pShiftType.setValue(this.shifts[0].pShiftType);
      this.customForm.controls.pNoStaffRequired.setValue(this.shifts[0].pNoStaffRequired);
      this.customForm.controls.pContactName.setValue(this.shifts[0].pContactName);
      this.customForm.controls.pContactNumber.setValue(this.shifts[0].pContactNumber);
      this.customForm.controls.pUniformRequirements.setValue(this.shifts[0].pUniformRequirements);
      this.customForm.controls.pEntryInstructions.setValue(this.shifts[0].pEntryInstructions);
      this.customForm.controls.pGroomingPolicy.setValue(this.shifts[0].pGroomingPolicy);
    });
  }

  checkedSuperstar(event: any, superstar: Superstars) {
    const checked = event.target.checked;
    if (checked) {
      this.show = checked;
      this.selectedSuperstar.push({
        eventID: superstar.pID,
        eventEmail: superstar.pEmail,
        eventFullName: superstar.pFullName,
      });
    } else {
      const index: number = this.selectedSuperstar.findIndex(x => x.eventEmail === superstar.pEmail);
      if (index !== -1) {
        this.selectedSuperstar.splice(index, 1);
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  private initForm() {

    let pShiftDate = '';
    let pStartTime = '';
    let pEndTime = '';
    let pLength = '';
    let pShiftType = '';
    let pContactName = '';
    let pContactNumber = '';
    let pNoStaffRequired = '';
    let pUniformRequirements = '';
    let pEntryInstructions = '';
    let pGroomingPolicy = '';

    if (this.editMode) {
      pShiftDate = pShiftDate;
      pStartTime = pStartTime;
      pEndTime = pEndTime;
      pLength = pLength;
      pShiftType = pShiftType;
      pNoStaffRequired = pNoStaffRequired;
      pContactName = pContactName;
      pContactNumber = pContactNumber;
      pUniformRequirements = pUniformRequirements;
      pEntryInstructions = pEntryInstructions;
      pGroomingPolicy = pGroomingPolicy;
    }
    this.customForm = this.formBuilder.group({
      pBookingID: [this.id],
      pShiftDate: [pShiftDate, Validators.required],
      pStartTime: [pStartTime, Validators.required],
      pEndTime: [pEndTime, Validators.required],
      pLength: [this.pLength],
      pShiftType: [pShiftType, Validators.required],
      pNoStaffRequired: [pNoStaffRequired, [Validators.required, Validators.max(500), Validators.min(0)]],
      pContactName: [pContactName, Validators.required],
      pContactNumber: [pContactNumber],
      pUniformRequirements: [pUniformRequirements],
      pEntryInstructions: [pEntryInstructions],
      pGroomingPolicy: [pGroomingPolicy]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      return;
    } else {
      this.loading = true;
    }

    this.bookingService.updateShift(this.id, this.customForm.value, this.pEndShiftDate, this.pLength)
    .subscribe(
      (res) => {
        const rateName = this.rates.filter(rate => rate.pID === this.customForm.controls.pShiftType.value);
        this.superstarsByShiftID[this.index].pName =  rateName[0].pName,
        this.superstarsByShiftID[this.index].pShiftDate = this.customForm.controls.pShiftDate.value;
        this.superstarsByShiftID[this.index].pStartTime = this.customForm.controls.pStartTime.value;
        this.superstarsByShiftID[this.index].pEndTime = this.customForm.controls.pEndTime.value;
        this.superstarsByShiftID[this.index].pLength = this.customForm.controls.pLength.value;
        this.superstarsByShiftID[this.index].pShiftType = this.customForm.controls.pShiftType.value;
        this.superstarsByShiftID[this.index].pNoStaffRequired = this.customForm.controls.pNoStaffRequired.value;
        this.superstarsByShiftID[this.index].pContactName = this.customForm.controls.pContactName.value;
        this.superstarsByShiftID[this.index].pContactNumber = this.customForm.controls.pContactNumber.value;
        this.superstarsByShiftID[this.index].pUniformRequirements = this.customForm.controls.pUniformRequirements.value;
        this.superstarsByShiftID[this.index].pEntryInstructions = this.customForm.controls.pEntryInstructions.value;
        this.superstarsByShiftID[this.index].pGroomingPolicy = this.customForm.controls.pGroomingPolicy.value;
        this.onCancel();
        this.customForm.reset();
        this.loading = false;
      },
      (err) => {
        this.onCancel();
        this.loading = false;
      }
    );
  }

  updateLength() {

    const startDate = this.customForm.controls.pStartTime.value.split(':');
    const endDate = this.customForm.controls.pEndTime.value.split(':');

    if (startDate.length > 1 && endDate.length > 1) {

      const startd = new Date(this.customForm.value.pShiftDate);
      startd.setHours(0, 0, 0, 0);
      startd.setHours (startd.getHours() + Number(startDate[0]));
      startd.setMinutes (startd.getMinutes() + Number(startDate[1]));

      const starte = new Date(startd);
      starte.setHours (starte.getHours() + (Number(endDate[0]) - Number(startDate[0])));
      starte.setMinutes (starte.getMinutes() + (Number(endDate[1]) - Number(startDate[1])));

      let diff = starte.getTime() - startd.getTime();
      let thours = Math.floor(diff / 1000 / 60 / 60);
      diff -= thours * 1000 * 60 * 60;
      let minutes = Math.floor(diff / 1000 / 60);
      // If using time pickers with 24 hours format, add the below line get exact hours
      if (minutes === 30) {
        minutes = 0.5;
      }
      if (thours < 4  && thours >= 0) {
        thours = 4;
        minutes = 0;
      } else if (thours <= -1) {
        starte.setDate(starte.getDate() + 1);
        thours = thours + 24;
      }

      const starte2 = new Date(startd);
      starte2.setHours (starte2.getHours() + (thours + minutes));

      this.pEndShiftDate = this.datepipe.transform(starte2, 'yyyy-MM-dd H:mm:ss');
      this.customForm.get('pLength').setValue(thours + minutes);
      this.pLength = thours + minutes;
    }
  }

  sendMail() {
    if ((this.selectedShiftID !== null) && (this.selectedRateID !== null)
    && (this.selectedShiftHours !== null) && (this.selectedSuperstar)) {
      this.selectedSuperstar.forEach(item => {
        const rates = this.rates.filter(rate => Number(rate.pID) === this.selectedRateID);
        const rateAmount = (rates[0].pAmount * this.selectedShiftHours);
        this.bookingService.addSuperstarAssignedShifts(item, this.selectedShiftID,
          this.selectedShiftHours, rateAmount)
          .subscribe(
            (res) => {
              this.superstarsService.updateSuperstar(item.eventID, 1)
              .subscribe(superstars => {
                this.onCancel();
                /*Send Email Here...*/
                this.sendSuperstarEmail(item.eventEmail, item.eventFullName);
              });
            },
            (err) => this.onCancel()
          );
      });
      this.reloadData();
    }
  }

  sendSuperstarEmail(pEmail: string, pFullName: string) {

    const pSubject =  this.selectedShiftName + ' Shift in ' + this.selectedShiftCountyName + ' on ' + this.selectedShiftDate;
    const pMessage = `
    We thought you might be interested in the below shift that we have just received.
    <br /><br />
    If you can work this shift, please let us know by return email!
    <br /><br />
    <strong>Client:&nbsp;</strong>${this.selectedShiftClientName}
    <br />
    <strong>Date:&nbsp;</strong>${this.selectedShiftDate}
    <br />
    <strong>Start Time:&nbsp;</strong>${this.selectedShiftStart}
    <br />
    <strong>Finish Time:&nbsp;</strong>${this.selectedShiftEnd}
    <br />
    <strong>Address:&nbsp;</strong>${this.selectedShiftAddress}
    <br />
    <strong>Rate of Pay:&nbsp;</strong>${this.selectedShiftClientAmount2}&nbsp;EUR
    <br />
    <strong>Uniform Requirements:&nbsp;</strong>${this.selectedShiftUniform}

    <br /><br />Thanks,<br /><strong>Get the Shifts</strong>`;
    this.superstarsService.sendSuperstarEmail(pEmail, pFullName, pSubject, pMessage).subscribe();
  }

  getColor(colorID: number) {
    return this.bookingService.getColor(String(colorID)).primary;
  }

}
