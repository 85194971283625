import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { shiftViews } from '../../booking/utils/clients';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { HttpClient } from '@angular/common/http';
import { BookingService } from '../booking.service';
import 'rxjs/add/operator/map';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Shifts } from '../add-shift/shifts.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rates } from '../rates.model';
import { hours } from '../utils/hours';
import {NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-shift',
  templateUrl: './calendar-shift.component.html',
  styleUrls: ['./calendar-shift.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
})
export class CalendarShiftComponent implements OnInit {

  @Input() eventTemplate: TemplateRef<any>;

  view = 'week';
  viewDate: Date = new Date();
  @Input() events: CalendarEvent[] = [];
  shifts: Shifts[] = [];
  activeDayIsOpen = false;
  id: number;
  shiftViews = shiftViews;
  selectedView: string = null;
  loadingMessage = 'Loading events...';
  customForm: FormGroup;
  submitted = false;
  loadingf = false;
  editMode = false;
  error: string = null;
  superstarsByShiftID: any[] = [];
  pLength = 0;
  pEndShiftDate: string;
  rates: Rates[] = [];
  hours: any;
  dateString: string;

  constructor(
    private http: HttpClient,
    private bookingService: BookingService,
    public modal: NgbModal,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private bookingDetailsComponent: BookingDetailsComponent
    ) {}

  ngOnInit(): void {
    this.id = this.bookingDetailsComponent.id;
    this.bookingDetailsComponent.fetchEvents();
    this.initForm();
    this.customForm.controls.pLength.disable();
    this.bookingService.getRates().subscribe((rates: Rates[]) => {
      this.rates = rates;
    });
    this.hours = hours;
  }

  setView(viewType: string) {
    let view: CalendarView;
    if (viewType === 'Month') {
      view = CalendarView.Month;
    } else if (viewType === 'Week') {
      view = CalendarView.Week;
    } else {
      view = CalendarView.Day;
    }
    this.view = view;
  }

  getTime(date: string) {
    const day = new Date(date);
    const time = day.getHours() + ':' + day.getMinutes();
    return time;
  }

  getHours(date1: string, date2: string) {
    const oneDay =  60 * 60 * 1000;
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    let diffInMs = Math.round( Math.abs((secondDate.getTime() - firstDate.getTime()) / (oneDay)) * 10 ) / 10;
    if (Number.isNaN(diffInMs)) {
        diffInMs = 0;
    }
    return diffInMs;
  }

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  onCancel() {
    this.modal.dismissAll();
  }

  onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.dateString = this.ngbDateParserFormatter.format(date);
      this.updateLength();
    }
  }

  eventClicked(event: any, modalShiftDetails): void {
    this.open(modalShiftDetails);
    this.bookingService.getShiftbyID(event.event.meta.pid).subscribe((shifts: Shifts[]) => {
      this.shifts = shifts;
      this.id = this.shifts[0].pID;
      this.dateString = this.shifts[0].pShiftDate;
      this.customForm.get('pShiftDate').setValue(new Date(this.dateString));
      this.customForm.controls.pStartTime.setValue(this.shifts[0].pStartTime);
      this.customForm.controls.pEndTime.setValue(this.shifts[0].pEndTime);
      this.customForm.controls.pLength.setValue(this.shifts[0].pLength);
      this.customForm.controls.pShiftType.setValue(this.shifts[0].pShiftType);
      this.customForm.controls.pNoStaffRequired.setValue(this.shifts[0].pNoStaffRequired);
      this.customForm.controls.pContactName.setValue(this.shifts[0].pContactName);
      this.customForm.controls.pContactNumber.setValue(this.shifts[0].pContactNumber);
      this.customForm.controls.pUniformRequirements.setValue(this.shifts[0].pUniformRequirements);
      this.customForm.controls.pEntryInstructions.setValue(this.shifts[0].pEntryInstructions);
      this.customForm.controls.pGroomingPolicy.setValue(this.shifts[0].pGroomingPolicy);
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.customForm.controls; }

  private initForm() {

    let pShiftDate = '';
    let pStartTime = '';
    let pEndTime = '';
    let pLength = '';
    let pShiftType = '';
    let pUniformRequirements = '';
    let pContactName = '';
    let pContactNumber = '';
    let pNoStaffRequired = '';
    let pEntryInstructions = '';
    let pGroomingPolicy = '';

    if (this.editMode) {
      pShiftDate = pShiftDate;
      pStartTime = pStartTime;
      pEndTime = pEndTime;
      pLength = pLength;
      pShiftType = pShiftType;
      pNoStaffRequired = pNoStaffRequired;
      pContactName = pContactName;
      pContactNumber = pContactNumber;
      pUniformRequirements = pUniformRequirements;
      pEntryInstructions = pEntryInstructions;
      pGroomingPolicy = pGroomingPolicy;
    }
    this.customForm = this.formBuilder.group({
      pBookingID: [this.id],
      pShiftDate: [pShiftDate, Validators.required],
      pStartTime: [pStartTime, Validators.required],
      pEndTime: [pEndTime, Validators.required],
      pLength: [this.pLength],
      pShiftType: [pShiftType, Validators.required],
      pNoStaffRequired: [pNoStaffRequired, [Validators.required, Validators.max(500), Validators.min(0)]],
      pContactName: [pContactName, Validators.required],
      pContactNumber: [pContactNumber],
      pUniformRequirements: [pUniformRequirements],
      pEntryInstructions: [pEntryInstructions],
      pGroomingPolicy: [pGroomingPolicy]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customForm.invalid) {
      this.error = 'Please complete all the required fields.';
      return;
    } else {
      this.loadingf = true;
    }

    this.bookingService.updateShift(this.id, this.customForm.value, this.pEndShiftDate, this.pLength)
    .subscribe(
      (res) => {
        this.onCancel();
        this.customForm.reset();
        this.loadingf = false;
        this.ngOnInit();
      },
      (err) => {
        this.onCancel();
        this.loadingf = false;
      }
    );
  }

  updateLength() {

    const startDate = this.customForm.controls.pStartTime.value.split(':');
    const endDate = this.customForm.controls.pEndTime.value.split(':');

    if (startDate.length > 1 && endDate.length > 1) {

      const startd = new Date(this.customForm.value.pShiftDate);
      startd.setHours(0, 0, 0, 0);
      startd.setHours (startd.getHours() + Number(startDate[0]));
      startd.setMinutes (startd.getMinutes() + Number(startDate[1]));

      const starte = new Date(startd);
      starte.setHours (starte.getHours() + (Number(endDate[0]) - Number(startDate[0])));
      starte.setMinutes (starte.getMinutes() + (Number(endDate[1]) - Number(startDate[1])));

      let diff = starte.getTime() - startd.getTime();
      let thours = Math.floor(diff / 1000 / 60 / 60);
      diff -= thours * 1000 * 60 * 60;
      let minutes = Math.floor(diff / 1000 / 60);
      // If using time pickers with 24 hours format, add the below line get exact hours
      if (minutes === 30) {
        minutes = 0.5;
      }
      if (thours < 4  && thours >= 0) {
        thours = 4;
        minutes = 0;
      } else if (thours <= -1) {
        starte.setDate(starte.getDate() + 1);
        thours = thours + 24;
      }

      const starte2 = new Date(startd);
      starte2.setHours (starte2.getHours() + (thours + minutes));

      this.pEndShiftDate = this.datepipe.transform(starte2, 'yyyy-MM-dd H:mm:ss');
      this.customForm.get('pLength').setValue(thours + minutes);
      this.pLength = thours + minutes;
    }
  }
}
