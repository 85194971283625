import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../booking.service';
import { Booking } from '../booking.model';
import { Shifts } from '../add-shift/shifts.model';
import { BookingComponent } from '../booking.component';
import {NgbTabsetConfig, NgbModalConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { hours } from '../utils/hours';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class BookingDetailsComponent implements OnInit {

constructor(
    public bookingService: BookingService,
    public bookingComponent: BookingComponent,
    config: NgbTabsetConfig,
    config2: NgbModalConfig,
    public modal: NgbModal,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    config.justify = 'start';
    config2.backdrop = 'static';
    config2.keyboard = false;
    }

  id: number;
  editMode = false;
  hours: any;
  events: CalendarEvent[] = [];
  latestEvents: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  shifts: Shifts[] = [];
  bookings: Booking[] = [];

  open(content) {
    this.modal.open(content, {
      ariaLabelledBy: 'modal-basic',
      windowClass: 'right-modal'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.id = +params.id || 0;
    });

    this.hours = hours;
    this.bookingService.getBooking(this.id)
    .subscribe(
      resData => {
        this.bookings.push({
          pID: this.id,
          pBookingName: resData.pBookingName,
          pBookingSummary: resData.pBookingSummary,
          pEntryInstructions: resData.pEntryInstructions,
          pGroomingPolicy: resData.pGroomingPolicy,
          pUniformRequirements: resData.pUniformRequirements,
          pUserID: resData.pUserID,
          pClientID: resData.pClientID,
          pClientLogo: resData.pClientLogo,
          pClientName: resData.pClientName,
          pClientTypeID: resData.pClientTypeID,
          pContactEmail: resData.pContactEmail,
          pContactName: resData.pContactName,
          pContactNumber: resData.pContactNumber,
          pContactAddress: resData.pContactAddress,
          pCountyName: resData.pCountyName,
          pMapCordinates: resData.pMapCordinates,
          pCreated: resData.pCreated,
          totals: resData.totals
        });
      },
      error => {
        this.bookingService.handleError(error.message);
      }
    );

  }

  fetchEvents(): void {

    this.fetchLatestEvents(this.id);

    this.events = [];
    this.bookingService.getCalendarShifts(this.id)
    .subscribe(
      (events: CalendarEvent[]) => {
        this.events = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < events.length; i++) {
            this.events.push({
                title: events[i].title,
                start: new Date(events[i].start),
                end: new Date(events[i].end),
                color: this.bookingService.getColor(String(events[i].color)),
                draggable: false,
                meta: events[i].meta,
                allDay: false
            });
        }
        this.refresh.next(true);
      });
  }

  fetchLatestEvents(shitftID: number): void {
    this.latestEvents = [];
    this.bookingService.getCalendarLatestShifts(shitftID)
    .subscribe(
      (events: CalendarEvent[]) => {
        this.latestEvents = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < events.length; i++) {
            this.latestEvents.push({
                title: events[i].title,
                start: new Date(events[i].start),
                end: new Date(events[i].end),
                color: events[i].color,
                draggable: false,
                meta: events[i].meta,
                allDay: false
            });
        }
        this.refresh.next(true);
      });
  }
}
