import { Component, AfterViewInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-charts.component.html',
  styleUrls: ['./bar-charts.component.css']
})
export class BarChartsComponent implements AfterViewInit {
  @Input() ChartType: string;
  @Input() chartData = [];
  @Input() ChartID: string;
  ChartLabels: any;
  AchartTotals: any;
  BchartTotals: any;
  barchart = [];

  constructor() { }

  ngAfterViewInit() {

    this.ChartLabels = [];
    this.AchartTotals = [];
    this.BchartTotals = [];

    setTimeout( () => {
      this.chartData.forEach( (element) => {
        const numberOfSuperstars = element.superstars.filter(item => item.pStatusID === '1').length;
        const numberOfHours = Number(numberOfSuperstars) * Number(element.pLength);
        this.ChartLabels.push(element.pName);
        if (this.ChartType === 'staff') {
            this.AchartTotals.push(numberOfSuperstars);
            this.BchartTotals.push(Number(element.pNoStaffRequired) - numberOfSuperstars);
        } else {
          this.AchartTotals.push(numberOfHours);
          this.BchartTotals.push(((Number(element.pLength) * Number(element.pNoStaffRequired)) - numberOfHours));
        }
      });

      if (this.AchartTotals.length > 0 && this.BchartTotals.length > 0) {
        this.loadBarChart(this.ChartLabels, this.AchartTotals, this.BchartTotals);
      }
      }, 200);
  }

  loadBarChart(chartLabels: any, aChartTotals: any, bchartTotals: any) {

    let title1 = 'Hrs Filled';
    let title2 = 'Hrs Unfilled';
    if (this.ChartType === 'staff') {
        title1 = 'Staff Filled';
        title2 = 'Staff Unfilled';
    }

    this.barchart = new Chart(this.ChartID, {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: [{
          label: title1,
          data: aChartTotals,
          backgroundColor: '#29546F'
        },
        {
          label: title2,
          data: bchartTotals,
          backgroundColor: '#C7D0D6'
        }]
      },
      options: {
        responsive: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            barPercentage: 0.9,
            categoryPercentage: 0.8,
            barThickness: 80,
            stacked: true,
            ticks: {
              fontSize: 13,
              fontFamily: 'Montserrat',
              padding: 10
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            categoryPercentage: 0.8,
            gridLines: {
              display: false
            }
          }],
        }
    }
  });
  }
}
